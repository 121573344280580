import axios from "axios";

//Create a instance
// const apiUrl = (config.enviornment === 'production') ? config.production.api_url : config.development.api_url;
const apiUrl = process.env.REACT_APP_BASE_URL
const instance = axios.create({
   baseURL: apiUrl,
   headers: {
      'Content-Type': 'application/json',
   }
});

//Set AUTH token for any request
instance.interceptors.request.use(function (config) {
   const token = localStorage.getItem('token') ? localStorage.getItem('token').replace(/['"]+/g, "") : '';
   config.headers.Authorization = token ? `Bearer ${token}` : '';
   return config;
});

const doAPI = {
   //Get data from the server by method = GET
   getData: (URL, parameters = {}) =>
      instance({
         'method': 'GET',
         'url': URL,
         'params': parameters
      }),
   //Post JSON Object on the server by method = POST
   postData: (URL, parameters = {}) =>
      instance({
         'method': 'POST',
         'url': URL,
         'data': parameters
      }),
   //Post Fromdata on the server by method = POST
   postWithImage: async (url, body, callback) => {
      const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
      const formData = createFormData(body);
      return axios.post(apiUrl + url, formData, {
         headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            Authorization: token ? `Bearer ${token.replace(/['"]+/g, "")}` : '',
         },
         transformRequest: formData => formData,
         onUploadProgress: callback,
      }).catch(error => {
         throw error;
      });
   },
   //Post JSON Object on the server by method = PUT
   putData: (URL, parameters = {}) =>
      instance({
         'method': 'PUT',
         'url': URL,
         'data': parameters
      }),
};

/**
 * Helper function to create a from data
 * @param {*} body 
 * @returns 
 */
const createFormData = (body) => {
   const data = new FormData();
   Object.keys(body).forEach(key => {
      data.append(key, body[key]);
   });
   return data;
};

export default doAPI;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import doAPI from '../api';

const initialState = {
  bannerList: [],
  bannerDetails: {},
  status: 'idle',
  error: null,
};

export const fetchBannerList = createAsyncThunk('banner/fetchList', async (_, { rejectWithValue }) => {
  try {
    const response = await doAPI.getData(`slider/banner-list`);
    return response.data; // Axios automatically parses the response, so you can access data directly
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchBannerById = createAsyncThunk('banner/fetchById', async (id, { rejectWithValue }) => {
  try {
    const response = await doAPI.getData(`slider/banner-detail/${id}`);
    return response.data; // Axios automatically parses the response, so you can access data directly
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBannerList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBannerList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.bannerList = action.payload;
      })
      .addCase(fetchBannerList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchBannerById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBannerById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.bannerDetails = action.payload;
      })
      .addCase(fetchBannerById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default bannerSlice.reducer;

// consignmentSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Service from '../http';

const services = new Service();

// Async thunk for fetching consignments and subconsignments
export const fetchConsignment = createAsyncThunk('consignments/fetchConsignment', async () => {
   try {
      const response = await services.get('setting/list-stock');

      return response.data;

   } catch (error) {
      console.log('redux', error)

      throw error;
   }
});

export const fetchCurrentConsignment = createAsyncThunk('consignments/fetchCurrentConsignment', async () => {
   try {
      const response = await services.get('setting/stock');
      return response.data;
   } catch (error) {
      console.log('redux', error)

      throw error;
   }
});

// Initial state
const initialState = {
   consignments: [],
   currentConsignment: [],
   status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
   error: null,
};

// Create a slice
const consignmentSlice = createSlice({
   name: 'consignments',
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(fetchConsignment.pending, (state) => {
            state.status = 'loading';
         })
         .addCase(fetchConsignment.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.consignments = action.payload;
         })
         .addCase(fetchConsignment.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
         })
         .addCase(fetchCurrentConsignment.pending, (state) => {
            state.status = 'loading';
         })
         .addCase(fetchCurrentConsignment.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.currentConsignment = action.payload;
         })
         .addCase(fetchCurrentConsignment.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
         });
   },
});

export default consignmentSlice.reducer;

import React, { useEffect, useState } from 'react';
// import * as XLSX from 'xlsx';
import LTable from '../../../Components/LTable';
import CustomSelect from '../../../Components/CustomSelect';
import { Button, Form, Modal, Col } from 'react-bootstrap';
import Configuration from './Configuration';
import { AiOutlineUpload } from 'react-icons/ai';
import { useFormik } from 'formik';
import Notification, { notify } from '../../../Components/Notification';
import * as Yup from 'yup';
import Service from './../../../http';
import Label from '../../../Components/Label';
import axios from 'axios';
function ExcelReader() {
  const services = new Service();
  const [excelData, setExcelData] = useState([]);
  // const [fileName, setFileName] = useState(""); // State to store the file name
  // const [isLoading, setIsLoading] = useState(false)
  const [categoryInfo, setCategoryInfo] = useState([])
  const [subCategoryInfo, setSubCategoryInfo] = useState([])
  const [showUploadExcelFile, setShowUploadExcelFile] = useState(false);
  const handleCloseUploadExcelFile = () => setShowUploadExcelFile(false);
  const handleShowUploadExcelFile = () => setShowUploadExcelFile(true);

  const [configurationForm, setShowConfigurationForm] = useState(false);
  const handleCloseConfigurationForm = () => setShowConfigurationForm(false);
  const handleShowConfigurationForm = () => setShowConfigurationForm(true);

  const NewProductSchema = Yup.object().shape({
    import_excel: Yup.mixed().required('File is required'),
    category_id: Yup.mixed().required('Category is required'),
    sub_category_id: Yup.mixed().required('Sub Category is required'),
  });

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      import_excel: null,
      category_id: null,
      sub_category_id: null,
    },
    onSubmit: (values, { resetForm }) => {

      
      const formData = new FormData();

      formData.append('import_excel', values.import_excel);
      formData.append('category_id', values.category_id);
      formData.append('sub_category_id', values.sub_category_id);


      const token = () => {
        if (localStorage.getItem("token")) {
           let tokenVal = localStorage.getItem("token").replace(/['"]+/g, "");
           return "Bearer " + tokenVal;
        }
     };

     const baseUrl = process.env.REACT_APP_BASE_URL;
     const headers = {
        Accept: '*',
        'Content-Type': 'multipart/form-data',

     };

     headers.Authorization = token();
     axios.post(baseUrl + '/product/import-excel', formData, { headers })
        .then((res) => {
           console.log({ formData });
           if (res.status) {
              notify(res.message);
              // handleImageChange();
           } else {
              notify(res.message[0]?.message[0]?.message, { variant: 'error' });
           }
        })
        .catch((error) => {
           console.error('Error uploading images:', error);
        });

      resetForm();
    },
    validationSchema: NewProductSchema
  });

  useEffect(() => {
    let isMounted = true;
    services.get('category/list').then((res) => {
      if (isMounted) {
        setCategoryInfo(res.data);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    // const reader = new FileReader();

    file && formik.setFieldValue("import_excel", file);

    // reader.onload = (e) => {

    //   const data = new Uint8Array(e.target.result);
    //   const workbook = XLSX.read(data, { type: 'array' });
    //   const sheetName = workbook.SheetNames[0];
    //   const worksheet = workbook.Sheets[sheetName];
    //   const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    //   setExcelData(excelData);
    //   setFileName(file.name);

    // };

    // reader.readAsArrayBuffer(file);

  };

  useEffect(() => {
    let isMounted = true;
    services.get('category/sub-category/' + formik.values.category_id).then((res) => {
      if (isMounted) {
        setSubCategoryInfo(res.data);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [formik.values.category_id]);


  return (
    <div>
      <Notification />
      <div className='table_filter equal_space'>
        <div className='table_filter_item'>
          <input className='form-control' placeholder='search' type='search' />
          <input className='form-control' type='date' />
        </div>
        <div className='table_filter_item'>
          <Button type="button" value="Upload" onClick={handleShowUploadExcelFile}><AiOutlineUpload /> Upload Excel</Button>
        </div>
        <div className='table_filter_item ms-md-auto'>
          <CustomSelect
            placeholder="Select Import Type"
            //   value={formik.values.country}
            onChange={(value) =>
              console.log(value)
            }
            options={[{ label: 'Product', value: 1 }, { label: 'Delivery note', value: 2 }]}
          />
          <Button size='lg' onClick={handleShowConfigurationForm}>Configuration</Button>
        </div>
      </div>

      <LTable>
        {excelData.length > 0 && (
          <thead>
            <tr>
              {excelData[0].map((i, index) => <th key={index}>{i}</th>)}
            </tr>
          </thead>
        )}
        <tbody>
          {excelData.slice(1).map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </LTable>

      <Modal show={showUploadExcelFile} onHide={handleCloseUploadExcelFile}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Excel File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Col md={12} className='mb-3'>
              <Label aria-required>Select Category</Label>
              <select name="category_id"
                className='form-control'
                onChange={(event) => formik.setFieldValue('category_id', event.target.value)}
                onBlur={formik.handleBlur}>
                <option value={null}>Select Category</option>
                {categoryInfo && categoryInfo.map((i, index) => (
                  <option key={index} value={i.id}>
                    {i.name}
                  </option>
                ))}
              </select>
              {formik.touched.category_id && formik.errors.category_id ? (
                <div className="error">{formik.errors.category_id}</div>
              ) : null}
            </Col>

            <Col md={12} className='mb-3'>
              <Label aria-required>Select Sub Category</Label>
              <select name="sub_category_id"
                className='form-control'
                onChange={(event) => formik.setFieldValue('sub_category_id', event.target.value)}
                onBlur={formik.handleBlur}>
                <option value={null}>Select Category</option>
                {subCategoryInfo && subCategoryInfo.map((i, index) => (
                  <option key={index} value={i.id}>
                    {i.name}
                  </option>
                ))}
              </select>
              {formik.touched.sub_category_id && formik.errors.sub_category_id ? (
                <div className="error">{formik.errors.sub_category_id}</div>
              ) : null}
            </Col>

            <Col md={12} className='mb-3'>
              <div className='fileImporter'>
                <AiOutlineUpload /> {formik.values.import_excel?.name||'Upload Excel File'}
                <input type="file" accept=".xlsx,.csv" onChange={handleFileUpload} />
              </div>
            </Col>
            <Col md={12} className='mb-3'>
              <Button size='lg' type='submit' className='w-100'>Submit</Button>
            </Col>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={configurationForm} onHide={handleCloseConfigurationForm}>
        <Modal.Header closeButton>
          <Modal.Title>Configuration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Configuration />
        </Modal.Body>
      </Modal>

    </div>
  );
}

export default ExcelReader;

import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Notification() {
   return (
      <div>
         <ToastContainer position="bottom-right" />
      </div>
   );
}

export function notify(message) {
   toast(message);

}

export default Notification;

// orderSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import doAPI from "../api";

// Define an initial state
const initialState = {
  orders: [],
  loading: false,
  error: null,
};
// Define the async thunk for fetching orders
export const fetchOrders = createAsyncThunk(
  "orders/fetchOrders",
  async ({ page = 1, shop = "", from_date='',to_date='', status='', sku='',orderId='' }) => {
    try {
      const params = new URLSearchParams({ page, shop, from_date, to_date , status, sku, orderId}).toString();
      const data = await doAPI.getData(`order/list?${params}`);
      return data;
    } catch (error) {
      throw Error("Failed to fetch orders");
    }
  }
);

// Define the async thunk for fetching order details by id
export const fetchOrderDetails = createAsyncThunk(
  "orders/fetchOrderDetails",
  async ({ orderId = "" }) => {
    try {
      const url = `order/${orderId}`;
      const data = await doAPI.getData(url);
      return data;
    } catch (error) {
      throw Error("Failed to fetch order details");
    }
  }
);

// Create the slice
const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchOrderDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrderDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = [action.payload]; // Assuming you want to store details as an array for consistency
      })
      .addCase(fetchOrderDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export the async thunk for external usage
// export { fetchOrders };

// Export the reducer
export default orderSlice.reducer;

// citySlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import doAPI from '../api';

const initialState = {
  cities: [],
  status: 'idle',
  error: null,
};

export const fetchCitiesByState = createAsyncThunk('city/fetchListByState', async (stateId, { rejectWithValue }) => {
  try {
    const response = await doAPI.getData(`city/state/${stateId}`);
    const data = await response.json();
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchCityById = createAsyncThunk('city/fetchById', async (cityId, { rejectWithValue }) => {
  try {
    const response = await doAPI.getData(`city?page=${cityId}`);
    const data = await response.json();
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const citySlice = createSlice({
  name: 'city',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCitiesByState.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCitiesByState.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cities = action.payload;
      })
      .addCase(fetchCitiesByState.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchCityById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCityById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cities = action.payload;
      })
      .addCase(fetchCityById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default citySlice.reducer;

// colorSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import doAPI from "../api";

// Define the initial state for the color slice
const initialState = {
  colorlist: [],
  colorDetails: {},
  color: null,
  status: "idle",
  error: null,
};

// Define an async thunk for fetching the color list
export const fetchColorList = createAsyncThunk(
  "color/fetchList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await doAPI.getData(`color/list`);
      const data = await response;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchColorDetails = createAsyncThunk(
  "color/fetchDetails",
  async (colorId, { rejectWithValue }) => {
    try {
      const response = await doAPI.getData(`color/detail/${colorId}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Create the color slice
const colorSlice = createSlice({
  name: "color",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchColorList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchColorList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.colorlist = action.payload;
      })
      .addCase(fetchColorList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload; // Now storing the error message in the state
      })
      .addCase(fetchColorDetails.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchColorDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.colorDetails = action.payload;
        state.error = null;
      })
      .addCase(fetchColorDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default colorSlice.reducer;

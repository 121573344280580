import React from 'react'
import Pagelayout from '../../../Layouts/Pagelayout'
import { Card, Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import CustomDatePicker from '../../../Components/CustomDatePicker'
import Label from '../../../Components/Label'
import CustomSelect from '../../../Components/CustomSelect'
import LTable from '../../../Components/LTable'

export default function PurchaseDocument() {

  const { documentId } = useParams()

  console.log(documentId)
  return (
    <Pagelayout
      title="Inventory"
      breadcrumbs={
        [
          { title: 'Inventory', link: '/inventory' },
          { title: 'Purchase', link: '/inventory/purchase' },
          { title: documentId, link: `/inventory/purchase/${documentId}` },
        ]
      }>
      <Card className='overflow-visible mb-4'>
        <Card.Body>
          <div className='d-md-flex'>
            <div className='flex-grow-1'>
              <Label aria-required>Document Title</Label>
              <h4 className='flex-grow-1 mt-2'>{documentId}</h4>
            </div>
            <div>
              <Label aria-required>Document Date</Label>
              <CustomDatePicker
                name="dob"
                className="form-control"
                // value={formik.values.dob}
                onChange={(value) => console.log(value)}
              // onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <hr />
          <Row>
            <Col lg={12}>
              <h5 className='d-block'>Type and reference</h5>
              <div className='my-4'>
                <label className='me-3'><input type="radio" name="type" /> Delivery note / Purchase invoice</label>
                <label className='me-3'><input type="radio" name="type" /> Purchase order</label>
              </div>
            </Col>
            <Col lg={3} md={4} sm={6}>
              <Label aria-required>Supplier</Label>
              <CustomSelect
                className={'w-100'}
                placeholder="Select Supplier"
                //   value={formik.values.country}
                onChange={(value) =>
                  console.log(value)
                }
                options={[{ label: 'Supplier name', value: 1 }, { label: 'Supplier name', value: 2 }]}
              />
            </Col>


            <Col lg={3} md={4} sm={6}>
              <Label aria-required>Store to</Label>
              <CustomSelect
                className={'w-100'}
                placeholder="Store to"
                //   value={formik.values.country}
                onChange={(value) =>
                  console.log(value)
                }
                options={[{ label: 'Product Number', value: 1 }, { label: 'Barcode', value: 2 }]}
              />
            </Col>

          </Row>
          <hr />
          <Row>
            <Col lg={3} md={4} sm={6}>
              <div>
                <Label aria-required>Payment Date</Label>
                <CustomDatePicker
                  name="dob"
                  className="form-control"
                  // value={formik.values.dob}
                  onChange={(value) => console.log(value)}
                // onBlur={formik.handleBlur}
                />
              </div>
            </Col>
            <Col lg={3} md={4} sm={6}>
              <div>
                <Label aria-required>Delivery Date</Label>
                <CustomDatePicker
                  name="dob"
                  className="form-control"
                  // value={formik.values.dob}
                  onChange={(value) => console.log(value)}
                // onBlur={formik.handleBlur}
                />
              </div>
            </Col>
            <Col lg={3} md={4} sm={6}>
              <div>
                <Label aria-required>Payment Period in days</Label>
                <input type='text' placeholder='Payment period in days' className='form-control' />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className='overflow-visible'>
        <Card.Body>
          <LTable>
            <thead>
              <tr>
                <th>#</th>
                <th>Product name</th>
                <th>Barcode</th>
                <th>Unit</th>
                <th>Quantity</th>
                <th>Unit price without VAT</th>
                <th>Total without VAT</th>
                <th>To store balance</th>
                <th>Unit price with VAT</th>
                <th>Total with VAT</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>#</td>
                <td>Lorem ipsum dolor sit amet consectetur adipi</td>
                <td>6565898952</td>
                <td>PCS</td>
                <td><input type="text" value={'18.000'} className='form-control' /></td>
                <td><input type="text" value={'20.952'} className='form-control' /></td>
                <td><input type="text" value={'377.143'} className='form-control' /></td>
                <td>0</td>
                <td><input type="text" value={'22'} className='form-control' /></td>
                <td><input type="text" value={'396'} className='form-control' /></td>
                <td></td>
              </tr>
              <tr>
                <td>#</td>
                <td>Lorem ipsum dolor sit amet consectetur adipi</td>
                <td>6565898952</td>
                <td>PCS</td>
                <td><input type="text" value={'18.000'} className='form-control' /></td>
                <td><input type="text" value={'20.952'} className='form-control' /></td>
                <td><input type="text" value={'377.143'} className='form-control' /></td>
                <td>0</td>
                <td><input type="text" value={'22'} className='form-control' /></td>
                <td><input type="text" value={'396'} className='form-control' /></td>
                <td></td>
              </tr>
              <tr>
                <td>#</td>
                <td>Lorem ipsum dolor sit amet consectetur adipi</td>
                <td>6565898952</td>
                <td>PCS</td>
                <td><input type="text" value={'18.000'} className='form-control' /></td>
                <td><input type="text" value={'20.952'} className='form-control' /></td>
                <td><input type="text" value={'377.143'} className='form-control' /></td>
                <td>0</td>
                <td><input type="text" value={'22'} className='form-control' /></td>
                <td><input type="text" value={'396'} className='form-control' /></td>
                <td></td>
              </tr>
              <tr>
                <td>#</td>
                <td>Lorem ipsum dolor sit amet consectetur adipi</td>
                <td>6565898952</td>
                <td>PCS</td>
                <td><input type="text" value={'18.000'} className='form-control' /></td>
                <td><input type="text" value={'20.952'} className='form-control' /></td>
                <td><input type="text" value={'377.143'} className='form-control' /></td>
                <td>0</td>
                <td><input type="text" value={'22'} className='form-control' /></td>
                <td><input type="text" value={'396'} className='form-control' /></td>
                <td></td>
              </tr>
              <tr>
                <td>#</td>
                <td>Lorem ipsum dolor sit amet consectetur adipi</td>
                <td>6565898952</td>
                <td>PCS</td>
                <td><input type="text" value={'18.000'} className='form-control' /></td>
                <td><input type="text" value={'20.952'} className='form-control' /></td>
                <td><input type="text" value={'377.143'} className='form-control' /></td>
                <td>0</td>
                <td><input type="text" value={'22'} className='form-control' /></td>
                <td><input type="text" value={'396'} className='form-control' /></td>
                <td></td>
              </tr>
              <tr>
                <td>#</td>
                <td>Lorem ipsum dolor sit amet consectetur adipi</td>
                <td>6565898952</td>
                <td>PCS</td>
                <td><input type="text" value={'18.000'} className='form-control' /></td>
                <td><input type="text" value={'20.952'} className='form-control' /></td>
                <td><input type="text" value={'377.143'} className='form-control' /></td>
                <td>0</td>
                <td><input type="text" value={'22'} className='form-control' /></td>
                <td><input type="text" value={'396'} className='form-control' /></td>
                <td></td>
              </tr>
              <tr>
                <td>#</td>
                <td>Lorem ipsum dolor sit amet consectetur adipi</td>
                <td>6565898952</td>
                <td>PCS</td>
                <td><input type="text" value={'18.000'} className='form-control' /></td>
                <td><input type="text" value={'20.952'} className='form-control' /></td>
                <td><input type="text" value={'377.143'} className='form-control' /></td>
                <td>0</td>
                <td><input type="text" value={'22'} className='form-control' /></td>
                <td><input type="text" value={'396'} className='form-control' /></td>
                <td></td>
              </tr>
              <tr>
                <td>#</td>
                <td>Lorem ipsum dolor sit amet consectetur adipi</td>
                <td>6565898952</td>
                <td>PCS</td>
                <td><input type="text" value={'18.000'} className='form-control' /></td>
                <td><input type="text" value={'20.952'} className='form-control' /></td>
                <td><input type="text" value={'377.143'} className='form-control' /></td>
                <td>0</td>
                <td><input type="text" value={'22'} className='form-control' /></td>
                <td><input type="text" value={'396'} className='form-control' /></td>
                <td></td>
              </tr>
              <tr>
                <td>#</td>
                <td>Lorem ipsum dolor sit amet consectetur adipi</td>
                <td>6565898952</td>
                <td>PCS</td>
                <td><input type="text" value={'18.000'} className='form-control' /></td>
                <td><input type="text" value={'20.952'} className='form-control' /></td>
                <td><input type="text" value={'377.143'} className='form-control' /></td>
                <td>0</td>
                <td><input type="text" value={'22'} className='form-control' /></td>
                <td><input type="text" value={'396'} className='form-control' /></td>
                <td></td>
              </tr>

            </tbody>
          </LTable>
        </Card.Body>
      </Card>
    </Pagelayout>
  )
}

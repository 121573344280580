import React from 'react'
import { useSelector } from 'react-redux';

export default function Currency({ currency }) {


   const selectedCurrency = useSelector((state) => state.property.selectedCurrency);

   const currencylist = useSelector((state) => state.currency?.currencylist?.data);

   return (
      <span className='d-inline-block currency'>{!currency ? selectedCurrency?.symbol : currencylist.find(i=>i.id===currency).symbol}</span>
   )
}

// stateSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import doAPI from "../api";

const initialState = {
  states: [],
  status: "idle",
  error: null,
};

export const fetchStatesByCountry = createAsyncThunk(
  "state/fetchListByCountry",
  async (countryId, { rejectWithValue }) => {
    try {
      const response = await doAPI.getData(`state/country/${countryId}`);

      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const stateSlice = createSlice({
  name: "state",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatesByCountry.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchStatesByCountry.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.states = action.payload;
      })
      .addCase(fetchStatesByCountry.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default stateSlice.reducer;

import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import RouteFile from './Routes/RouteFile';
import './styles/main.css'
import { useDispatch, useSelector } from 'react-redux';

function App() {
  const dispatch = useDispatch();
  const selectedCurrency = useSelector((state) => state.property.selectedCurrency);

  useEffect(() => {
    selectedCurrency && localStorage.setItem('currency', JSON.stringify(selectedCurrency))
    // console.log({selectedCurrency})
  }, [])

  useEffect(() => {
    dispatch({ type: 'RESET_USER_STATE_FROM_LOCAL_STORAGE' });
  }, [dispatch]);
  return <RouteFile />
}

export default App;

import React from 'react'
import Charts from '../../Components/Charts'

export default function MonthlyOrdersCharts() {
  const barChartOptions = {
    colors: ['#48DA74'],
    chart: {
      id: 'basic-bar'
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']
    },
    grid: { show: false },
  };

  const barChartSeries = [
    {
      name: 'series-1',
      data: [30, 40, 50, 60, 70, 80]
    }
  ];
  return (
    <Charts type="bar" options={barChartOptions} series={barChartSeries} height={350} />
  )
}

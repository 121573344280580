import React, { useEffect, useState } from 'react'
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { useFormik } from "formik";
import * as Yup from 'yup';
import Service from '../../http'
import { fetchCoupons } from '../../redux/couponSlice'
import { useDispatch, useSelector } from 'react-redux'
import CustomInput from '../../Components/CustomInput';
import Notification, { notify } from '../../Components/Notification';
import CustomDatePicker from '../../Components/CustomDatePicker'
import Label from '../../Components/Label';
import CustomSelect from '../../Components/CustomSelect';
import Currency from '../../Components/Currency';
import { fetchProductList } from '../../redux/productSlice';
import { BiLoader } from 'react-icons/bi';
import { fetchCategories } from '../../redux/categorySlice';
import { fetchUsers } from '../../redux/usersSlice';
import { AiFillInfoCircle } from 'react-icons/ai';

export default function AddSubCategory({ onSuccess, subCategoryId }) {

   const services = new Service();
   const dispatch = useDispatch()
   const [isLoading, setIsLoading] = useState(false);
   const [selectedCategory, setSelectedCategory] = useState()
   const [categoryList, setCategoryList] = useState([])
   const [productList, setProductList] = useState([])
   const [categorySearchQuery, setCategorySearchQuery] = useState('')

   const categories = useSelector((state) => state.category)?.categories?.data;

   const products = useSelector((state) => state.product)?.productList?.rows;

   useEffect(() => {
      dispatch(fetchCategories());
   }, [dispatch, categorySearchQuery]);

   useEffect(() => {
      setProductList([]);
      products && products.map(i => {
         const obj = {};
         obj.value = i.id;
         obj.label = i.name;
         setProductList(old => [...old, obj]);
      });

   }, [products]);

   useEffect(() => {
      setCategoryList([]);
      categories && categories.map(i => {
         const obj = {};
         obj.value = i.id;
         obj.label = i.name;
         setCategoryList(old => [...old, obj]);
      });

   }, [categories]);

   const formik = useFormik({
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: {
         category_id: 0,
         name: "",
         parent_id: 0,
         slug: "",
         image: null
      },
      onSubmit: (values, { resetForm }) => {
         setIsLoading(true);
         values.slug = values.slug.toLowerCase().replace(/\s+/g, "-");

         services &&
            services
               .post("category/sub-category/add-update", values)
               .then((res) => {
                  if (res.status) {
                     notify(res.message)
                     dispatch(fetchCategories());
                     onSuccess()
                  } else {
                     res?.message.map(i => notify(i.message[0].message))
                  };
                  setIsLoading(false);
               });
         resetForm();
      },
      validationSchema: Yup.object().shape({
         category_id: Yup.number().required('Category is required.'),
         name: Yup.string().required('Name is required.'),
         parent_id: Yup.number().required('Parent is required.'),
         slug: Yup.string().required('Slug is required.'),
      })
   });

   // console.log(formik.values)

   return (
      <Form onSubmit={formik.handleSubmit}>
         <Notification />
         <Row>
            <Col md={12} className='mb-3'>
               <Label aria-required>Enter Sub Category</Label>
               <CustomInput name="name" className='form-control' type='text' placeholder='Enter Sub Category'
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
               />
               {formik.touched.name && formik.errors.name ? (
                  <div className="error">{formik.errors.name}</div>
               ) : null}
            </Col>

            <Col md={12} className='mb-3'>
               <Label aria-required>Select Category</Label>
               <CustomSelect
                  className={'w-100'}
                  placeholder="Select Category"
                  onInputChange={value => setCategorySearchQuery(value)}
                  value={formik.values.parent_id}
                  onChange={(value) => {
                     setSelectedCategory(categories.find(i => i.id === value.value).slug)
                     formik.setFieldValue('parent_id', value.value)
                  }}
                  options={categoryList}
               />
            </Col>

            <Col md={12} className='mb-3'>
               <Label aria-required>Slug</Label>
               <CustomInput name="slug" className='form-control' type='text' placeholder='Slug'
                  value={formik.values.slug}
                  // onChange={formik.handleChange}
                  onChange={(event) => {
                     const formattedSlug = event.target.value
                       .toLowerCase()
                       .replace(/\s+/g, "-");
                     formik.handleChange({
                       target: {
                         name: "slug",
                         value: formattedSlug,
                       },
                     });
                   }}
                  onBlur={formik.handleBlur}
               />
               {formik.touched.slug && formik.errors.slug ? (
                  <div className="error">{formik.errors.slug}</div>
               ) : null}
            </Col>
            <Col md={12} className='mb-3'>
               <Button
                  type="submit"
                  size='lg'
                  className="px-md-5 w-100"
                  variant="primary"
               >
                  {isLoading ? <BiLoader className='loader' /> : "Add Sub Category"}
               </Button>
            </Col>
         </Row>
      </Form >
   )
}

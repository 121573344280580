// CustomCropper.js
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Modal, Button } from "react-bootstrap";
import Image from "./Image";
import { AiFillDelete, AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

const CustomCropper = ({ onImageCropped, fetchedImg, isMulti }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedImageFile, setCroppedImageFile] = useState(null);
  const [imagePriview, setImagePriview] = useState(null);
  const [cropper, setCropper] = useState();
  const [zoomLevel, setZoomLevel] = useState(1);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedFile(file);
  };

  const handleCrop = () => {
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();

      // Convert the canvas to Blob
      croppedCanvas.toBlob((blob) => {
        // Create a File object from the Blob
        const croppedImage = new File([blob], "cropped-image.png", {
          type: "image/png",
        });
        setImagePriview(cropper.getCroppedCanvas().toDataURL());
        // Set the File object in the state
        setCroppedImageFile(croppedImage);
        onImageCropped(croppedImage);
      }, "image/png");

      setSelectedFile(false);
    }
  };

  const bytesToMB = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2); // Convert bytes to megabytes with 2 decimal places
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: isMulti,
  });

  const handleZoomIn = () => {
    if (cropper) {
      cropper.zoom(0.1); // Zoom in by 0.1
      setZoomLevel(cropper.zoomTo()); // Update zoom level state
    }
  };

  const handleZoomOut = () => {
    if (cropper) {
      cropper.zoom(-0.1); // Zoom out by 0.1
      setZoomLevel(cropper.zoomTo()); // Update zoom level state
    }
  };

  const updateZoom = (event) => {
    let zoomval = parseFloat(event.target.value);
    if (cropper) {
      // Calculate the difference between the new zoom value and the current zoom level

      if (zoomval > zoomLevel) {
        // Trigger event for increasing value
        cropper.zoom(0.1);
      } else if (zoomval < zoomLevel) {
        // Trigger event for decreasing value
        cropper.zoom(-0.1);
      }

      // Update zoom level state
      setZoomLevel(zoomval);
    }
  };

  return (
    <div className="customMultiUpload">
      <div
        {...getRootProps()}
        style={dropzoneStyles}
        className="contained-button-file"
      >
        <button type="button" class="btn btn-primary">
          Select Image
        </button>
        <input {...getInputProps()} />
      </div>

      {selectedFile && (
        <Modal show={selectedFile} onHide={() => setSelectedFile(null)}>
          <Modal.Header closeButton>
            <Modal.Title>Crop Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <em className="text-center d-block text-danger mb-3">
              You can adjust the image with the cropper
            </em>
            <Cropper
              style={{ height: 400, width: "100%" }}
              initialAspectRatio={1}
              src={URL.createObjectURL(selectedFile)}
              viewMode={1}
              guides={true}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkCrossOrigin={true}
              onInitialized={(instance) => setCropper(instance)}
            />
            <div className="mt-3 text-center">
              {/* {JSON.stringify(zoomLevel)} */}
              <Button className="btn btn-primary m-2" onClick={handleZoomIn}>
                <AiOutlinePlus />
              </Button>
              {/* <input
                type="range"
                max={2}
                step={0.1}
                min={1}
                onChange={(event) => updateZoom(event)}
              /> */}
              <Button className="btn btn-primary m-2" onClick={handleZoomOut}>
                <AiOutlineMinus />
              </Button>
            </div>
            <Button className="btn btn-priamry mt-3 w-100" onClick={handleCrop}>
              Save Image
            </Button>
          </Modal.Body>
        </Modal>
      )}
      {(typeof croppedImageFile !== "object" && fetchedImg !== "") ||
        (fetchedImg !== undefined &&
          croppedImageFile==null&&(
            <div>
              <div className="previewHolder mt-3">
                <div className="filePreview">
                  <img src={fetchedImg} />
                  {/* <div className="actionbtn">
                <button type="button">
                  <AiFillDelete />
                </button>
              </div> */}
                </div>
              </div>
            </div>
          ))}

      {croppedImageFile && (
        <div>
          <div className="previewHolder mt-3">
            <div className="filePreview">
              <Image src={imagePriview} />
              {/* <div className="actionbtn">
                <button type="button">
                  <AiFillDelete />
                </button>
              </div> */}
              <small>{bytesToMB(croppedImageFile.size)} MB</small>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const dropzoneStyles = {
  borderRadius: "4px",
  padding: "0px",
  textAlign: "center",
  cursor: "pointer",
};

export default CustomCropper;

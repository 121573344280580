import React, { useEffect, useState } from 'react'
import Notification, { notify } from '../../../Components/Notification'
import { Button, Col, Row, Form } from 'react-bootstrap'
import Label from '../../../Components/Label'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import Service from '../../../http'
import CustomInput from '../../../Components/CustomInput'
import { useSelector } from 'react-redux'
import { BiLoader } from 'react-icons/bi'
import doAPI from '../../../api'

export default function AddCarousel({ onFormSubmitSuccess }) {
   const services = new Service();
   const currency = useSelector((state) => state.currency)?.currencylist?.data;
   const [isLoading, setIsLoading] = useState(false)

   const NewProductSizeSchema = Yup.object().shape({
      slider_image: Yup.mixed().required('Slider image is required'),
      mobile_image: Yup.mixed().required('Mobile image is required'),
      slider_name: Yup.string().required('Slider Name is required'),
      title: Yup.string().required('Title is required'),
      content: Yup.string().required('Content is required'),
      link: Yup.string().required('Link is required')

   });

   const formik = useFormik({
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: {
         slider_image: "",
         mobile_image:"",
         slider_name: "",
         title: "",
         content: "",
         link: ""
      },
      onSubmit: (values, { resetForm }) => {


      },
      validationSchema: NewProductSizeSchema
   });

   const submitForm = () => {
      // let formData = new FormData();
      // for (const key in formik.values) {
      //    if (Object.hasOwnProperty.call(formik.values, key)) {
      //       formData.append(key, formik.values[key]);
      //    }
      // }

      doAPI.postWithImage('/slider/add', formik.values).then((res) => {
         setIsLoading(true)
         if (res.status) {
            notify(res.message);
            onFormSubmitSuccess();
            setIsLoading(false)
         } else {
            // notify(res.message[0]?.message[0]?.message, { variant: 'error' });
         }
      })
   }

   console.log(formik.values)

   return (
      <>
         <Form onSubmit={formik.handleSubmit}>
            <Notification />
            <Row>
               <Col md={12} className='mb-3'>
                  <Label aria-required>Slider Image</Label>
                  <CustomInput
                     type='file'
                     name="slider_image"
                     accept=".png,.jpg,.jpeg,.gif,.bmp"
                     placeholder='Slider Image'
                     className='form-control'
                     onChange={(event) => {
                        formik.setFieldValue("slider_image", event.currentTarget.files[0]);
                     }}
                     onBlur={formik.handleBlur}
                  />

                  {formik.touched.slider_image && formik.errors.slider_image ? (
                     <div className="error">{formik.errors.slider_image}</div>
                  ) : null}
               </Col>
               <Col md={12} className='mb-3'>
                  <Label aria-required>Slider Mobile Image</Label>
                  <CustomInput
                     type='file'
                     name="mobile_image"
                     accept=".png,.jpg,.jpeg,.svg"
                     placeholder='Slider Mobile Image'
                     className='form-control'
                     onChange={(event) => {
                        formik.setFieldValue("mobile_image", event.currentTarget.files[0]);
                     }}
                     onBlur={formik.handleBlur}
                  />

                  {formik.touched.mobile_image && formik.errors.mobile_image ? (
                     <div className="error">{formik.errors.mobile_image}</div>
                  ) : null}
               </Col>

               <Col md={12} className='mb-3'>
                  <Label aria-required>Title</Label>
                  <CustomInput type='text' name="title" placeholder='Title' className='form-control'
                     value={formik.values.title}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
                  {formik.touched.title && formik.errors.title ? (
                     <div className="error">{formik.errors.title}</div>
                  ) : null}
               </Col>

               <Col md={12} className='mb-3'>
                  <Label aria-required>Slider Name</Label>
                  <CustomInput type='text' name="slider_name" placeholder='Slider Name' className='form-control'
                     value={formik.values.slider_name}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
                  {formik.touched.slider_name && formik.errors.slider_name ? (
                     <div className="error">{formik.errors.slider_name}</div>
                  ) : null}
               </Col>

               <Col md={12} className='mb-3'>
                  <Label aria-required>Content</Label>
                  <CustomInput type='text' name="content" placeholder='Content' className='form-control'
                     value={formik.values.content}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
                  {formik.touched.content && formik.errors.content ? (
                     <div className="error">{formik.errors.content}</div>
                  ) : null}
               </Col>

               <Col md={12} className='mb-3'>
                  <Label aria-required>Link</Label>
                  <CustomInput type='text' name="link" placeholder='Link' className='form-control'
                     value={formik.values.link}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
                  {formik.touched.link && formik.errors.link ? (
                     <div className="error">{formik.errors.link}</div>
                  ) : null}
               </Col>

               <Col md={12} className='mb-3'>
                  <Button
                     // type="submit"
                     size='lg'
                     onClick={() => submitForm()}
                     className="px-md-5 w-100"
                     variant="primary">
                     {isLoading ? <BiLoader className='loader' /> : "Submit"}
                  </Button>
               </Col>
            </Row>
         </Form>
      </>
   )
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
    addShipping,
    addToCart,
    clearCart,
    getCartTotal,
    removeFromCart,
    addVat,
    addPriceType,
    removeSingleItem,
    updateQuantity,
    removeCoupon,
} from '../redux/AddToCart';
import CustomInput from './CustomInput';
import Image from './Image';
import Currency from './Currency';
import LTable from './LTable';
import AddCouponCode from './AddCouponCode';
import { AiOutlineDelete } from 'react-icons/ai';
import { amount } from './Utils';
import AddToDraft from './AddToDraft';

export const CartItems = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectedCurrency = useSelector((state) => state.property.selectedCurrency);
    const cart = useSelector((state) => state.cart);
    const { items } = cart;
    const afterCouponTotal = useSelector((state) => state.cart.couponResponse?.data?.sub_total);

    const [shippingCharge, setShippingCharge] = useState(false);
    const [showVatInput, setShowVatInput] = useState(true);
    const [customVat, setCustomVat] = useState(0);
    const [isPriceTypeSelected, setIsPriceTypeSelected] = useState(false);
    const [showCoupon, setShowCoupon] = useState(true);

    const cartTotal = amount(
        (cart?.shipping || 0) +
            cart?.subTotal +
            (parseInt(cart.totalVatCustom) > 0 ? parseInt(cart.totalVatCustom) : parseInt(cart.totalVat)) -
            (cart?.couponResponse?.data?.discount || 0.0)
    );

    const afterCouponCartTotal = amount(
        (afterCouponTotal > 0 ? afterCouponTotal : cart?.subTotal) +
            (cart?.shipping || 0) +
            (parseInt(cart.totalVatCustom) > 0 ? parseInt(cart.totalVatCustom) : parseInt(cart.totalVat))
    );

    const priceTypeArr = [
        { title: 'Gms', value: 'gram' },
        { title: 'Piece', value: 'piece' },
    ];

    useEffect(() => {
        dispatch(getCartTotal(selectedCurrency?.id));
        // dispatch(addVat(customVat))
    }, [dispatch, cart, selectedCurrency?.id]);

    const handleAddToCart = (product) => {
        dispatch(addToCart(product));
    };

    const handleQuantityChange = (sizeId, newQuantity) => {
        dispatch(updateQuantity({ sizeId, newQuantity }));
    };

    const handleRemoveFromCart = (product) => {
        dispatch(removeFromCart(product));
    };

    const handleShippingChange = (event) => {
        const shippingCharge = parseFloat(event.target.value); // Assuming it's a number input
        dispatch(addShipping(shippingCharge));
    };
    const handleClearCart = () => {
        dispatch(clearCart());
    };
    const handlePriceType = (event, sizeId) => {
        setIsPriceTypeSelected(true);
        dispatch(addPriceType({ price_type: event.target.value, size_id: sizeId }));
    };

    const addCustomVat = (event) => {
        setCustomVat(event.target.value);
        dispatch(addVat(event.target.value));
    };

    const cartProducts = [];

    items.map((i) =>
        cartProducts.push({
            id: i.id,
            size_id: i.selectedSize,
            quantity: i.quantity,
            price_type: i.price_type !== null ? i.price_type : 'gram',
        })
    );

    const handleContinue = () => {
        if (cartTotal !== afterCouponCartTotal) {
            // Show SweetAlert confirmation dialog
            const options = {
                title: 'Re-Apply Coupon',
                text: 'Your cart has been updated since the coupon was applied. Would you like to re-apply the coupon?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Re-apply Coupon!',
                cancelButtonText: 'Continue with checkout',
            };

            // Use Swal.fire to show the SweetAlert dialog
            Swal.fire(options).then((result) => {
                if (result.isConfirmed) {
                    dispatch(removeCoupon());
                } else {
                    console.log('Did not re-apply coupon.');
                    navigate('/checkout');
                }
            });
        } else {
            navigate('/checkout');
        }
    };

    return (
        <div className="shadow-lg h-100">
            <div className="newCardHeader border-bottom">
                <AddToDraft items={items} />
            </div>
            <div className="px-xl-3 px-0 bg-white pt-xl-3 pt-0 cartTable">
                <div className="cartWrapper">
                    <LTable>
                        <tbody>
                            {items !== undefined &&
                                items.map((i, index) => (
                                    <tr key={index}>
                                        <td className="d-flex">
                                            <div>
                                                <Image
                                                    style={{
                                                        border: '1px solid #ddd',
                                                        objectFit: 'cover',
                                                    }}
                                                    src={`${i?.product_images[0]?.image_path}/${i?.product_images[0]?.image_name}`}
                                                    width={45}
                                                    height={45}
                                                    className={'rounded'}
                                                />
                                            </div>
                                            <div className="ps-3">
                                                <p className="fw-normal mb-0 text-muted">
                                                    <span className="fw-bold text-black">{i.name},</span>
                                                    <br />
                                                    Category - &nbsp; {i?.category?.name}
                                                    <br />
                                                    {i.price_type === 'piece'
                                                        ? i.sizes
                                                              ?.find((size) => size.id === i.size_id)
                                                              ?.size_prices.find(
                                                                  (j) => j.currency_id === selectedCurrency?.id
                                                              )?.vat_gram !== 0 && (
                                                              <>
                                                                  VAT -{' '}
                                                                  {
                                                                      i.sizes
                                                                          ?.find((size) => size.id === i.size_id)
                                                                          ?.size_prices.find(
                                                                              (j) =>
                                                                                  j.currency_id === selectedCurrency?.id
                                                                          )?.vat_gram
                                                                  }{' '}
                                                                  &nbsp;|&nbsp;
                                                              </>
                                                          )
                                                        : i.sizes
                                                              ?.find((size) => size.id === i.size_id)
                                                              ?.size_prices.find(
                                                                  (j) => j.currency_id === selectedCurrency?.id
                                                              )?.vat_piece !== 0 && (
                                                              <>
                                                                  VAT -{' '}
                                                                  {
                                                                      i.sizes
                                                                          ?.find((size) => size.id === i.size_id)
                                                                          ?.size_prices.find(
                                                                              (j) =>
                                                                                  j.currency_id === selectedCurrency?.id
                                                                          )?.vat_piece
                                                                  }{' '}
                                                                  &nbsp;|&nbsp;
                                                              </>
                                                          )}
                                                    SKU - {i?.sku} &nbsp;|&nbsp;
                                                    {i.price_type === 'piece'
                                                        ? i.sizes
                                                              ?.find((size) => size.id === i.size_id)
                                                              ?.size_prices.find(
                                                                  (j) => j.currency_id === selectedCurrency?.id
                                                              )?.discount != 0 && (
                                                              <>
                                                                  Discount -{' '}
                                                                  {
                                                                      i.sizes
                                                                          ?.find((size) => size.id === i.size_id)
                                                                          ?.size_prices.find(
                                                                              (j) =>
                                                                                  j.currency_id === selectedCurrency?.id
                                                                          )?.Discount
                                                                  }{' '}
                                                                  &nbsp;|&nbsp;
                                                              </>
                                                          )
                                                        : i.sizes
                                                              ?.find((size) => size.id === i.size_id)
                                                              ?.size_prices.find(
                                                                  (j) => j.currency_id === selectedCurrency?.id
                                                              )?.discount != 0 && (
                                                              <>
                                                                  Discount -{' '}
                                                                  {
                                                                      i.sizes
                                                                          ?.find((size) => size.id === i.size_id)
                                                                          ?.size_prices.find(
                                                                              (j) =>
                                                                                  j.currency_id === selectedCurrency?.id
                                                                          )?.Discount
                                                                  }{' '}
                                                                  &nbsp;|&nbsp;
                                                              </>
                                                          )}
                                                    {/* Discount - {i?.discount} &nbsp;|&nbsp; */}
                                                    Size -{' '}
                                                    {i.sizes &&
                                                        i.sizes.find((j) => j.id === i.size_id)?.size_name?.name}
                                                    &nbsp;|&nbsp; Stock -{' '}
                                                    {i.sizes.find((size) => size.id === i.size_id)?.stock}
                                                </p>
                                                <div className="mobileTd">
                                                    <div className="d-flex my-2">
                                                        <select
                                                            className="form-control py-1"
                                                            onChange={(event) => handlePriceType(event, i.size_id)}
                                                            defaultValue={i.price_type}
                                                            style={{ width: 90, height: 35 }}>
                                                            <option>Price type</option>
                                                            {priceTypeArr.map((row, index) => (
                                                                <option key={index} value={row.value}>
                                                                    {row.title}
                                                                </option>
                                                            ))}
                                                        </select>

                                                        <div className="d-flex qtySection">
                                                            <Button
                                                                className="py-1"
                                                                onClick={() => handleRemoveFromCart(i)}>
                                                                -
                                                            </Button>

                                                            <input
                                                                type="number"
                                                                min={1}
                                                                max={100}
                                                                className="mx-1 rounded border"
                                                                onChange={(event) =>
                                                                    handleQuantityChange(
                                                                        i.size_id,
                                                                        parseInt(event.target.value) || 1
                                                                    )
                                                                }
                                                                value={i.quantity}
                                                            />
                                                            <Button
                                                                className={
                                                                    i.quantity ===
                                                                    i.sizes.find((size) => size.id === i.size_id)?.stock
                                                                        ? 'py-1 disabled'
                                                                        : 'py-1'
                                                                }
                                                                onClick={() => handleAddToCart(i)}>
                                                                +
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="desktopTd" width={100}>
                                            <div className="d-flex">
                                                <select
                                                    className="form-control py-1"
                                                    onChange={(event) => handlePriceType(event, i.size_id)}
                                                    defaultValue={i.price_type}
                                                    style={{ width: 90, height: 35 }}>
                                                    <option>Price type</option>
                                                    {priceTypeArr.map((row, index) => (
                                                        <option key={index} value={row.value}>
                                                            {row.title}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </td>
                                        <td className="desktopTd qtySection">
                                            <Button className="py-1" onClick={() => handleRemoveFromCart(i)}>
                                                -
                                            </Button>
                                            <input
                                                type="number"
                                                min={1}
                                                max={i.sizes.find((size) => size.id === i.size_id)?.stock}
                                                className="mx-1 rounded border"
                                                onChange={(event) => {
                                                    const enteredQuantity = parseInt(event.target.value) || 1;
                                                    const maxStock =
                                                        i.sizes.find((size) => size.id === i.size_id)?.stock || 1;
                                                    const newQuantity = Math.min(enteredQuantity, maxStock);

                                                    handleQuantityChange(i.size_id, newQuantity);
                                                }}
                                                value={i.quantity}
                                            />

                                            <OverlayTrigger
                                                placement={'top'}
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        Remaining Stock{' '}
                                                        {i.sizes.find((size) => size.id === i.size_id)?.stock -
                                                            i.quantity}
                                                    </Tooltip>
                                                }>
                                                <Button
                                                    className={
                                                        i.quantity ===
                                                        i.sizes.find((size) => size.id === i.size_id)?.stock
                                                            ? 'py-1 disabled'
                                                            : 'py-1'
                                                    }
                                                    onClick={() => handleAddToCart(i)}>
                                                    +
                                                </Button>
                                            </OverlayTrigger>
                                        </td>
                                        <td className="">
                                            <div className="d-inline-flex justify-content-end">
                                                {i.price_type === 'piece' ? (
                                                    <>
                                                        <Currency />{' '}
                                                        {amount(
                                                            i.sizes
                                                                ?.find((size) => size.id === i.size_id)
                                                                ?.size_prices.find(
                                                                    (j) => j.currency_id === selectedCurrency?.id
                                                                )?.sell_price_piece * i.quantity
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <Currency />{' '}
                                                        {amount(
                                                            i.sizes
                                                                ?.find((size) => size.id === i.size_id)
                                                                ?.size_prices.find(
                                                                    (j) => j.currency_id === selectedCurrency?.id
                                                                )?.sell_price_gram * i.quantity
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                        <td>
                                            <AiOutlineDelete onClick={() => dispatch(removeSingleItem(i))} />
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </LTable>
                </div>
            </div>
            {/* <hr /> */}
            <div className="px-3 bg-white pt-md-3 pt-0 order_summery_holder">
                <h5 className="fw-bold mb-0">Order Summary</h5>

                <table className="w-100 mt-3">
                    <tbody>
                        <tr>
                            <td>Sub Total</td>
                            <td align="right">
                                <Currency /> {amount(cart.subTotal && cart.subTotal)}
                            </td>
                        </tr>
                        <tr>
                            <td>VAT</td>
                            <td align="right">
                                {!showVatInput ? (
                                    <>
                                        <CustomInput
                                            className="form-control text-end input-sm"
                                            style={{ width: 100 }}
                                            readOnly
                                            placeholder="Add Vat"
                                            name="vat_amount"
                                            value={
                                                parseInt(cart?.totalVatCustom) > 0
                                                    ? amount(cart?.totalVatCustom || 0.0)
                                                    : amount(cart?.totalVat || 0.0)
                                            }
                                            defaultValue={
                                                parseInt(cart?.totalVatCustom) > 0
                                                    ? amount(cart?.totalVatCustom || 0.0)
                                                    : amount(cart?.totalVat || 0.0)
                                            }
                                            type="number"
                                            onChange={(event) => addCustomVat(event)}
                                        />
                                    </>
                                ) : parseInt(cart?.totalVatCustom) > 0 ? (
                                    amount(cart?.totalVatCustom || 0.0)
                                ) : (
                                    amount(cart?.totalVat || 0.0)
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="d-inline-flex align-items-center">
                                    Coupon Code Discount &nbsp;{' '}
                                    <AddCouponCode
                                        productArr={cartProducts}
                                        cartTotal={cartTotal}
                                        afterCouponCartTotal={afterCouponCartTotal}
                                    />
                                </div>
                            </td>
                            <td align="right">
                                {showCoupon && (
                                    <div className="d-inline-flex align-items-center">
                                        {amount(cart?.couponResponse?.data?.discount || 0.0)}
                                    </div>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Shipping Charge &nbsp; <Link onClick={() => setShippingCharge(true)}>Update</Link>
                            </td>
                            <td align="right">
                                {shippingCharge ? (
                                    <>
                                        <CustomInput
                                            className="form-control text-end input-sm"
                                            type="text"
                                            value={cart?.shipping ? cart?.shipping : 0}
                                            placeholder="Shipping Charge"
                                            onChange={(event) => handleShippingChange(event)}
                                        />{' '}
                                    </>
                                ) : (
                                    <>{amount(cart?.shipping) ? amount(cart?.shipping) : 0}</>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td align="right">
                                <div className="d-flex justify-content-end">
                                    <Currency /> &nbsp;
                                    {cartTotal}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {items.length > 0 && (
                <>
                    <div className="my-3 px-3">
                        <Row>
                            <Col>
                                <Button variant="danger" className="w-100" onClick={() => handleClearCart()}>
                                    Reset Cart
                                </Button>
                            </Col>
                            <Col>
                                <Button className="btn btn-primary w-100" onClick={handleContinue}>
                                    Continue
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </>
            )}
        </div>
    );
};

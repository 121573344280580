/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import Upload from "./cropper_lt/Upload";
import Popup from "./cropper_lt/Popup";
import Service from '../http';
import { Link } from 'react-router-dom';
import { AiFillDelete } from 'react-icons/ai';
import Notification, { notify } from './Notification';

export default function CustomMultiUpload({ multiple, placeholder, onChange, imageDeleted, images, isEdit, productId, isFormSubmitted, fetchedImages }) {
    const services = new Service();

    const [fileList, setFileList] = useState([]);
    const [open, setOpen] = React.useState(false);

    const [croppedImages, setCroppedImages] = React.useState([]);
    const [croppedImagesObj, setCroppedImagesObj] = React.useState([]);

    useEffect(() => {
        if (fetchedImages?.length) { setCroppedImagesObj(fetchedImages) }
    }, [fetchedImages])

    const [image, setImage] = React.useState('');

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        onChange(croppedImagesObj);
    }, [croppedImagesObj]);

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setCroppedImagesObj(files);
        onChange(files); // Pass the updated cropped images data
    };

    const handleRemoveFile = (index, id) => {

        if (isEdit === true) {
            imageDeleted(false);
            const updatedFiles = [...croppedImagesObj];
            updatedFiles.splice(index, 1);
            setCroppedImagesObj(updatedFiles);
            onChange(updatedFiles);
            services &&
                services.put("product/image/delete/" + id)
                    .then((res) => {
                        if (res.status) {
                            notify(res.message);
                            imageDeleted(true);
                            console.log({ updatedFiles }, { index })
                        } else {
                            notify(res.message[0]?.message);
                            imageDeleted(false);
                        }
                    }).catch((error) => { console.error('Error - ', error) });
        } else {
            const updatedFiles = [...croppedImagesObj];
            updatedFiles.splice(index, 1);
            setCroppedImagesObj(updatedFiles);
            onChange(updatedFiles);
        }
    };

    return (
        <>
            <Notification />
            <div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }} >

                    {/* <Popup
                        open={open}
                        handleClose={handleClose}
                        image={image}
                        getCroppedFileObject={(image) => {
                            setCroppedImagesObj((prevImages) => [...prevImages, image]);
                        }}
                        getCroppedFile={(image) => {
                            setImage(image);
                            handleClose();
                            setCroppedImages((prevImages) => [...prevImages, image]);
                        }}
                    /> */}
                </div>
            </div>
            <div className="customMultiUpload">
                <div className="inputHolder">
                    <div className='content'>
                        {/* <h5>Select Images </h5> */}
                        {/* {multiple === true && <small> (Max. 5 Images)</small>} */}
                    </div>
                    <Upload
                        getUploadedFile={(image) => {
                            const arr = Object.values(image);
                            setCroppedImagesObj(arr);
                        }}
                    />
                </div>

                {croppedImagesObj.length === 0 && images?.length > 0 && (
                    <div className="previewHolder" style={{ marginTop: 10 }}>
                        {images && images.map((file, index) => {
                            return (
                                <div key={index} className="filePreview">
                                    {file instanceof File ? (
                                        <img src={URL.createObjectURL(file)} alt="img" />
                                    ) : (
                                        <img src={file?.image_path + '/' + file?.image_name} alt={`File ${index + 1}`} /> // Assume file is the URL of the cropped image
                                    )}

                                    <div className='actionbtn'>
                                        <button type='button' onClick={() => {
                                            handleRemoveFile(index, file.id)
                                            // file.id && removeImage(file.id)
                                        }}>
                                            <AiFillDelete />
                                        </button>

                                    </div>
                                    {
                                        file instanceof File ?
                                            <small>{((file.size / 1024) / 1024).toFixed(2)} MB</small>
                                            : ""
                                    }


                                </div>
                            )
                        }
                        )}
                    </div>
                )}
                {
                    croppedImagesObj?.length > 0 && (
                        <div className="previewHolder" style={{ marginTop: 10 }}>
                            {croppedImagesObj.map((file, index) => (
                                <div key={index} className="filePreview">
                                    {file instanceof File ? (
                                        <img src={URL.createObjectURL(file)} alt="img" />
                                    ) : (
                                        <img src={file?.thumbnail} alt={`File ${index + 1}`} /> // Assume file is the URL of the cropped image
                                    )}
                                    <div className='actionbtn'>
                                        <button type='button' onClick={() => {
                                            handleRemoveFile(index)
                                            // file.id && removeImage(file.id)
                                        }}>
                                            <AiFillDelete />
                                        </button>

                                    </div>
                                    {
                                        file instanceof File ?
                                            <small>{((file.size / 1024) / 1024).toFixed(2)} MB</small>
                                            : ""
                                    }
                                </div>
                            ))}
                        </div>
                    )
                }

            </div>
        </>
    );
}


import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import categoryReducer from "./categorySlice"; // Update the import path
import colorReducer from "./colorSlice"; // Update the import path

import productReducer from "./productSlice";
import cartReducer from "./AddToCart";
import themeReducer from "./themeSlice";
import userReducer from "./loginSlice";
import orderReducer from "./orderSlice";
import { localStorageMiddleware } from "../middleware/localStorageMiddleware";
import couponReducer from "./couponSlice";
import sizeReducer from "./sizeSlice";
import currencyReducer from "./currencySlice";
import propertyReducer from "./PropertySlice";
import consignmentReducer from "./consignmentSlice";
import carouselReducer from "./carouselSlice";

import vatReducer from "./vatSlice";
import userListReducer from "./usersSlice";
import userDetailsReducer from "./userDetailsSlice";
import userAddressListReducer from "./userAddressSlice";

import countryReducer from "./countrySlice";
import stateReducer from "./stateSlice";
import cityReducer from "./citySlice";
import bannerReducer from "./bannerSlice";

import tagReducer from "./tagSlice";

import orderStatusReducer from "./orderStatusSlice";
import draftOrderReducer from "./draftOrderSlice";
import locationReducer from "./LocationSlice";

import assignCourierCompanyReducer from "./assignCourierCompanySlice";

import courierCompaniesReducer from "./courierCompaniesSlice";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["cart"],
};

const rootReducer = combineReducers({
  cart: cartReducer,
  product: productReducer,
  theme: themeReducer,
  user: userReducer,
  order: orderReducer,
  category: categoryReducer,
  color: colorReducer,
  carousel: carouselReducer,
  coupons: couponReducer,
  sizes: sizeReducer,
  currency: currencyReducer,
  property: propertyReducer,
  consignmentlist: consignmentReducer,
  vatSetting: vatReducer,
  userList: userListReducer,
  userDetails: userDetailsReducer,
  userAddressList: userAddressListReducer,
  country: countryReducer,
  state: stateReducer,
  city: cityReducer,
  banner: bannerReducer,
  tags: tagReducer,
  orderStatus: orderStatusReducer,
  draftOrders: draftOrderReducer,
  location: locationReducer,
  courierCompanies: courierCompaniesReducer,
  assignCourierCompany: assignCourierCompanyReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(localStorageMiddleware),
  devTools: true,
});

const persistor = persistStore(store);

export { store, persistor };

import React, { useEffect, useState } from "react";
import Notification from "../../Components/Notification";
import { Card } from "react-bootstrap";
// import Service from "../../http";
import { useDispatch, useSelector } from "react-redux";
import Pagelayout from "../../Layouts/Pagelayout";
import LTable from "../../Components/LTable";
// import Swal from "sweetalert2";
import { fetchUsers } from "../../redux/usersSlice";
// import AddUser from "./AddUser";
import Pagination from "../../Components/Pagination";
// import EditUser from "./EditUser";
import { formatDate, tableSerialNumber } from "../../Components/Utils";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";

export default function UserList() {
  //   const services = new Service();

  const dispatch = useDispatch();

  const [roleState, setRoleState] = useState("");

  //   const [userData, setUserData] = useState();

  const [page, setPage] = useState(1);

  const userList = useSelector((state) => state.userList);
  useEffect(() => {
    dispatch(fetchUsers({ role_id: parseInt(roleState), page: page }));
  }, [dispatch, roleState, page]);


  const handleSearchClick = (event) => {
    const thSearchable = event.currentTarget.closest(".th_searchable");
    if (thSearchable) {
      const input =
        thSearchable.querySelector("input") ||
        thSearchable.querySelector("select");
      const title = thSearchable.querySelector(".thTitle");
      const icon = thSearchable.querySelector(".icon");

      if (input && title) {
        input.classList.toggle("show-input");
        title.classList.toggle("show-thTitle");
        icon.classList.toggle("toggleIcon");
      }
    }
  };

  const handlePageChange = (newPage) => {
    setPage(parseInt(newPage));
  };

  const handleGoToPage = (event) => {
    setPage(parseInt(event.target.value));
  };

  return (
    <Pagelayout
      title="User"
      breadcrumbs={[
        { title: "Dashboard", link: "/dashboard" },
        { title: "User list", link: "#" },
      ]}
    >
      <Notification />
      <Card>
        <Card.Body>
          <LTable isLoading={userList.status === "pending"}>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Country</th>
                <th>Currency</th>
                <th width={50}>
                  <div className="th_searchable">
                    <span className="thTitle show-thTitle ">Role</span>
                    <select
                      onChange={(event) => setRoleState(event.target.value)}
                    >
                      <option value={0}>All</option>
                      <option value={3}>User</option>
                      <option value={5}>Staff</option>
                    </select>
                    <span className="icon" onClick={handleSearchClick}>
                      <AiOutlineSearch className="searchIcon" />
                      <AiOutlineClose
                        className="closeIcon"
                        onClick={() => setRoleState("")}
                      />
                    </span>
                  </div>
                </th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {userList?.userList.rows &&
                userList?.userList.rows.map((i, index) => {
                  const serialNumber = tableSerialNumber(
                    index,
                    userList?.userList?.cur_page,
                    userList?.userList?.rows.length,
                    25
                  );
                 return (
                   <tr key={index}>
                    <td>
                      {serialNumber}
                    </td>
                    <td>
                      <span className="fw-bold">
                        {i.first_name} {i.family_name},
                      </span>{" "}
                      <small>({i.gender === "M" ? "Male" : "Female"})</small>
                    </td>
                    <td>{i.email}</td>

                    <td className="text-nowrap">
                      {/* <PhoneNumberFormatter phoneNumber={i?.mobile } region={i.country?.phone_code} /> */}
                      +{i.country?.phone_code} {i?.mobile}
                    </td>
                    <td >{i.country?.name}</td>
                    <td className="text-nowrap">
                      {i.country?.currency_name}{" "}
                      <b>{i.country?.currency_symbol}</b>
                    </td>
                    <td>{i.user_role?.title}</td>
                    <td>{formatDate(i.updated_at)}</td>
                  </tr>
                )})}
            </tbody>
          </LTable>
          <Pagination
            pagecount={userList?.total_pages}

            handleGoToPage={handleGoToPage}
            currentpage={page || 1}
            onPageChange={handlePageChange}
          />
        </Card.Body>
      </Card>

      {/* <Modal show={UserEditForm} size="lg" onHide={handleCloseUserEditForm}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditUser
            userData={userData}
            onFormSubmitSuccess={handleFormSubmitSuccess}
          />
        </Modal.Body>
      </Modal>

      <Modal show={UserAddForm} size="lg" onHide={handleCloseUserAddForm}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddUser onFormSubmitSuccess={handleFormSubmitSuccess} />
        </Modal.Body>
      </Modal> */}
    </Pagelayout>
  );
}

import React from 'react'
import Pagelayout from '../../../Layouts/Pagelayout'
import { Badge, Button, Card, Col, Dropdown, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import CustomDatePicker from '../../../Components/CustomDatePicker'
import Label from '../../../Components/Label'
import CustomSelect from '../../../Components/CustomSelect'
import LTable from '../../../Components/LTable'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'

export default function NewDocument() {

  const { documentId } = useParams()

  console.log(documentId)
  return (
    <Pagelayout
      title="Inventory"
      breadcrumbs={
        [
          { title: 'Inventory', link: '/inventory' },
          { title: 'Purchase', link: '/inventory/purchase' },
          { title: documentId, link: `/inventory/purchase/${documentId}` },
        ]
      }>

      <Row>
        <Col md={8}>
          <Card className='overflow-visible mb-4'>
            <Card.Body>
              <div className='d-md-flex'>
                <div className='flex-grow-1 pe-3'>
                  <Label aria-required>Document Title</Label>
                  <input type='text' className='form-control' placeholder='Document Title' />
                  <small className='text-muted'>If left blank, number wukk be generated automatically</small>
                </div>
                <div>
                  <Label aria-required>Document Date</Label>
                  <CustomDatePicker
                    name="dob"
                    className="form-control"
                    // value={formik.values.dob}
                    onChange={(value) => console.log(value)}
                  // onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <hr />
              <Row>
                {/* <Col lg={12}>
                  <h5 className='d-block'>Store</h5>

                </Col> */}
                <Col lg={3} md={4} sm={6}>
                  <Label aria-required>Store From</Label>
                  <CustomSelect
                    className={'w-100'}
                    placeholder="Select Supplier"
                    //   value={formik.values.country}
                    onChange={(value) =>
                      console.log(value)
                    }
                    options={[{ label: 'Supplier name', value: 1 }, { label: 'Supplier name', value: 2 }]}
                  />
                </Col>


                <Col lg={3} md={4} sm={6}>
                  <Label aria-required>Store to</Label>
                  <CustomSelect
                    className={'w-100'}
                    placeholder="Store to"
                    //   value={formik.values.country}
                    onChange={(value) =>
                      console.log(value)
                    }
                    options={[{ label: 'Product Number', value: 1 }, { label: 'Barcode', value: 2 }]}
                  />
                </Col>

              </Row>
              <hr />
              <Row>
                <Col lg={12} className='mb-3'>
                  <div>
                    <Label aria-required>Informatione</Label>
                    <textarea type='text' rows={4} className='form-control'></textarea>
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <div>
                    <Label aria-required>Delivery Date</Label>
                    <CustomDatePicker
                      name="dob"
                      className="form-control"
                      // value={formik.values.dob}
                      onChange={(value) => console.log(value)}
                    // onBlur={formik.handleBlur}
                    />
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <div>
                    <Label aria-required>Payment Period in days</Label>
                    <input type='text' placeholder='Payment period in days' className='form-control' />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className='overflow-visible mb-4'>
            <Card.Body>
              <div className='d-flex'>
                <Button size='lg'>Save</Button> &nbsp;&nbsp;
                <Button size='lg'>Accept</Button> &nbsp;&nbsp;
                <Button size='lg'><AiOutlineClose /></Button> &nbsp;&nbsp;
                <Dropdown>
                  <Dropdown.Toggle size='lg' variant="primary" id="dropdown-basic">
                    <AiOutlineMenu />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Copy Document</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Export to PDF</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Print Labels</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Delete Document</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <hr />
              <div className='border p-3 rounded my-3'>
                <table className='w-100'>
                  <tbody>
                    <tr>
                      <td>Locked By</td>
                      <td align='right'>Lorem Ipsum</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <table className='w-100'>
                <tbody>
                  <tr>
                    <td>Status</td>
                    <td align='right'><Badge bg="warning">Pending</Badge></td>
                  </tr>
                  <tr>
                    <td>Created</td>
                    <td align='right'>20 Nov, 2023, 12:30</td>
                  </tr>
                </tbody>
              </table>

              <div className='border p-3 rounded my-3'>
                <table className='w-100'>
                  <tbody>
                    <tr>
                      <td>Total without VAT</td>
                      <td align='right'>0.00</td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td align='right'>0.00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>


      <Card className='overflow-visible'>
        <Card.Body>
          <div className='table_filter equal_space'>
            <div className='table_filter_item align-items-center'>
              <input type='search' placeholder='Search By name, product number, barcode' className='form-control' />
            </div>
          </div>

          <LTable>
            <thead>
              <tr>
                <th>#</th>
                <th>Product name</th>
                <th>Product number</th>
                <th>Barcode</th>
                <th>Unit</th>
                <th>Quantity</th>
                <th>Total</th>
                <th>Unit price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Lorem ipsum dolor sit amet consectetur adipi</td>
                <td>6565</td>
                <td>6565989898</td>
                <td>PCS</td>
                <td><input type="text" value={'1'} className='form-control' /></td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Lorem ipsum dolor sit amet consectetur adipi</td>
                <td>6565</td>
                <td>6565989898</td>
                <td>PCS</td>
                <td><input type="text" value={'1'} className='form-control' /></td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Lorem ipsum dolor sit amet consectetur adipi</td>
                <td>6565</td>
                <td>6565989898</td>
                <td>PCS</td>
                <td><input type="text" value={'1'} className='form-control' /></td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Lorem ipsum dolor sit amet consectetur adipi</td>
                <td>6565</td>
                <td>6565989898</td>
                <td>PCS</td>
                <td><input type="text" value={'1'} className='form-control' /></td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Lorem ipsum dolor sit amet consectetur adipi</td>
                <td>6565</td>
                <td>6565989898</td>
                <td>PCS</td>
                <td><input type="text" value={'1'} className='form-control' /></td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Lorem ipsum dolor sit amet consectetur adipi</td>
                <td>6565</td>
                <td>6565989898</td>
                <td>PCS</td>
                <td><input type="text" value={'1'} className='form-control' /></td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Lorem ipsum dolor sit amet consectetur adipi</td>
                <td>6565</td>
                <td>6565989898</td>
                <td>PCS</td>
                <td><input type="text" value={'1'} className='form-control' /></td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Lorem ipsum dolor sit amet consectetur adipi</td>
                <td>6565</td>
                <td>6565989898</td>
                <td>PCS</td>
                <td><input type="text" value={'1'} className='form-control' /></td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Lorem ipsum dolor sit amet consectetur adipi</td>
                <td>6565</td>
                <td>6565989898</td>
                <td>PCS</td>
                <td><input type="text" value={'1'} className='form-control' /></td>
                <td>0</td>
                <td>0</td>
              </tr>
            </tbody>
          </LTable>
        </Card.Body>
      </Card>
    </Pagelayout>
  )
}

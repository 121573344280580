import React, { useEffect, useState } from "react";
import LTable from "../../Components/LTable";
import Pagelayout from "../../Layouts/Pagelayout";
import Notification, { notify } from "../../Components/Notification";
import { Card, Dropdown, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCourierCompanies,
  changeCourierCompanyStatus,
  deleteCourierCompany,
  updateCourierCompany,
  clearResponse,
} from "../../redux/courierCompaniesSlice";
import { formatDate, tableSerialNumber } from "../../Components/Utils";
import { MdDelete, MdEdit } from "react-icons/md";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import doAPI from "../../api";
import AddCompany from "./AddCompany";
import EditCompany from "./EditCompany";

export default function CourierCompanies() {
  const dispatch = useDispatch();
  const [companyInfo, setCompanyInfo] = useState(null);

  const [CompanyAddForm, setShowCompanyAddForm] = useState(false);
  const handleCloseCompanyAddForm = () => setShowCompanyAddForm(false);
  const handleShowCompanyAddForm = () => setShowCompanyAddForm(true);

  const [CompanyEditForm, setShowCompanyEditForm] = useState(false);
  const handleCloseCompanyEditForm = () => setShowCompanyEditForm(false);
  const handleShowCompanyEditForm = (data) => {
    setCompanyInfo(data);
    setShowCompanyEditForm(true);
  };
  const courierCompanies = useSelector((state) => state.courierCompanies);

  const fetchEntries = () => {
    dispatch(fetchCourierCompanies(1));
  };

  useEffect(() => {
    if (courierCompanies?.response !== null) {
      notify(courierCompanies?.response?.message);
      handleCloseCompanyAddForm();
      handleCloseCompanyEditForm();
      fetchEntries();

      setTimeout(() => {
        dispatch(clearResponse());
      }, 1000);
    }
  }, [courierCompanies?.response]);

  useEffect(() => {
    fetchEntries();
  }, [dispatch]);

  const handleStatusChange = (companyId) => {
    doAPI
      .putData(`logistics-company/change-status/${companyId}`, {})
      .then((response) => {
        if (response.status) fetchEntries();
      });
  };

  const deleteProduct = (companyId) => {
    Swal.fire({
      title: "Delete Company",
      text: "Are you sure you want to delete this company?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        doAPI
          .putData(`logistics-company/delete/${companyId}`, {})
          .then((response) => {
            if (response.status) fetchEntries();
          });
        fetchEntries();
      }
    });
  };

  return (
    <Pagelayout
      title="Courier Companies"
      breadcrumbs={[
        { title: "Dashboard", link: "/dashboard" },
        { title: "Courier Companies", link: "/courier-companies" },
      ]}
      actions={
        <>
          <Link
            className="btn btn-primary btn-lg"
            to={"#"}
            onClick={() => handleShowCompanyAddForm()}
          >
            Add Company
          </Link>{" "}
          &nbsp;
          {/* <Link className='btn btn-primary btn-lg' to={'/products/import'}>Import Product</Link> */}
        </>
      }
    >
      <Notification />
      <Card className="overflow-visible">
        <Card.Body>
          <LTable
            isLoading={courierCompanies?.courierCompanies?.data?.isLoading}
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Company Name</th>
                <th>Create Date</th>
                <th>Tracking Link</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {courierCompanies?.courierCompanies?.data?.result.map(
                (i, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{i?.company_name}</td>
                      <td>{formatDate(i?.created_at)}</td>
                      <td><a target="_blank" href={i?.tracking_link}>{i?.tracking_link}</a></td>
                      <td>
                        <label
                          onClick={() => handleStatusChange(i.id, i.status)}
                          className={
                            i.status === 1
                              ? "custom_check active"
                              : "custom_check"
                          }
                        >
                          <div className="custom_check_wrapper">
                            <span></span>
                            <label>
                              {i.status === 1 ? "Active" : "Deactivated"}
                            </label>
                          </div>
                        </label>
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            <BiDotsVerticalRounded />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              as={Link}
                              to={""}
                              onClick={() => handleShowCompanyEditForm(i)}
                            >
                              <MdEdit /> Edit
                            </Dropdown.Item>

                            <Dropdown.Item
                              onClick={() => deleteProduct(i.id)}
                              href="#"
                            >
                              <MdDelete /> Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </LTable>
        </Card.Body>
      </Card>

      <Modal show={CompanyAddForm} onHide={handleCloseCompanyAddForm}>
        <Modal.Header closeButton>
          <Modal.Title>Add Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddCompany onSuccess={() => setShowCompanyAddForm(false)} />
        </Modal.Body>
      </Modal>

      <Modal show={CompanyEditForm} onHide={handleCloseCompanyEditForm}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditCompany
            data={companyInfo}
            onSuccess={() => setShowCompanyEditForm(false)}
          />
        </Modal.Body>
      </Modal>
    </Pagelayout>
  );
}

import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import Image from "./Image";
import { AiOutlineArrowRight, AiOutlineMenu } from "react-icons/ai";
import { useSelector } from "react-redux";

export default function Sidebar() {
  const menu = [
    { title: "POS", icon: "ic_pos.svg", link: "/pos" },

    { title: "Dashboard", icon: "ic_dashboard.svg", link: "/dashboard" },

    {
      title: "Coupon Code",
      icon: "ic_discount_and_coupons.svg",
      link: null,
      child: [{ title: "Coupon Code List", icon: "", link: "/coupon/list" }],
    },

    {
      title: "Order Management",
      icon: "ic_orders.svg",
      link: null,
      child: [
        { title: "Order List", icon: "", link: "/order/list" },
        { title: "Draft Orders", icon: "", link: "/order/draft-orders" },

        // { title: 'Create Guest Order', icon: '', link: '/order/create-guest-order' },
      ],
    },
    {
      title: "Product Management",
      icon: "ic_cart.svg",
      link: null,
      child: [
        { title: "Product", icon: "", link: "/products" },
        { title: "Manage Size", icon: "", link: "/products-sizes-setting" },
        { title: "Manage Tags", icon: "", link: "/products-tags" },
        { title: "Manage Colors", icon: "", link: "/products-colors" },
      ],
    },
    {
      title: "Setting Frontend",
      icon: "ic_carousal.svg",
      link: null,
      child: [
        { title: "Carousel", icon: "", link: "/carousel/list" },
        { title: "Banner", icon: "", link: "/banner/list" },
      ],
    },
    {
      title: "Category",
      icon: "ic_category.svg",
      link: null,
      child: [{ title: "Category List", icon: "", link: "/categories" }],
    },
    {
      title: "User Management",
      icon: "ic_user.svg",
      link: null,
      child: [{ title: "User List", icon: "", link: "/user-list" }],
    },

    // { title: 'Carousel', icon: 'ic_carousal.svg', link: '/carousel/list' },

    {
      title: "Inventory",
      icon: "ic_kanban.svg",
      link: null,
      child: [
        { title: "Purchase", icon: "", link: "/inventory/purchase" },
        { title: "Transfer", icon: "", link: "/inventory/products/transfer" },
        // { title: 'Write-off', icon: '', link: '/inventory/products/write-off' },
        {
          title: "Stock adjustment",
          icon: "",
          link: "/inventory/stock-adjustment",
        },
        // { title: 'Reorder', icon: '', link: '/inventory/products/reorder' },
        {
          title: "Purchase Import",
          icon: "",
          link: "/inventory/purchase-import",
        },
        { title: "Store Balance", icon: "", link: "/inventory/store-balance" },
        // { title: 'Transaction Report', icon: '', link: '/inventory/transaction-report' },
      ],
    },
    {
      title: "Courier Companies",
      icon: "ic_courier_companies.svg",
      link: "/courier-companies",
    },

    {
      title: "Settings",
      icon: "ic_cog.svg",
      link: null,
      child: [{ title: "Vat", icon: "", link: "/vat-setting" }],
    },
  ];

  const [showSidebar, setShowSidebar] = useState(true);
  // const [defaultActiveTab, setDefaultActiveTab] = useState(null)
  const toggleSidebar = () => {
    showSidebar ? setShowSidebar(false) : setShowSidebar(true);
  };

  const userinfo = useSelector((state) => state.user.user);

  // const decoratedOnClick = useAccordionButton(eventKey);

  // useEffect(() => {
  //    if (showSidebar) {
  //       decoratedOnClick(0)
  //    } else {
  //    }
  // }, [showSidebar]);

  return (
    <div
      onMouseEnter={() => setShowSidebar(false)}
      onMouseLeave={() => {
        setShowSidebar(true);
      }}
      className={showSidebar ? "sidebar minimize" : "sidebar"}
    >
      <div className="sidebar_wrapper">
        <button className="toggleSidebar" onClick={toggleSidebar}>
          {showSidebar ? <AiOutlineArrowRight /> : <AiOutlineMenu />}
        </button>
        <div className="nav">
          <div className="sidebar_header">
            <div className="account_info">
              <div className="profile_img_sm profile_img">
                <Image
                  src={require("../public/images/android-chrome-512x512.png")}
                  alt="android-chrome-192x192.png"
                />
              </div>
              <div className="ps-3">
                <p className="mb-0 text-capitalize">
                  {userinfo?.first_name} {userinfo?.last_name}
                </p>
              </div>
            </div>
          </div>
          <Accordion as={"li"}>
            {menu.map((i, index) => {
              return (
                <Accordion.Item
                  className={i.link !== null && "noChild"}
                  eventKey={index}
                  key={index}
                >
                  <Accordion.Header>
                    {i.link !== null ? (
                      <Link className="nav-link" to={i.link}>
                        <Image
                          src={require(`../public/images/icons/${i.icon}`)}
                          height={20}
                        />
                        <div className="text">&nbsp; {i.title}</div>
                      </Link>
                    ) : (
                      <Link className="nav-link" to={"#"}>
                        <Image
                          src={require(`../public/images/icons/${i.icon}`)}
                          height={20}
                        />
                        <div className="text"> &nbsp; {i.title}</div>
                      </Link>
                    )}
                  </Accordion.Header>
                  {i.link === null && (
                    <Accordion.Body>
                      <div className=" show">
                        <ul className="nav flex-column sub-menu">
                          {i.child.map((j, index) => (
                            <li key={index} className="nav-item">
                              <Link className="nav-link" to={j.link}>
                                {j.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Accordion.Body>
                  )}
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      </div>
    </div>
  );
}

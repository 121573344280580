import React, { useState } from 'react';

let counter = 0;
function ToggleSwitch({ text, name }) {
  const id = `toggle-${counter++}`;

  const handleToggle = () => {
  };

  return (
    <div className='customSwitch mt-2 mb-3'>
      <input
        type="checkbox"
        name={name}
        id={id} // Use the unique ID here
        className="hidden-checkbox"
      />
      <label htmlFor={id} className='switch-label'>
        <span className='slider'></span>
      </label>
      &nbsp; <span>{text}</span>


      <style jsx>
        {`
         .customSwitch {
          display: flex;
          align-items: center;
          position: relative;
        }
        
        .hidden-checkbox {
          display: none;
        }
        
        .switch-label {
          cursor: pointer;
          width: 30px; /* Decreased width */
          height: 20px; /* Decreased height */
          background: grey;
          display: block;
          border-radius: 100px;
          position: relative;
        }
        
        .switch-label .slider {
          position: absolute;
          top: 3px; /* Adjusted top position */
          left: 3px; /* Adjusted left position */
          width: 14px; /* Decreased width */
          height: 14px; /* Decreased height */
          background: var(--color5);
          border-radius: 50%; /* Changed to 50% for a circular shape */
          transition: 0.3s;
        }
        
        .hidden-checkbox:checked + .switch-label .slider {
          top: 3px; /* Adjusted top position */
          left: 13px; /* Adjusted left position */
          background: white;
        }
        
        /* Styling for the "On" state */
        .hidden-checkbox:checked + .switch-label {
          background: green;
        }
        
        `}
      </style>
    </div>
  );
}

export default ToggleSwitch;

import React, { useEffect } from "react";
import { fetchOrderStatus } from "../../redux/orderStatusSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  MdAccessTime,
  MdClose,
  MdDoneAll,
  MdOutlineCheck,
  MdOutlineInbox,
  MdOutlineLocalShipping,
  MdOutlineRemove,
  MdShoppingCartCheckout,
} from "react-icons/md";

const OrderStatusTracking = ({ currentStatus, paymentMethod }) => {
  const dispatch = useDispatch();

  const orderStatus = useSelector(
    (state) => state.orderStatus?.status?.data?.data
  );

  const statuslist = [
  
    {
      title: "Rejected",
      icon: <MdAccessTime />,
    },
    {
      title: "Cancelled",
      icon: <MdAccessTime />,
    },
    {
      title: "Confirmed",
      icon: <MdOutlineCheck />,
    },
    {
      title: "Out for delivery",
      icon: <MdAccessTime />,
    },
    {
      title: "Shipped",
      icon: <MdOutlineLocalShipping />,
    },
    {
      title: "Delivered",
      icon: <MdDoneAll />,
    }
  ];

  useEffect(() => {
    dispatch(fetchOrderStatus({ type: "manager" }));
  }, [dispatch]);

  // Filter out "Cancelled" and "Rejected" statuses by default
  let filteredStatusList = statuslist.filter(
    (status) => status.title !== "Cancelled" && status.title !== "Rejected"
  );

  let displayedStatusList;

  // Conditionally change the order based on payment method
  if (paymentMethod === "Bank / QR") {
    displayedStatusList = [
      {
        title: "Pending",
        icon: <MdAccessTime />,
      },
      {
        title: "Ordered",
        icon: <MdShoppingCartCheckout />,
      },
      ...filteredStatusList,
    ];
  } else {
    displayedStatusList = [
      {
        title: "Ordered",
        icon: <MdShoppingCartCheckout />,
      },
      {
        title: "Pending",
        icon: <MdAccessTime />,
      },
      ...filteredStatusList,
    ];  }

  // Adjust order based on currentStatus
  if (currentStatus === "Rejected") {
    displayedStatusList = displayedStatusList.filter(
      (status) =>
        status.title === "Ordered" ||
        status.title === "Pending" ||
        status.title === "Rejected"
    );
  } else if (currentStatus === "Cancelled") {
    displayedStatusList = displayedStatusList.filter(
      (status) =>
        status.title === "Ordered" ||
        status.title === "Pending" ||
        status.title === "Cancelled"
    );
  } else {
    // Default order for other statuses
    displayedStatusList = displayedStatusList;
  }

  return (
    <div>
      <ul className="statusTracking">
        {displayedStatusList.map((status, index) => (
          <li
            key={index}
            className={status.title === currentStatus ? "current-status" : ""}
          >
            <span className="icon">{status?.icon}</span>
            <span>{status.title}</span>
          </li>
        ))}
        {currentStatus==="Rejected"&&<li className={"current-status"}>
          <span className="icon bg-danger">
            <MdClose />
          </span>
          <span>Rejected</span>
        </li>}

        {currentStatus==="Cancelled"&&<li className={"current-status"}>
          <span className="icon bg-danger">
            <MdClose />
          </span>
          <span>Cancelled</span>
        </li>}
      </ul>
    </div>
  );
};

export default OrderStatusTracking;

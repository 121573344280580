import React, { useEffect, useState } from "react";
import Notification, { notify } from "../../../Components/Notification";
import { Button, Col, Row, Form } from "react-bootstrap";
import Label from "../../../Components/Label";
import { useFormik } from "formik";
import * as Yup from "yup";
import Service from "../../../http";
import CustomInput from "../../../Components/CustomInput";
import { useSelector } from "react-redux";
import { BiLoader } from "react-icons/bi";

export default function AddBanner({ onFormSubmitSuccess }) {
  const services = new Service();
  const currency = useSelector((state) => state.currency)?.currencylist?.data;
  const [isLoading, setIsLoading] = useState(false);

  const NewProductSizeSchema = Yup.object().shape({
    desktop_image: Yup.mixed().required("Slider image is required"),
    mobile_image: Yup.mixed().required("Mobile image is required"),
    heading: Yup.string().required("Teading is required"),
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    position: Yup.string().required("Position is required"),
    link: Yup.string().required("Link is required"),
  });

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      desktop_image: "",
      mobile_image: "",
      title: "",
      heading: "",
      description: "",
      position: "top",
      link: "",
    },
    onSubmit: (values, { resetForm }) => {},
    validationSchema: NewProductSizeSchema,
  });

  const submitForm = () => {
    let formData = new FormData();
    for (const key in formik.values) {
      if (Object.hasOwnProperty.call(formik.values, key)) {
        formData.append(key, formik.values[key]);
      }
    }

    services &&
      services.post("slider/banner-add", formData).then((res) => {
        setIsLoading(true);
        if (res.status) {
          notify(res.message);
          onFormSubmitSuccess();
          setIsLoading(false);
        } else {
          notify(res.message[0]?.message[0]?.message, { variant: "error" });
        }
      });
  };

  console.log(formik.values);

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Notification />
        <Row>
          <Col md={12} className="mb-3">
            <Label aria-required>Banner Desktop Image</Label>
            <CustomInput
              type="file"
              name="desktop_image"
              placeholder="Banner Desktop Image"
              className="form-control"
              onChange={(event) => {
                formik.setFieldValue(
                  "desktop_image",
                  event.currentTarget.files[0]
                );
              }}
              onBlur={formik.handleBlur}
            />

            {formik.touched.desktop_image && formik.errors.desktop_image ? (
              <div className="error">{formik.errors.desktop_image}</div>
            ) : null}
          </Col>

          <Col md={12} className="mb-3">
            <Label aria-required>Banner Mobile Image</Label>
            <CustomInput
              type="file"
              name="mobile_image"
              placeholder="Banner Mobile Image"
              className="form-control"
              onChange={(event) => {
                formik.setFieldValue(
                  "mobile_image",
                  event.currentTarget.files[0]
                );
              }}
              onBlur={formik.handleBlur}
            />

            {formik.touched.mobile_image && formik.errors.mobile_image ? (
              <div className="error">{formik.errors.mobile_image}</div>
            ) : null}
          </Col>

          <Col md={12} className="mb-3">
            <Label aria-required>Title</Label>
            <CustomInput
              type="text"
              name="title"
              placeholder="Title"
              className="form-control"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.title && formik.errors.title ? (
              <div className="error">{formik.errors.title}</div>
            ) : null}
          </Col>

          <Col md={12} className="mb-3">
            <Label aria-required>Heading</Label>
            <CustomInput
              type="text"
              name="heading"
              placeholder="Heading"
              className="form-control"
              value={formik.values.heading}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.heading && formik.errors.heading ? (
              <div className="error">{formik.errors.heading}</div>
            ) : null}
          </Col>

          <Col md={12} className="mb-3">
            <Label aria-required>Description</Label>
            <CustomInput
              type="text"
              name="description"
              placeholder="Description"
              className="form-control"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.description && formik.errors.description ? (
              <div className="error">{formik.errors.description}</div>
            ) : null}
          </Col>
          <Col md={12} className="mb-3">
            <Label aria-required>Position</Label>
            <select
              className="form-control"
              name="position"
              value={formik.values.position}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value={"top"}>Top</option>
              <option value={"bottom"}>Bottom</option>
              <option value={"middle"}>Middle</option>
              <option value={"footer"}>Footer</option>
            </select>
          </Col>
          <Col md={12} className="mb-3">
            <Label aria-required>Link</Label>
            <CustomInput
              type="text"
              name="link"
              placeholder="Link"
              className="form-control"
              value={formik.values.link}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.link && formik.errors.link ? (
              <div className="error">{formik.errors.link}</div>
            ) : null}
          </Col>

          <Col md={12} className="mb-3">
            <Button
              // type="submit"
              size="lg"
              onClick={() => submitForm()}
              className="px-md-5 w-100"
              variant="primary"
            >
              {isLoading ? <BiLoader className="loader" /> : "Submit"}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

import React, { useEffect, useState } from 'react'
import Pagelayout from '../../Layouts/Pagelayout'
import { Badge, Button, Card, Dropdown, Modal } from 'react-bootstrap'
import Notification, { notify } from '../../Components/Notification'
import LTable from '../../Components/LTable'
import { BiCategory, BiDotsVerticalRounded } from 'react-icons/bi'
import Service from '../../http'
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md'
import { AiFillDelete, AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'
import { formatDate, tableSerialNumber } from '../../Components/Utils'
import AddCategory from './AddCategory'
import AddSubCategory from './AddSubCategory'

import EditCategory from './EditCategory'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCategories } from '../../redux/categorySlice'
import Swal from 'sweetalert2';

export default function CategoryList() {

   const services = new Service();
   const dispatch = useDispatch()
   const [searchQuery, setSearchQuery] = useState('')
   const [page, setPage] = useState(1)
   const [categoryId, setCategoryId] = useState()

   const categories = useSelector((state) => state.category.categories.data);
   useEffect(() => {
      // Dispatch the fetchCategories async thunk when the component mounts
      dispatch(fetchCategories());
   }, [dispatch]);


   const [CategoryAddForm, setShowCategoryAddForm] = useState(false);
   const handleCloseCategoryAddForm = () => setShowCategoryAddForm(false);
   const handleShowCategoryAddForm = () => setShowCategoryAddForm(true);

   const [CategoryEditForm, setShowCategoryEditForm] = useState(false);
   const handleCloseCategoryEditForm = () => setShowCategoryEditForm(false);
   const handleShowCategoryEditForm = (id) => {
      setCategoryId(id)
      setShowCategoryEditForm(true)
   };

   const [SubCategoryAddForm, setShowSubCategoryAddForm] = useState(false);
   const handleCloseSubCategoryAddForm = () => setShowSubCategoryAddForm(false);
   const handleShowSubCategoryAddForm = () => setShowSubCategoryAddForm(true);

   const handleStatusChange = (categoryId, status) => {
      services &&
         services.put("category/status/" + categoryId, {
            status: status === 1 ? 0 : 1
         })
            .then((res) => {
               if (res.status) {
                  notify(res.message);
                  dispatch(fetchCategories());
               } else {
                  notify(res.message[0]?.message);
               }
            })
            .catch((error) => {
               console.error('Error - ', error);
            });
   };

   const [isAlertVisible, setAlertVisible] = useState(false);
   const [alertOptions, setAlertOptions] = useState({});

   const showSweetAlert = (options) => {
      setAlertOptions(options);
      setAlertVisible(true);
   };

   const handleConfirm = () => {
      if (alertOptions.onConfirm) {
         alertOptions.onConfirm();
      }
      setAlertVisible(false);
   };

   const handleCancel = () => {
      setAlertVisible(false);
   };

   const handleDeleteCategory = (categoryId) => {
      Swal.fire({
        title: 'Delete Category',
        text: 'Are you sure you want to delete this category?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          // Call your delete category function here
          services
            .put('category/delete/' + categoryId)
            .then((res) => {
              if (res.status) {
                notify(res.message);
                dispatch(fetchCategories());
              } else {
                notify(res.message[0]?.message);
              }
            })
            .catch((error) => {
              console.error('Error - ', error);
            });
        }
      });
    };

    const handleDeleteSubCategory = (categoryId) => {
      Swal.fire({
        title: 'Delete Sub-Category',
        text: 'Are you sure you want to delete this sub-category?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          // Call your delete sub-category function here
          services
            .put('category/delete/' + categoryId)
            .then((res) => {
              if (res.status) {
                notify(res.message);
                dispatch(fetchCategories());
              } else {
                notify(res.message[0]?.message);
              }
            })
            .catch((error) => {
              console.error('Error - ', error);
            });
        }
      });
    };

   return (
      <Pagelayout
         title="Categories"
         breadcrumbs={
            [
               { title: 'Categories', link: '/categories' },
               { title: 'List', link: '/categories/list' },
            ]
         }
         actions={<>
            <Button onClick={handleShowCategoryAddForm}><AiOutlinePlus /> Add Categories</Button> &nbsp;
            <Button onClick={handleShowSubCategoryAddForm}><AiOutlinePlus /> Add SubCategory</Button>
         </>}>

         
         <Notification />
         <Card>
            <Card.Body>
               <LTable
                  isLoading={categories&&categories.length === 0}
               >
                  <thead>
                     <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Sub Category</th>
                        <th>Slug</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Action</th>
                     </tr>
                  </thead>
                  <tbody>
                     {categories && categories.map((i, index) =>
                        <tr key={index}>
                           <td>{
                              tableSerialNumber(index, categories?.cur_page, categories.length, 20)
                           }</td>
                           <td><BiCategory className='h5 m-0' /> &nbsp; {i.name}</td>
                           <td>
                              <div className='d-flex flex-wrap' style={{ maxWidth: 450 }}>
                                 {i.sub_category.map((j, key) => <><Badge className='mb-1 d-inline-flex align-items-center' key={key}>{j.name} &nbsp; <span onClick={() => handleDeleteSubCategory(j.id)} className='h6 m-0 d-inline-block '><AiFillDelete /></span></Badge>&nbsp; </>)}
                              </div>
                           </td>
                           <td>{i.slug}</td>
                           <td>{formatDate(i.updated_at)}</td>
                           <td>
                              <label
                                 onClick={() => handleStatusChange(i.id, i.status)}
                                 className={i.status === 1 ? 'custom_check active' : 'custom_check'}
                              >
                                 <div className='custom_check_wrapper'>
                                    <span></span>
                                    <label>
                                       {i.status === 1 ? 'Active' : 'Deactivated'}
                                    </label>
                                 </div>
                              </label>
                           </td>
                           <td>
                              <Dropdown>
                                 <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <BiDotsVerticalRounded />
                                 </Dropdown.Toggle>
                                 <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleShowCategoryEditForm(i.id)}><MdEdit /> Edit</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleDeleteCategory(i.id)} href="#/action-2"><MdDelete /> Delete</Dropdown.Item>
                                 </Dropdown.Menu>
                              </Dropdown>
                           </td>
                        </tr>
                     )}
                  </tbody>
               </LTable>
               {/* <Pagination pagecount={coupons.total_pages} currentpage={page} onPageChange={handlePageChange} /> */}
            </Card.Body>
         </Card>

         <Modal show={CategoryAddForm} onHide={handleCloseCategoryAddForm}>
            <Modal.Header closeButton>
               <Modal.Title>Add Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <AddCategory onSuccess={() => setShowCategoryAddForm(false)} />
            </Modal.Body>
         </Modal>

         <Modal show={CategoryEditForm} onHide={handleCloseCategoryEditForm}>
            <Modal.Header closeButton>
               <Modal.Title>Edit Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <EditCategory category_id={categoryId} onSuccess={() => setShowCategoryEditForm(false)} />
            </Modal.Body>
         </Modal>

         <Modal show={SubCategoryAddForm} onHide={handleCloseSubCategoryAddForm}>
            <Modal.Header closeButton>
               <Modal.Title>Add Sub Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <AddSubCategory onSuccess={() => setShowSubCategoryAddForm(false)} />
            </Modal.Body>
         </Modal>
      </Pagelayout>
   )
}

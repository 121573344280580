import React, { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CommonEditor = ({ onChange, value }) => {
  const [editorData, setEditorData] = useState(value || '');

  // Update the local state when the 'value' prop changes
  useEffect(() => {
    setEditorData(value || '');
  }, [value]);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
    onChange(data);
  };

  return (
    <CKEditor
      editor={ClassicEditor}
      data={editorData}
      onChange={handleEditorChange}
    />
  );
};

export default CommonEditor;

import React from 'react'
import { Link } from 'react-router-dom'

export default function PageHeading({ title, breadcrumbs, actions }) {
   return (
      <div className='pageHeading mb-2'>
         <div className='flex-grow-1 pe-3'>
            {title&&<div className='pageHeading_title'>{title}</div>}
            {breadcrumbs&&<div className='breadcrumb'>
               {breadcrumbs.map((i, index) => <div key={index} className='breadcrumb-item'><span className='dot'></span><Link to={i.link}>{i.title}</Link></div>)}
            </div>}
         </div>
         <div className='actions' >{actions}</div>
      </div>
   )
}

import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import Label from "../../Components/Label";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../Components/CustomInput";
import { BiLoader } from "react-icons/bi";
import CustomSelect from "../../Components/CustomSelect";
import CustomDatePicker from "../../Components/CustomDatePicker";
import doAPI from "../../api";
import Notification, { notify } from "./../../Components/Notification";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { fetchCitiesByState, fetchCountries, fetchStatesByCountry } from "../../redux/LocationSlice";
import { useDispatch, useSelector } from "react-redux";

export default function EditProfile({ onFormSubmitSuccess, userData }) {
  const dispatch = useDispatch()
  // const services = new Service();
  // const currency = useSelector((state) => state.currency)?.currencylist?.data;
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);
  const [countryCode, setCountryCode] = useState();
  const [allCountries, setAllCountries] = useState([]);

  const countries = useSelector((state) =>
    state.location.countries.map((country) => ({
      value: country.id,
      label: country.name,
    }))
  );
  const states = useSelector((state) =>
    state.location.states.map((state) => ({
      value: state.id,
      label: state.name,
    }))
  );
  const cities = useSelector((state) =>
    state.location.cities.map((city) => ({
      value: city.id,
      label: city.name,
    }))
  );

  const NewProductSizeSchema = Yup.object().shape({
    first_name: Yup.mixed().required("First name is required"),
    family_name: Yup.mixed().required("Family name is required"),
    email: Yup.mixed().required("Email is required"),
    gender: Yup.mixed().required("Gender is required"),
    dob: Yup.mixed().required("Dob is required"),
    mobile: Yup.mixed().required("Mobile is required"),
    address: Yup.mixed().required("Address is required"),
    country_id: Yup.mixed().required("Country is required"),
    state_id: Yup.mixed().required("State is required"),
    city_id: Yup.mixed().required("City is required"),
  });

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      role_id: null,
      first_name: "",
      family_name: "",
      email: "",
      gender: "",
      dob: "",
      mobile: "",
      address: "",
      country_id: 0,
      state_id: 0,
      city_id: 0,
    },
    onSubmit: (values, { resetForm }) => {
      
    },
    validationSchema: NewProductSizeSchema,
  });

  const submitForm = () => {
    let formData = new FormData();
      for (const key in formik.values) {
        if (Object.hasOwnProperty.call(formik.values, key)) {
          formData.append(key, formik.values[key]);
        }
      }

      doAPI.putData("user/update-profile", formData).then((res) => {
        setIsLoading(true);
        if (res.status) {
          notify(res?.data?.message);
          onFormSubmitSuccess();
          setIsLoading(false);
        } else {
          notify(res.message[0]?.message[0]?.message, { variant: "error" });
        }
      });
  }

  useEffect(() => {
    formik.setValues({
      first_name: userData?.first_name,
      family_name: userData?.family_name,
      email: userData?.email,
      gender: userData?.gender,
      dob: userData?.dob,
      mobile: userData?.mobile,
      address: userData?.address,
      country_id: userData?.country_id,
      state_id: userData?.state_id,
      city_id: userData?.city_id,
    });
  }, [userData]);

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  // useEffect to fetch states based on selected country
  useEffect(() => {
    if (formik.values.country_id !== null) {
      dispatch(fetchStatesByCountry(formik.values.country_id));
    }
  }, [dispatch, formik.values.country_id]);

  // useEffect to fetch cities based on selected state
  useEffect(() => {
    if (formik.values.state_id !== null) {
      dispatch(fetchCitiesByState(formik.values.state_id));
    }
  }, [dispatch, formik.values.state_id]);

  // useEffect(() => {
  //   onFormSubmit(formik.values);
  // }, [formik.values]);

  const getCountrycode = () => {
    const phonecode = formik.values?.mobile&&formik.values?.mobile.replace('+','').split(' ')[0]
    formik.setFieldValue('country_id',allCountries.find((i) => i.phone_code === phonecode)?.id)
  };
  useEffect(() => {
    getCountrycode();
  }, [formik.values.mobile]);

  // console.log(formik.values)
  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Notification />
        <Row>
          <Col md={6} className="mb-2">
            <Label aria-required>Frist Name</Label>
            <CustomInput
              type="text"
              name="first_name"
              placeholder="First Name"
              className="form-control"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.first_name && formik.errors.first_name ? (
              <div className="error">{formik.errors.first_name}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-2">
            <Label aria-required>Family Name</Label>
            <CustomInput
              type="text"
              name="family_name"
              placeholder="Last Name"
              className="form-control"
              value={formik.values.family_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.family_name && formik.errors.family_name ? (
              <div className="error">{formik.errors.family_name}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-2">
            <Label aria-required>Gender</Label>
            <select
              className="form-control"
              name="gender"
              value={formik.values.gender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value={null}>Select Gender</option>
              <option value={"M"}>Male</option>
              <option value={"F"}>Female</option>
            </select>
            {formik.touched.gender && formik.errors.gender ? (
              <div className="error">{formik.errors.gender}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-2">
            <Label aria-required>Email</Label>
            <CustomInput
              type="email"
              name="email"
              placeholder="Email"
              className="form-control"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-2">
            <Label aria-required>Mobile</Label>
            <PhoneInput
              countryCodeEditable={false}
              country={(countryCode ? countryCode : "TH").toLowerCase()}
              value={formik.values.mobile}
              onChange={(phone, country, e, formattedValue) => {
                formik.setFieldValue("mobile", formattedValue);
              }}
              onBlur={formik.handleBlur}
            />

            {formik.touched.mobile && formik.errors.mobile ? (
              <div className="error">{formik.errors.mobile}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-3">
            <Label aria-required>Date of birth</Label>
            <CustomDatePicker
              name="dob"
              className="form-control"
              value={formik.values.dob}
              onChange={(value) => formik.setFieldValue("dob", value)}
              onBlur={formik.handleBlur}
            />
            {formik.touched.dob && formik.errors.dob ? (
              <div className="error">{formik.errors.dob}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-3">
            <Label aria-required>Select Country</Label>
            <CustomSelect
              className="w-100"
              value={formik.values.country_id}
              onBlur={formik.handleBlur} // pass onBlur handler from Formik form
              form={formik} // pass the formik instance to CustomSelect
              onChange={(value) => formik.setFieldValue("country_id", value.value)}
              options={countries}
            />
            {formik.touched.country_id && formik.errors.country_id ? (
              <div className="error">{formik.errors.country_id}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-3">
            <Label aria-required>Select State</Label>
            <CustomSelect
              className="w-100"
              value={formik.values.state_id}
              onBlur={formik.handleBlur} // pass onBlur handler from Formik form
              form={formik} // pass the formik instance to CustomSelect
              onChange={(value) => formik.setFieldValue("state_id", value.value)}
              options={states}
            />
            {formik.touched.state_id && formik.errors.state_id ? (
              <div className="error">{formik.errors.state_id}</div>
            ) : null}
          </Col>

          <Col md={6} className="mb-3">
            <Label>Select City</Label>
            <CustomSelect
              className="w-100"
              value={formik.values.city_id}
              onBlur={formik.handleBlur} // pass onBlur handler from Formik form
              form={formik} // pass the formik instance to CustomSelect
              onChange={(value) => formik.setFieldValue("city_id", value.value)}
              options={cities}
            />
          </Col>

          <Col md={6} className="mb-2">
            <Label aria-required>Address</Label>
            <textarea
              type="text"
              name="address"
              placeholder="Address"
              className="form-control"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></textarea>
            {formik.touched.address && formik.errors.address ? (
              <div className="error">{formik.errors.address}</div>
            ) : null}
          </Col>

          <Col md={12} className="my-2 text-end">
            <Button
              // type="submit"
              onClick={()=>submitForm()}
              size="lg"
              className="px-md-5"
              variant="primary"
            >
              {isLoading ? <BiLoader className="loader" /> : "Submit"}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

// Import necessary libraries
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import doAPI from '../api';

// Async thunk for fetching user details
export const fetchAddressList = createAsyncThunk('user/fetchAddressList', async () => {
  try {
    // Make a request to the API endpoint for user details
    const response = await doAPI.getData('user/address');
    return response.data; // Assuming the response has the user details
  } catch (error) {
    throw error; // Propagate the error if the request fails
  }
});

// Initial state
const initialState = {
  userDetails: {}, // Assuming user details will be stored as an object
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

// Create a slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddressList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAddressList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userDetails = action.payload;
      })
      .addCase(fetchAddressList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default userSlice.reducer;

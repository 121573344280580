import React from 'react'
import Pagelayout from '../../../Layouts/Pagelayout'
import { Badge, Card, Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import LTable from '../../../Components/LTable'
import CustomDatePicker from '../../../Components/CustomDatePicker'
import CustomSelect from '../../../Components/CustomSelect'

export default function StackAdjustmentDetail() {
   const { id } = useParams()
   return (
      <Pagelayout
         title="Inventory"
         breadcrumbs={
            [
               { title: 'Inventory', link: '/inventory' },
               { title: 'Stock Adjustment', link: '/inventory/stock-adjustment/' },
               { title: `Stock Adjustment ${id}`, link: `/inventory/stock-adjustment/${id}` },
            ]
         }>
         <Card className='mb-3'>
            <Card.Body>

               <Row>
                  <div className='d-flex align-items-center mb-3'>
                     <div className='flex-grow-1 d-flex align-items-center'>
                        <h5>Inventory count no. - </h5><h4> 1371 </h4>
                     </div>
                     <Badge bg="warning">In Progress</Badge>
                  </div>


                  <Col md={4} className='mb-3'>
                     <div className='border rounded p-3'>
                        <table className='w-100'>
                           <tbody>

                              <tr>
                                 <td>Store</td>
                                 <td align='right'>Marina Store</td>
                              </tr>
                              <tr>
                                 <td>Store State Time</td>
                                 <td align='right'>12, No. 2023, 14:02</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </Col>
                  <Col md={4} className='mb-3'>
                     <div className='border rounded p-3'>
                        <table className='w-100'>
                           <tbody>

                              <tr>
                                 <td>Difference in quantity</td>
                                 <td align='right'>0.000</td>
                              </tr>
                              <tr>
                                 <td>Price Difference</td>
                                 <td align='right'>0.00</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </Col>
                  <Col md={4} className='mb-3'>
                     <div className='border rounded p-3'>
                        <table className='w-100'>
                           <tbody>
                              <tr>
                                 <td>Created By <b>Lorem Ipsum</b> on 12, No. 2023, 14:02</td>
                              </tr>

                              <tr>
                                 <td>Accepted By <b>Lorem Ipsum</b> on 12, No. 2023, 14:02</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </Col>
                  <Col md={4} className='mb-3'>
                     <input type='text' className='form-control' placeholder='Comment' />
                  </Col>
               </Row>
            </Card.Body>
         </Card>
         <Card>
            <Card.Body>
               <div className='table_filter equal_space'>
                  <div className='table_filter_item'>
                     <input className='form-control' placeholder='search' type='search' />
                  </div>
                  <div className='table_filter_item ms-md-auto'>
                     <CustomSelect
                        placeholder="Select Product Group"
                        //   value={formik.values.country}
                        onChange={(value) =>
                           console.log(value)
                        }
                        options={[{ label: 'Boys', value: 2 }, { label: 'Girls', value: 2 }, { label: 'Kids', value: 3 }, { label: 'Equipments', value: 4 }]}
                     />
                  </div>
                  <div className='table_filter_item ms-md-auto'>
                     <CustomSelect
                        placeholder="Select Quantity"
                        //   value={formik.values.country}
                        onChange={(value) =>
                           console.log(value)
                        }
                        options={[{ label: 'Greater Than 0', value: 2 }, { label: 'Equal to 0', value: 2 }, { label: 'Less than 0', value: 3 }]}
                     />
                  </div>
               </div>

               <LTable>
                  <thead>
                     <tr>
                        <th>Product Name</th>
                        <th>Product Number</th>
                        <th>Barcode</th>
                        <th>Counted Quantity</th>
                        <th>Amount in Store</th>
                        <th>Difference in Quantity</th>
                        <th>Comment</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td>102390 Babolat Aero G tennis racquet | Grip 3</td>
                        <td>9665</td>
                        <td>65656586326352</td>
                        <td><input type="text" placeholder='Quantity' className='form-control' value={3} /></td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td><input type="text" placeholder='Comment' className='form-control' value={'Some Comment'} /></td>
                     </tr>
                     <tr>
                        <td>102390 Babolat Aero G tennis racquet | Grip 3</td>
                        <td>9665</td>
                        <td>65656586326352</td>
                        <td><input type="text" placeholder='Quantity' className='form-control' value={3} /></td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td><input type="text" placeholder='Comment' className='form-control' value={'Some Comment'} /></td>
                     </tr>
                     <tr>
                        <td>102390 Babolat Aero G tennis racquet | Grip 3</td>
                        <td>9665</td>
                        <td>65656586326352</td>
                        <td><input type="text" placeholder='Quantity' className='form-control' value={3} /></td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td><input type="text" placeholder='Comment' className='form-control' value={'Some Comment'} /></td>
                     </tr>
                     <tr>
                        <td>102390 Babolat Aero G tennis racquet | Grip 3</td>
                        <td>9665</td>
                        <td>65656586326352</td>
                        <td><input type="text" placeholder='Quantity' className='form-control' value={3} /></td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td><input type="text" placeholder='Comment' className='form-control' value={'Some Comment'} /></td>
                     </tr>
                     <tr>
                        <td>102390 Babolat Aero G tennis racquet | Grip 3</td>
                        <td>9665</td>
                        <td>65656586326352</td>
                        <td><input type="text" placeholder='Quantity' className='form-control' value={3} /></td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td><input type="text" placeholder='Comment' className='form-control' value={'Some Comment'} /></td>
                     </tr>
                     <tr>
                        <td>102390 Babolat Aero G tennis racquet | Grip 3</td>
                        <td>9665</td>
                        <td>65656586326352</td>
                        <td><input type="text" placeholder='Quantity' className='form-control' value={3} /></td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td><input type="text" placeholder='Comment' className='form-control' value={'Some Comment'} /></td>
                     </tr>
                     <tr>
                        <td>102390 Babolat Aero G tennis racquet | Grip 3</td>
                        <td>9665</td>
                        <td>65656586326352</td>
                        <td><input type="text" placeholder='Quantity' className='form-control' value={3} /></td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td><input type="text" placeholder='Comment' className='form-control' value={'Some Comment'} /></td>
                     </tr>
                     <tr>
                        <td>102390 Babolat Aero G tennis racquet | Grip 3</td>
                        <td>9665</td>
                        <td>65656586326352</td>
                        <td><input type="text" placeholder='Quantity' className='form-control' value={3} /></td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td><input type="text" placeholder='Comment' className='form-control' value={'Some Comment'} /></td>
                     </tr>

                  </tbody>
               </LTable>
            </Card.Body>
         </Card>
      </Pagelayout >
   )
}

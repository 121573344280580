import React, { useEffect, useState } from "react";
import Pagelayout from "../../Layouts/Pagelayout";
import { Button, Card, Dropdown, Modal } from "react-bootstrap";
import Service from "../../http";
import LTable from "../../Components/LTable";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { formatDate, tableSerialNumber } from "../../Components/Utils";
import Notification, { notify } from "../../Components/Notification";
import { Link } from "react-router-dom";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import EditSize from "./Size/EditSize";
import AddSize from "./Size/AddSize";
import { fetchSizeNames } from "../../redux/sizeSlice";

export default function ProductSizes() {
  const dispatch = useDispatch();
  const services = new Service();
  const [sizeData, setSizeData] = useState();

  const [showSizeAddForm, setShowSizeAddForm] = useState(false);
  const handleCloseSizeAddForm = () => setShowSizeAddForm(false);
  const handleShowSizeAddForm = () => setShowSizeAddForm(true);

  const [showSizeEditForm, setShowSizeEditForm] = useState(false);
  const handleCloseSizeEditForm = () => setShowSizeEditForm(false);
  const handleShowSizeEditForm = (data) => {
    setSizeData(data);
    setShowSizeEditForm(true);
  };

  const sizeList = useSelector((state) => state.sizes?.sizeNames);

  const fetchEntry = () => {
    dispatch(fetchSizeNames());
  };

  const formSubmit = () => {
    fetchEntry();
    handleCloseSizeEditForm();
    handleCloseSizeAddForm();
  };

  useEffect(() => {
    fetchEntry();
  }, []);

  const deleteEntry = (id) => {
    services &&
      services
        .put("product/delete/" + id)
        .then((res) => {
          if (res.status) {
            notify(res.message);
            fetchEntry();
          } else {
            notify(res.message[0]?.message);
          }
        })
        .catch((error) => {
          console.error("Error - ", error);
        });
  };

  const handleStatusChange = (id, isActive) => {
    services &&
      services
        .put("size/size-name/status/", {
          is_active: isActive === 1 ? 0 : 1,
          size_name_id: id,
        })
        .then((res) => {
          if (res.status) {
            notify(res.message);
            fetchEntry();
          } else {
            notify(res.message[0]?.message);
          }
        })
        .catch((error) => {
          console.error("Error - ", error);
        });
  };

  return (
    <Pagelayout
      title="Size List"
      breadcrumbs={[
        { title: "Dashboard", link: "/dashboard" },
        { title: "Products", link: "/products" },
        { title: "Size", link: "#" },
      ]}
      actions={<Button onClick={handleShowSizeAddForm}>Add Size</Button>}
    >
      <Notification />
      <Card className="overflow-visible">
        <Card.Body>
          <LTable>
            <thead>
              <tr>
                <th width={50}>#</th>
                <th>Category</th>
                <th>Name</th>
                <th>Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {sizeList &&
                sizeList.map((i, index) => (
                  <tr key={index}>
                    <td>
                      {tableSerialNumber(
                        index,
                        sizeList?.cur_page,
                        sizeList.length,
                        20
                      )}
                    </td>
                    <td>{i.category?.name}</td>
                    <td>{i.name}</td>
                    <td>{formatDate(i.updated)}</td>
                    <td>
                      <label
                        onClick={() => handleStatusChange(i.id, i.is_active)}
                        className={
                          i.is_active === 1
                            ? "custom_check active"
                            : "custom_check"
                        }
                      >
                        <div className="custom_check_wrapper">
                          <span></span>
                          <label>
                            {i.is_active === 1 ? "Active" : "Deactivated"}
                          </label>
                        </div>
                      </label>
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <BiDotsVerticalRounded />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            onClick={() => deleteEntry(i.id)}
                            to="#"
                          >
                            <MdDelete /> Delete
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Link}
                            onClick={() => handleShowSizeEditForm(i)}
                            to="#"
                          >
                            <MdEdit /> Edit
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
            </tbody>
          </LTable>
        </Card.Body>
      </Card>

      <Modal show={showSizeAddForm} onHide={handleCloseSizeAddForm}>
        <Modal.Header closeButton>
          <Modal.Title>Add Size</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddSize formSubmit={() => formSubmit()} />
        </Modal.Body>
      </Modal>

      <Modal show={showSizeEditForm} onHide={handleCloseSizeEditForm}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Size</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditSize formSubmit={() => formSubmit()} sizeData={sizeData} />
        </Modal.Body>
      </Modal>
    </Pagelayout>
  );
}

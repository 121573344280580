import React, { useState, useEffect } from 'react';
import Pagelayout from '../../Layouts/Pagelayout';
import { Button, Card, Col, Row, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Label from '../../Components/Label';
import Image from '../../Components/Image';
import { Link, useNavigate } from 'react-router-dom';
import Currency from '../../Components/Currency';
import CustomInput from '../../Components/CustomInput';
import { clearCart } from '../../redux/AddToCart';
import Notification, { notify } from '../../Components/Notification';
import { AiOutlineSend } from 'react-icons/ai';
import Receipt from './Receipt';
import { FaCartPlus, FaFileInvoice, FaMapMarkerAlt } from 'react-icons/fa';
import { amount, logoutApp } from '../../Components/Utils';
import { BiLoader } from 'react-icons/bi';
import CustomSelect from './../../Components/CustomSelect';
import { fetchUsers } from '../../redux/usersSlice';
import doAPI from './../../api';
import NoAddressFound from '../../Components/NoAddressFound';
import CityStateCountryFetcher from './../../Components/CityStateCountryFetcher';
import AddressModal from './AddressModal';

export default function Checkout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modalShow, setModalShow] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    shipping_address: 'Please select a shipping address',
    billing_address: 'Please select a billing address',
  });

  const handleCloseInvoice = () => setShowInvoice(false);
  const handleShowInvoice = () => setShowInvoice(true);
  const errorFreeState = error.shipping_address === '' && error.billing_address === '';

  const [paymentList, setPaymentList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(20);
  const cartItems = useSelector((state) => state.cart.items);
  const shipping = useSelector((state) => state.cart.shipping);
  const cartSubTotal = useSelector((state) => state.cart.subTotal);

  const discount = useSelector((state) => state.cart?.couponResponse?.data?.discount) || 0;
  const couponCode = useSelector((state) => state.cart?.couponResponse?.data?.coupon_code) || null;
  const couponId = useSelector((state) => state.cart?.couponResponse?.data?.coupon_id || 0);
  const selectedCurrency = useSelector((state) => state.property.selectedCurrency)?.id;
  const cart = useSelector((state) => state.cart);

  const cartTotal = parseFloat(
    cart?.shipping +
      cart?.subTotal +
      (parseFloat(cart.totalVatCustom) > 0 ? parseFloat(cart.totalVatCustom) : parseFloat(cart.totalVat)) -
      (cart?.couponResponse?.data?.discount || 0)
  );

  const userList = useSelector((state) => state.userList)?.userList?.data?.rows ?? [];

  const userListState = [];

  userList.map((i) =>
    userListState.push({
      label: i.first_name + ' ' + i.family_name,
      value: i.id,
    })
  );

  useEffect(() => {
    dispatch(fetchUsers({ role_id: 3 }));
  }, [dispatch]);

  const fetchEntry = () => doAPI.getData(`payment/method-list`).then((res) => setPaymentList(res?.data));

  useEffect(() => {
    fetchEntry();
  }, []);

  useEffect(() => {
    if (selectedUser !== null) {
      doAPI.getData(`user/address/${selectedUser}`).then((res) => {
        setAddressList(res?.data);
      });
    }
  }, [selectedUser]);

  const [orderDetail, setOrderDetail] = useState({
    order_id: 0,
    tran_ref: null,
    payment_result: null,
    order_items: {},
    shipping_address_id: 0,
    shipping_address: null, // Initialize with null or an empty object
    billing_address_id: 0,
    billing_address: null, // Initialize with null or an empty object
    payment_method: 'Cash',
    payment_id: 1,
    shipping_amount: shipping,
    vat_amount: parseInt(cart.totalVatCustom) > 0 ? parseInt(cart.totalVatCustom) : parseInt(cart.totalVat),
    raw_amount: cartSubTotal,
    total_amount: cartTotal,
    coupon_amount: discount,
    coupon_id: couponId,
    coupon_code: couponCode || null,
    order_status_id: 5,
    order_notes: 'Order Notes',
    currency_id: selectedCurrency,
    user_id: selectedUser,
  });

  useEffect(() => {
    const newOrderItems = cartItems.map((i) => ({
      id: i.id,
      sizes: i.sizes,
      size_id: i.size_id,
      quantity: i.quantity,
      price_type: i.price_type,
    }));
    setOrderDetail((prevOrderDetail) => ({
      ...prevOrderDetail,
      order_items: newOrderItems,
    }));
  }, [cartItems]);

  const addAddressFromList = (address) => {
    if (address.address_type === 'B') {
      setOrderDetail((prevOrderDetail) => ({
        ...prevOrderDetail,
        billing_address_id: address?.id,
        billing_address: {
          first_name: address?.first_name,
          last_name: address?.last_name,
          address_line_1: address?.address_line_1,
          phone_number: address?.phone_number,
          email: address?.email,
          country: address?.country_id,
          state: address?.state_id,
          city: address?.city_id,
          zip_code: address?.zip_code,
          address_type: address?.address_type,
          address_line_2: address?.address_line_2,
        },
      }));
      setError((prevState) => ({
        ...prevState,
        billing_address: '',
      }));
    } else {
      setOrderDetail((prevOrderDetail) => ({
        ...prevOrderDetail,
        shipping_address_id: address?.id,
        shipping_address: {
          first_name: address?.first_name,
          last_name: address?.last_name,
          address_line_1: address?.address_line_1,
          phone_number: address?.phone_number,
          email: address?.email,
          country: address?.country_id,
          state: address?.state_id,
          city: address?.city_id,
          zip_code: address?.zip_code,
          address_type: address?.address_type,
          address_line_2: address?.address_line_2,
        },
      }));
      setError((prevState) => ({
        ...prevState,
        shipping_address: '',
      }));
    }
  };

  const selectUser = (id) => {
    if (id !== selectedUser) {
      setSelectedUser(id);
      setOrderDetail((prevOrderDetail) => ({
        ...prevOrderDetail,
        user_id: id,
      }));
    }
  };

  const makeOrder = () => {
    // setIsLoading(true);

    if (orderDetail.billing_address_id <= 0) {
      setError((prevState) => ({
        ...prevState,
        billing_address: 'Please select a billing address',
      }));
    }
    if (orderDetail.shipping_address_id <= 0) {
      setError((prevState) => ({
        ...prevState,
        shipping_address: 'Please select a shipping address',
      }));
    }

    if (errorFreeState) {
      doAPI
        .postData('order/create-user-order', orderDetail)
        .then((res) => {
          if (res.data.status) {
            notify(res.message);
            dispatch(clearCart());
            navigate('/order/list');
            setIsLoading(false);
          } else {
            if (res?.is_expire === true) logoutApp();
            notify('An error has occured, kindly contact the admin!');
            console.log(res?.data?.message[0].message);
          }
          console.log(res);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const addPaymentMethod = (payment_method, value) => {
    setOrderDetail((prevOrderDetail) => ({
      ...prevOrderDetail,
      payment_id: value,
      payment_method: payment_method,
    }));
  };

  return (
    <Pagelayout
      title="Checkout"
      breadcrumbs={[
        { title: 'Dashboard', link: '/dashboard' },
        { title: 'POS', link: '/pos' },
        { title: 'Checkout', link: '#' },
      ]}>
      <Notification />
      {cartItems.length > 0 ? (
        <Row className="mb-3">
          <Col md={12} xl={8}>
            <Row>
              <Col md={6}>
                <Label aria-required style={{ color: 'var(--color8)' }}>
                  Select User
                </Label>
                <CustomSelect
                  className={'w-100'}
                  placeholder="Select User"
                  value={selectedUser}
                  onChange={(value) => selectUser(value.value)}
                  options={userListState}
                />
              </Col>
              <Col md={6}>
                {Object.values(error).some((e) => e !== null && e !== '') && (
                  <div className="guest-checkout-errors">
                    <strong>Before Proceeding:</strong>
                    {Object.values(error).map((error, key) =>
                      error !== '' ? (
                        <li className="form-text" key={key}>
                          {error}
                        </li>
                      ) : (
                        ''
                      )
                    )}
                  </div>
                )}
              </Col>
              <Col md={12} className="mb-3">
                <Button variant="outline-dark" size="sm" className="my-2 " onClick={() => setModalShow(true)}>
                  + Add New Address for this user
                </Button>
              </Col>
              <Col md={6} className="border-end">
                {addressList.data && addressList.data.filter((j) => j.address_type === 'S').length === 0 && (
                  <div className="text-center py-5 text-muted">
                    <NoAddressFound addressType="Shipping" />
                  </div>
                )}
                {addressList.data &&
                  addressList.data
                    .filter((j) => j.address_type === 'S')
                    .map((i, index) => {
                      return (
                        <label key={index} className="border-top bottom-bottom position-relative mb-3 d-block p-3 ">
                          <input
                            type="radio"
                            style={{
                              position: 'absolute',
                              top: 15,
                              right: 10,
                              height: 25,
                              width: 25,
                            }}
                            name={i.address_type}
                            onChange={() => addAddressFromList(i)}
                          />
                          <div className="d-flex">
                            <div>
                              <FaMapMarkerAlt className="mb-0 me-2 h3 " style={{ color: 'var(--color1)' }} />
                            </div>
                            <div>
                              <h6 className="fw-bold">
                                {i.first_name} {i.last_name}
                              </h6>
                              <p className="mb-1">Email - {i.email}</p>
                              <p className="mb-1">Mobile - {i.phone_number}</p>
                              <p className="mb-1">
                                Address - {i.address_line_1}, {i.address_line_2}, {i.city?.name}, {i.state?.name},{' '}
                                {i.country?.name}, , {i.zip_code}
                              </p>
                            </div>
                          </div>
                        </label>
                      );
                    })}
              </Col>
              <Col md={6}>
                {addressList.data && addressList.data.filter((j) => j.address_type === 'B').length === 0 && (
                  <div className="text-center py-5 text-muted">
                    <NoAddressFound addressType="Billing" />
                  </div>
                )}
                {addressList.data &&
                  addressList.data
                    .filter((j) => j.address_type === 'B')
                    .map((i, index) => {
                      return (
                        <label key={index} className="border-top bottom-bottom position-relative mb-3 d-block p-3 ">
                          <input
                            type="radio"
                            style={{
                              position: 'absolute',
                              top: 15,
                              right: 10,
                              height: 25,
                              width: 25,
                            }}
                            name={i.address_type}
                            onChange={() => addAddressFromList(i)}
                          />
                          <div className="d-flex">
                            <div>
                              <FaMapMarkerAlt className="mb-0 me-2 h3 " style={{ color: 'var(--color1)' }} />
                            </div>
                            <div>
                              <h6 className="fw-bold">
                                {i.first_name} {i.last_name}
                              </h6>
                              <p className="mb-1">Email - {i.email}</p>
                              <p className="mb-1">Mobile - {i.phone_number}</p>
                              <p className="mb-1">
                                Address - {i.address_line_1}, {i.address_line_2}, {i.city?.name}, {i.state?.name},{' '}
                                {i.country?.name}, , {i.zip_code}
                              </p>
                            </div>
                          </div>
                        </label>
                      );
                    })}
              </Col>
            </Row>
          </Col>

          <Col md={12} xl={4}>
            <h5 className="fw-bold mb-4">Select Payment Method</h5>
            {paymentList?.data &&
              paymentList?.data.map((i, index) => (
                <Label className="fw-normal me-4 mb-3" key={index}>
                  <input
                    type="radio"
                    defaultChecked={index === 0}
                    name="payment_method"
                    onChange={() => addPaymentMethod(i.name, i.id)}
                  />{' '}
                  &nbsp;
                  {/* <Image
                    src={require("../../public/images/icons/" +
                      i.name.replace(/\//g, "") +
                      ".png")}
                    height="20"
                  /> */}
                  {i.name}
                </Label>
              ))}

            <hr />

            <h5 className="fw-bold mb-3">Order Notes</h5>
            <CustomInput
              type="text"
              className="form-control mb-3"
              placeholder="Order Notes"
              onChange={(event) => {
                setOrderDetail((prevState) => {
                  // Create a new object with the updated value
                  return {
                    ...prevState,
                    order_notes: event.target.value,
                  };
                });
              }}
            />

            <Card>
              <Card.Body className="text-start">
                <h6 className="fw-bold mb-2">
                  Customer Name - {orderDetail?.shipping_address?.first_name} {orderDetail?.shipping_address?.last_name}{' '}
                </h6>
                <hr />
                <h6 className="fw-bold mb-2">Shipping Address - </h6>
                <p>
                  {orderDetail?.shipping_address?.address_line_1} {orderDetail?.shipping_address?.address_line_2},{' '}
                  <CityStateCountryFetcher id={orderDetail?.shipping_address?.city} type="city" />,{' '}
                  <CityStateCountryFetcher id={orderDetail?.shipping_address?.state} type="state" />,{' '}
                  <CityStateCountryFetcher id={orderDetail?.shipping_address?.country} type="country" />,{' '}
                  {orderDetail?.shipping_address?.zip_code}
                </p>
                <hr />
                <h6 className="fw-bold mb-2">Billing Address - </h6>

                <p>
                  {orderDetail?.billing_address?.address_line_1} {orderDetail?.billing_address?.address_line_2},{' '}
                  <CityStateCountryFetcher id={orderDetail?.billing_address?.city} type="city" />,{' '}
                  <CityStateCountryFetcher id={orderDetail?.billing_address?.state} type="state" />,{' '}
                  <CityStateCountryFetcher id={orderDetail?.billing_address?.country} type="country" />,{' '}
                  {orderDetail?.billing_address?.zip_code}
                </p>
                <hr />
                <h5 className="fw-bold">Order Summary</h5>

                <table className="w-100 mt-2">
                  <tbody>
                    <tr>
                      <td>Sub Total</td>
                      <td align="right">
                        <Currency /> {cartSubTotal && amount(cartSubTotal)}
                      </td>
                    </tr>
                    <tr>
                      <td>VAT</td>
                      <td align="right">
                        {amount(
                          parseInt(cart.totalVatCustom) > 0 ? parseInt(cart.totalVatCustom) : parseInt(cart.totalVat)
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Coupon Code Discount</td>
                      <td align="right">{discount && discount.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Shipping Charge</td>
                      <td align="right">{parseFloat(shipping).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td align="right">
                        <Currency />
                        {amount(
                          parseInt(cart?.shipping) +
                            parseFloat(cart?.subTotal) +
                            (parseFloat(cart.totalVatCustom) > 0
                              ? parseFloat(cart.totalVatCustom)
                              : parseFloat(cart.totalVat)) -
                            (cart?.couponResponse?.data?.discount || 0)
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card.Body>
            </Card>
            <div className="text-end my-3">
              <Link to={'/pos'} className="btn btn-primary btn-lg">
                Back to Cart <FaCartPlus />
              </Link>{' '}
              &nbsp;
              <Button as="Link" size="lg" onClick={handleShowInvoice}>
                View Invoice <FaFileInvoice />{' '}
              </Button>{' '}
              &nbsp;
              <Button disabled={!errorFreeState} size="lg" onClick={() => makeOrder()}>
                {isLoading ? (
                  <BiLoader className="loader" />
                ) : (
                  <>
                    Countinue Checkout <AiOutlineSend />
                  </>
                )}{' '}
              </Button>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <div className="text-center pt-5">
            <Image height={100} src={require('../../public/images/empty-cart.png')} />
            <h4 className="mt-3" style={{ opacity: 0.5 }}>
              Your cart is empty, <br />
              please add some product in it
            </h4>
            <Link to="/pos" className="btn btn-primary mt-4 px-3">
              Go Back
            </Link>
          </div>
        </>
      )}

      <Modal show={showInvoice} size="xl" onHide={handleCloseInvoice}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {/* <div className="bg-white h-100"> */}
          <Receipt orderData={orderDetail} />
          {/* </div> */}
        </Modal.Body>
      </Modal>
      <AddressModal show={modalShow} onHide={() => setModalShow(false)} user={selectedUser} />
    </Pagelayout>
  );
}

import React, { useState, useRef } from "react";
import Currency from "../../Components/Currency";
import { useSelector } from "react-redux";
import {
  amount,
  getCountryName,
  getStateName,
} from "../../Components/Utils";
import LTable from "../../Components/LTable";
import Image from "../../Components/Image";
import { Col, Row } from "react-bootstrap";
import CityStateCountryFetcher from "./../../Components/CityStateCountryFetcher";

export default function Receipt({ orderData }) {
  const [orderInfo, setOrderInfo] = useState(orderData);

  const cart = useSelector((state) => state.cart);
  
  const selectedCurrency = useSelector(
    (state) => state.property.selectedCurrency
  )?.id;

  return (
    <>
      <div className="page-content">
        <div className="w-100 mt-3">
          <Image
            src={require("../../public/images/logo_black.png")}
            height={40}
          />
        </div>
        <div className="container px-0">
          {/* Customer Information */}

          <div className="">
            <div className="row mt-3">
              <div className="col-6">
                <p className="mb-1">
                  <b className="mt-0 fw-bold">Address</b> - 1249/12 Ground
                  Floor, Gems Tower, Charoenkrung RD., Suriyawong, Bangrak
                  Bangkok Thailand 10500
                </p>
                <p className="mb-1">
                  <b className="mt-0 fw-bold">Mobile</b> - 66-81-634-4340
                </p>
                <p className="mb-1">
                  <b className="mt-0 fw-bold">Shop</b> - 02 267 4480
                </p>
                <p className="mb-1">
                  <b className="mt-0 fw-bold">Email</b> -
                  manao.gemstower@yahoo.com
                </p>
              </div>
              <div className="col-6">
                {/* <h5 className="mt-4">Customer Information</h5> */}
                <p className="mb-1 fw-bold">
                  {orderInfo?.shipping_address?.first_name}
                  {orderInfo?.shipping_address?.last_name}
                </p>
                <p className="mb-1">{orderInfo?.shipping_address?.email}</p>
                <p className="mb-1">
                  {orderInfo?.shipping_address?.phone_number}
                </p>
                <p className="mb-1">
                  <b className="mt-0 fw-bold">Shipping Address</b> -&nbsp;
                  {orderInfo?.shipping_address?.address_line_1},
                  {orderInfo?.shipping_address?.address_line_2},
                  {" "}<CityStateCountryFetcher
                    id={orderInfo?.shipping_address?.city}
                    type="city"
                  />
                  ,{" "}
                  <CityStateCountryFetcher
                    id={orderInfo?.shipping_address?.state}
                    type="state"
                  />
                  ,{" "}
                  <CityStateCountryFetcher
                    id={orderInfo?.shipping_address?.country}
                    type="country"
                  />,{" "}

                  {orderInfo?.shipping_address?.zip_code}
                </p>
                <p className="mb-1 mt-0">
                  <b className="mt-0 fw-bold">Billing Address</b> -&nbsp;
                  {orderInfo?.billing_address?.address_line_1},
                  {orderInfo?.billing_address?.address_line_2},
                  {" "}<CityStateCountryFetcher
                    id={orderInfo?.shipping_address?.city}
                    type="city"
                  />
                  ,{" "}
                  <CityStateCountryFetcher
                    id={orderInfo?.shipping_address?.state}
                    type="state"
                  />
                  ,{" "}
                  <CityStateCountryFetcher
                    id={orderInfo?.shipping_address?.country}
                    type="country"
                  />,{" "}
                  {orderInfo?.billing_address?.zip_code}
                </p>
              </div>
            </div>
          </div>
          {/* Order Items */}
          <div className="mt-4">
            <LTable className="table table-bordered">
              <thead>
                <tr className="text-600 py-25">
                <th className="bgc-default-tp1 text-white" width={50}>#</th>
                  
                  <th className="bgc-default-tp1 text-white" width={200}>Item Name</th>
                  <th className="bgc-default-tp1 text-white" width={100}>Weight</th>
                  <th className="bgc-default-tp1 text-white" width={100}>Qty</th>
                  <th className="bgc-default-tp1 text-white">Unit Price</th>
                  <th className="bgc-default-tp1 text-white">Amount</th>
                </tr>
              </thead>
              <tbody>
                {cart?.items !== undefined &&
                  cart?.items.map((j, index) => {
                    // Additional checks for existence
                    if (!j || !j.sizes || !j.size_id) {
                      return null; // or handle the case when data is missing
                    }

                    const size = j.sizes.find((size) => j.size_id === size.id);

                    if (!size) {
                      return null; // or handle the case when size is not found
                    }

                    const priceObj = size.size_prices.find(
                      (price) => price.currency_id === selectedCurrency
                    );

                    if (!priceObj) {
                      return null; // or handle the case when priceObj is not found
                    }

                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{j?.name} </td>
                        <td>{size?.weight} Gms</td>
                        <td>{j?.quantity}</td>
                        <td>
                          {amount(
                            j?.price_type === "gram"
                              ? priceObj.price_gram
                              : priceObj.price_piece
                          )}
                        </td>
                        <td>
                          <Currency />
                          {amount(j?.total_amount)}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
              <thead>
                <tr className="text-600 py-25">
                  <th className="bgc-default-tp1 text-white" colSpan={4}></th>
                  <th className="bgc-default-tp1 text-white">Net Total</th>
                  <th className="bgc-default-tp1 text-white">
                    <Currency />
                    {amount(
                      cart?.shipping +
                        cart?.subTotal +
                        (parseInt(cart.totalVatCustom) > 0
                          ? parseInt(cart.totalVatCustom)
                          : parseInt(cart.totalVat)) -
                        (cart?.couponResponse?.data?.discount || 0)
                    )}
                  </th>
                </tr>
              </thead>
            </LTable>

            <Row>
              <Col className="text-center border rounded py-3">
                _________________________
                <br />
                ผู้รับของ RECEIVER
                <br />
                วันที่......./......./.......
              </Col>
              <Col className="text-center border py-3">
                _________________________
                <br />
                ผู้ส่งของ
                <br />
                DELIVERER
              </Col>
              <Col className="text-center border py-3">
                _________________________
                <br />
                ผู้รับมอบอำนาจ
                <br />
                Authorizer Signature
              </Col>
            </Row>

            {/* <div class="row border-b-2 brc-default-l2"></div> */}
            {/* <div class="row mt-3">
              <div class="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">
                {orderInfo?.extra_note}
              </div>

              <div class="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
                <table className="w-100 mt-3">
                  <tbody>
                    <tr>
                      <td>Sub Total</td>
                      <td align="right">
                        <Currency /> {cartSubTotal && amount(cartSubTotal)}
                      </td>
                    </tr>
                    <tr>
                      <td>VAT</td>
                      <td align="right">{amount(cartTotalVat)}</td>
                    </tr>
                    <tr>
                      <td>Coupon Code Discount</td>
                      <td align="right">{amount(discount)}</td>
                    </tr>
                    <tr>
                      <td>Shipping Charge</td>
                      <td align="right">{amount(cartShippingCharge)}</td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td align="right">
                        <Currency />
                        {amount(
                          cart?.shipping +
                            cart?.subTotal +
                            (parseInt(cart.totalVatCustom) > 0
                              ? parseInt(cart.totalVatCustom)
                              : parseInt(cart.totalVat)) -
                            (cart?.couponResponse?.data?.discount || 0)
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <style jsx>{`
        @media print {
          html {
            page-break-after: always;
          }

          table.no-border thead th {
            background-color: #f0f0f0;
          }

          table.no-border,
          table.no-border tr,
          table.no-border th,
          table.no-border td {
            padding: 10px;
            border-color: #000000;
          }
          table.no-border thead th {
            background-color: red;
          }
        }

        .text-secondary-d1 {
          color: #000000 !important;
        }
        .page-header {
          margin: 0 0 1rem;
          padding-bottom: 1rem;
          padding-top: 0.5rem;
          border-bottom: 1px dotted #e2e2e2;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -ms-flex-align: center;
          align-items: center;
        }
        .page-title {
          padding: 0;
          margin: 0;
          font-size: 1.75rem;
          font-weight: 300;
        }
        .brc-default-l1 {
          border-color: #dce9f0 !important;
        }

        .ml-n1,
        .mx-n1 {
          margin-left: -0.25rem !important;
        }
        .mr-n1,
        .mx-n1 {
          margin-right: -0.25rem !important;
        }
        .mb-4,
        .my-4 {
          margin-bottom: 1.5rem !important;
        }

        hr {
          margin-top: 1rem;
          margin-bottom: 1rem;
          border: 0;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
        }

        .text-grey-m2 {
          color: #888a8d !important;
        }

        .text-success-m2 {
          color: #86bd68 !important;
        }

        .font-bolder,
        .text-600 {
          font-weight: 600 !important;
        }

        .text-110 {
          font-size: 110% !important;
        }
        .text-blue {
          color: #478fcc !important;
        }
        .pb-25,
        .py-25 {
          padding-bottom: 0.75rem !important;
        }

        .pt-25,
        .py-25 {
          padding-top: 0.75rem !important;
        }
        .bgc-default-tp1 {
          background-color: rgba(121, 169, 197, 0.92) !important;
        }
        .bgc-default-l4,
        .bgc-h-default-l4:hover {
          background-color: #f3f8fa !important;
        }
        .page-header .page-tools {
          -ms-flex-item-align: end;
          align-self: flex-end;
        }

        .btn-light {
          color: #757984;
          background-color: #f5f6f9;
          border-color: #dddfe4;
        }
        .w-2 {
          width: 1rem;
        }

        .text-120 {
          font-size: 120% !important;
        }
        .text-primary-m1 {
          color: #4087d4 !important;
        }

        .text-danger-m1 {
          color: #dd4949 !important;
        }
        .text-blue-m2 {
          color: #68a3d5 !important;
        }
        .text-150 {
          font-size: 150% !important;
        }
        .text-60 {
          font-size: 60% !important;
        }
        .text-grey-m1 {
          color: #7b7d81 !important;
        }
        .align-bottom {
          vertical-align: bottom !important;
        }
        
        .sidebar{
          display:none !important;
        }
      `}</style>
    </>
  );
}

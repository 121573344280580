import React,{useState,useEffect} from 'react'
import { Button, Col, Form } from 'react-bootstrap'
import Notification, { notify } from '../../../Components/Notification'
import Label from '../../../Components/Label'
import CustomInput from '../../../Components/CustomInput'
import { useFormik } from 'formik'
import doAPI from '../../../api'
import * as Yup from 'yup';
import { fetchCategories } from '../../../redux/categorySlice'
import { useDispatch, useSelector } from 'react-redux'
import { BiLoader } from 'react-icons/bi'

export default function EditSize({sizeData, formSubmit}) {
   const [isLoading, setIsLoading] = useState(false)
   const dispatch = useDispatch()
   const categories = useSelector((state) => state.category.categories?.data); 
   
   useEffect(() => {dispatch(fetchCategories())}, [dispatch]);

   const formik = useFormik({
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: {
         id:sizeData?.id,
         category_id: sizeData.category_id,
         name: sizeData.name
      },
      onSubmit: (values, { resetForm }) => {
        setIsLoading(true);
  
        doAPI.putData("size/size-name/update", values)
            .then((res) => {
              if (res.status) {
                 notify(res.message)
                 setTimeout(() => { formSubmit()  }, 1000);
              } else {
                notify(res.message[0].message)
              };
              setIsLoading(false);
            });
  
        resetForm();
      },
      validationSchema: Yup.object().shape({
        category_id: Yup.number().required('Category is required.'),
        name: Yup.string().required('Name is required.'),
      })
   });
   


  return (
   <Form onSubmit={formik.handleSubmit} >
   <Notification />
   <Col md={12} className='mb-3'>
     <Label aria-required>Select Category</Label>
     <select
       className="form-control"
       name='category_id'
       value={formik.values.category_id}
       onChange={formik.handleChange}
       onBlur={formik.handleBlur}
     >
       <option value={0}>Select Category</option>
       {categories && categories.map(i => <option key={i.id} value={i.id}>{i.name}</option>)}
     </select>
   </Col>
   <Col md={12} className='mb-3'>
     <CustomInput
       className="form-control"
       type="text"
       placeholder="Add Size Name"
       name="name"
       value={formik.values.name}
       onChange={formik.handleChange}
       onBlur={formik.handleBlur}
     />
   </Col>
   <Col md={6} className='mb-3 mx-auto'>
     <Button className='w-100' type="submit">{isLoading ? <BiLoader className='loader' /> : <>Update</>}</Button>
   </Col>
 </Form>
  )
}

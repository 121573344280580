// sizeSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Service from '../http';

const services = new Service();

// Async thunk for fetching size names
export const fetchSizeNames = createAsyncThunk('sizes/fetchSizeNames', async () => {
  try {
    const response = await services.get('size/size-name/list');
    return response.data;
  } catch (error) {
    throw error;
  }
});

// Initial state
const initialState = {
  sizeNames: [],
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

// Create a slice
const sizeSlice = createSlice({
  name: 'sizes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSizeNames.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSizeNames.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.sizeNames = action.payload;
      })
      .addCase(fetchSizeNames.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default sizeSlice.reducer;

// usersSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Service from '../http';
import doAPI from '../api';

const services = new Service();

// Async thunk for fetching users
export const fetchUsers = createAsyncThunk('users/fetchUsers', async ({ page = '', role_id = ''}) => {
  try {
    const params = new URLSearchParams({ page, role_id }).toString();
    const response = await doAPI.getData(`user/list?${params}`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

// Initial state
const initialState = {
  userList: [],
  status: 'idle',
  error: null,
};

// Create a slice
const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    changeUserRole: (state, action) => {
      const { userId, newRoleId } = action.payload;
      // Find the user index in the state
      const userIndex = state.userList.findIndex(user => user.id === userId);
      
      // If the user is found, update its role using immutability
      if (userIndex !== -1) {
        state.userList = [
          ...state.userList.slice(0, userIndex),
          { ...state.userList[userIndex], role_id: newRoleId },
          ...state.userList.slice(userIndex + 1),
        ];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userList = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      
  },
});

export default usersSlice.reducer;

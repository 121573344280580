import { resetUserState } from '../redux/loginSlice';

export const localStorageMiddleware = () => (next) => (action) => {

  if (action.type === 'RESET_USER_STATE_FROM_LOCAL_STORAGE') {
    const userData = localStorage.getItem('user');
    
    if (userData) {
      try {
        const parsedUserData = JSON.parse(userData);
        next(resetUserState(parsedUserData));
      } catch (error) {
        console.error('Error parsing user data from localStorage:', error);
        // Handle the error here, e.g., dispatch an action to inform about the error
      }
    }
  } else {
    next(action);
  }
};

// tagSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Service from "../http";

// Define an initial state
const initialState = {
  tagList: [],
  loading: false,
  error: null,
};

// Create an instance of the Service class
const service = new Service();

// Define the async thunk for fetching the tag list
export const fetchTagList = createAsyncThunk(
  "tags/fetchTagList",
  async () => {
    try {
      const url = "tag/list";
      const data = await service.get(url);
      return data?.data;
    } catch (error) {
      throw Error("Failed to fetch tag list");
    }
  }
);

// Create the slice
const tagSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTagList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTagList.fulfilled, (state, action) => {
        state.loading = false;
        state.tagList = action.payload;
      })
      .addCase(fetchTagList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export the reducer
export default tagSlice.reducer;

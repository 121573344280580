// assignCourierCompanySlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import doAPI from "../api";

// Async thunk for assigning a courier company to an order
export const assignCourierCompany = createAsyncThunk(
  "order/assign-courier-company",
  async (data) => {
    try {
      const response = await doAPI.putData("order/update-track-detail", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Initial state
const initialState = {
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  response: null,
};

// Create a slice
const assignCourierCompanySlice = createSlice({
  name: "assignCourierCompany",
  initialState,
  reducers: {
    clearResponse: (state) => {
      state.status= "idle";
      state.response = null;
      state.error =null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(assignCourierCompany.pending, (state) => {
        state.status = "loading";
      })
      .addCase(assignCourierCompany.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.response = action.payload;
      })
      .addCase(assignCourierCompany.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.response = action.payload;
      });
  },
});

export const { clearResponse } = assignCourierCompanySlice.actions;

export default assignCourierCompanySlice.reducer;
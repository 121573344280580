import React from 'react';
// import { Trans, useTranslation } from 'react-i18next';

const Label = ({ children, ...props }) => {
   // const { t } = useTranslation();

   return (
      <label className='input-label' {...props}>
         {/* <Trans> */}
         {children}
         {/* </Trans> */}
      </label >
   );
};

export default Label;

import React from "react";
import Charts from "../../Components/Charts";

export default function MonthlySalesCharts() {
  const lineChartOptions = {
    colors: ["#3CA5F2", "#48DA74"],
    chart: {
      id: "basic-line",
    },
    fill: {
      opacity: 0.5,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    grid: { show: false },
  };

  const lineChartSeries = [
    {
      name: "series-1",
      data: [30, 40, 35, 50, 49, 60, 70, 91, 125, 100, 150, 110],
    },
    {
      name: "series-2",
      data: [10, 30, 45, 35, 60, 55, 80, 75, 95, 120, 130, 140],
    },
  ];

  return (
    <div jsx="true">
      <Charts
        type="area"
        options={lineChartOptions}
        series={lineChartSeries}
        height={350}
      />
    </div>
  );
}

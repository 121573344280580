import React, { useState } from 'react';
import CustomInput from './CustomInput';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Notification, { notify } from './Notification';
import { addCouponSuccess, addCouponFailure, removeCoupon } from '../redux/AddToCart';
import { useNavigate } from 'react-router-dom';
import { BiLoader } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import doAPI from '../api';

export default function AddCouponCode({ productArr, cartTotal, afterCouponCartTotal }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const couponCode = useSelector((state) => state.cart.couponResponse?.coupon_code);
  const couponStatus = useSelector((state) => state.cart.couponResponse?.status);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const selectedCurrency = useSelector((state) => state.property.selectedCurrency)?.id;

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      coupon_code: couponCode ? couponCode : '',
      user_id: 0,
      products: [],
      currency_id: selectedCurrency,
    },
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      const newValues = {
        ...values,
        products: productArr,
      };

      doAPI
        .postData('coupon/apply', newValues)
        .then((res) => {
          if (!isAuthenticated) {
            navigate('/login');
          }
          if (res.status) {
            notify(res.message);
            // dispatch(addCoupon(values.coupon_code)); // Dispatch the coupon code
            dispatch(addCouponSuccess(res));
            // Dispatch success action with the entire response
            setIsLoading(false);
            // setIsCoponApplied(true)
            notify(res?.data.message);
          } else {
            console.log(res);
            notify('Invalid Coupon Code');
            dispatch(addCouponFailure(res)); // Dispatch failure action with the entire response
            setIsLoading(false);
            resetForm();
          }
        })
        .catch((error) => {
          console.error('Error applying coupon:', error);
        });
    },
    validationSchema: Yup.object().shape({
      coupon_code: Yup.string().required('Coupon Code is required.'),
    }),
  });

  const removeCouponEvent = () => {
    dispatch(removeCoupon());
    formik.setFieldValue('coupon_code', '');
    // setIsCoponApplied(false)
  };

  return (
    <>
      <Form onSubmit={formik.handleSubmit} className={couponStatus ? 'coupon applied-coupon' : 'coupon'}>
        <Notification />
        <div className="couponSection">
          <CustomInput
            readOnly={couponStatus === true && true}
            className="form-control input-sm"
            type="text"
            placeholder="Enter coupon"
            name="coupon_code"
            value={formik.values.coupon_code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {couponCode === undefined && (
            <Button type="submit">
              {isLoading ? (
                <BiLoader className="loader" />
              ) : couponStatus !== true ? (
                <span style={{ fontSize: 12 }}>APPLY</span>
              ) : null}
            </Button>
          )}

          {couponStatus === true && (
            <Button type="button" onClick={removeCouponEvent}>
              <AiOutlineDelete />
            </Button>
          )}
        </div>
      </Form>
      {cartTotal !== afterCouponCartTotal && (
        <div className="text-danger"> Cart updated! Please apply coupon again.</div>
      )}
    </>
  );
}

/* eslint-disable */
import * as React from "react";
import { Button } from "react-bootstrap";

export default function Upload({ getUploadedFile }) {
    const [fileFromInput, setFileFromInput] = React.useState([])
    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        if (files.length === 0) {
            return alert("Please select a file.");
        }
        // const reader = new FileReader();
        // reader.onload = () => {
        setFileFromInput(old => [...old, ...e.target.files])
        getUploadedFile(fileFromInput);
        // };
        // reader.readAsDataURL(files[0]);
    };

    React.useEffect(() => {
        getUploadedFile(fileFromInput);
    }, [fileFromInput])
    return (
        <label htmlFor="contained-button-file">
            <input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                onChange={onChange}
            />
            <Button variant="primary">
                Select Image
            </Button>
        </label>
    );
}

import React, { useEffect, useState } from "react";
import Pagelayout from "../../Layouts/Pagelayout";
import { Button, Card, Col, Dropdown, Form, Modal } from "react-bootstrap";
import Service from "../../http";
import LTable from "../../Components/LTable";
import { BiDotsVerticalRounded, BiLoader } from "react-icons/bi";
import { tableSerialNumber } from "../../Components/Utils";
import CustomInput from "../../Components/CustomInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import Notification, { notify } from "../../Components/Notification";
import { Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import Label from "./../../Components/Label";
import { fetchTagList } from "../../redux/tagSlice";
import { useDispatch, useSelector } from "react-redux";

export default function ProductTags() {
  const dispatch = useDispatch();
  const services = new Service();
  const [isLoading, setIsLoading] = useState(false);
  // const [tagList, setTagList] = useState([])
  // const [page, setPage] = useState(1)
  const [showItemList, setShowItemList] = useState(false);
  const handleCloseItemList = () => setShowItemList(false);
  const handleShowItemList = () => {
    setShowItemList(true);
  };

  const tagList = useSelector((state) => state?.tags?.tagList);

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      tag_id: 0,
      name: "",
      slug: "",
      status: 0,
    },
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);

      services &&
        services.post("tag/add-update", values).then((res) => {
          if (res.status) {
            notify(res.message);
            fetchEntry();
            handleCloseItemList();
          } else {
            res.message.map((i) => notify(i.message));
          }
          setIsLoading(false);
        });

      resetForm();
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required."),
      slug: Yup.string().required("Slug is required."),
    }),
  });

  const fetchEntry = () => {
    dispatch(fetchTagList());
  };

  useEffect(() => {
    fetchEntry();
  }, [dispatch]);

  const deleteEntry = (id) => {
    services &&
      services
        .put("tag/delete/" + id)
        .then((res) => {
          if (res.status) {
            notify(res.message);
            fetchEntry();
          } else {
            notify(res.message[0]?.message);
          }
        })
        .catch((error) => {
          console.error("Error - ", error);
        });
  };

  const handleStatusChange = (item) => {
    services &&
      services
        .post("tag/add-update", {
          status: item.status === 1 ? 0 : 1,
          tag_id: item.id,
          slug: item.slug,
        })
        .then((res) => {
          if (res.status) {
            notify(res.message);
            fetchEntry();
          } else {
            notify(res.message[0]?.message);
          }
        })
        .catch((error) => {
          console.error("Error - ", error);
        });
  };

  return (
    <Pagelayout
      title="Tag List"
      breadcrumbs={[
        { title: "Dashboard", link: "/dashboard" },
        { title: "Products", link: "/products" },
        { title: "Tag", link: "/tag-list" },
      ]}
      actions={<Button onClick={handleShowItemList}>Add Tag</Button>}
    >
      <Notification />
      <Card className="overflow-visible">
        <Card.Body>
          <LTable>
            <thead>
              <tr>
                <th width={50}>#</th>
                <th>Name</th>
                <th>Slug</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tagList &&
                tagList.map((i, index) => (
                  <tr key={index}>
                    <td>
                      {tableSerialNumber(
                        index,
                        tagList?.cur_page,
                        tagList.length,
                        20
                      )}
                    </td>
                    <td>{i.name}</td>
                    <td>{i.slug}</td>
                    <td>
                      <label
                        onClick={() => handleStatusChange(i)}
                        className={
                          i.status === 1
                            ? "custom_check active"
                            : "custom_check"
                        }
                      >
                        <div className="custom_check_wrapper">
                          <span></span>
                          <label>
                            {i.status === 1 ? "Active" : "Deactivated"}
                          </label>
                        </div>
                      </label>
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <BiDotsVerticalRounded />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            onClick={() => deleteEntry(i.id)}
                            to="#"
                          >
                            <MdDelete /> Delete
                          </Dropdown.Item>
                          {/* <Dropdown.Item href="#/action-2"><MdEdit /> Edit</Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
            </tbody>
          </LTable>
        </Card.Body>
      </Card>

      <Modal show={showItemList} onHide={handleCloseItemList}>
        <Modal.Header closeButton>
          <Modal.Title>Add Tag Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Notification />
            <Col md={12} className="mb-3">
              <Label>Tag Name</Label>
              <CustomInput
                className="form-control"
                type="text"
                placeholder="Tag Name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              {formik.touched.name && formik.errors.name ? (
                <div className="error">{formik.errors.name}</div>
              ) : null}
            </Col>
            <Col md={12} className="mb-3">
              <Label>Slug</Label>
              <CustomInput
                className="form-control"
                type="text"
                placeholder="Slug"
                name="slug"
                value={formik.values.slug}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              {formik.touched.slug && formik.errors.slug ? (
                <div className="error">{formik.errors.slug}</div>
              ) : null}
            </Col>
            <Button size="lg" className="w-100" type="submit">
              {isLoading ? <BiLoader className="loader" /> : <>Add</>}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Pagelayout>
  );
}

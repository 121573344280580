import React, { useEffect, useState } from 'react';
import Label from './../../Components/Label';
import Service from './../../http';
import { Button, Card, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import Notification, { notify } from './../../Components/Notification';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, FieldArray, Field } from 'formik';
import { BiCopy, BiLoader, BiTrash } from 'react-icons/bi';
import { fetchCurrencyList } from './../../redux/currencySlice';
import { useDispatch, useSelector } from 'react-redux';
import doAPI from './../../api';
import { fetchVATInfo } from '../../redux/vatSlice';
import { AiOutlinePlus } from 'react-icons/ai';

export default function AddSize({ product_id, categoryId, colorId }) {
    const services = new Service();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const colorList = useSelector((state) => state.color?.colorlist?.data?.data);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingWeight, setLoadingWeight] = useState(null);
    const [sizeList, setSizeList] = useState([]);
    // const [masterGramList, setMasterGramList] = useState([]);
    const currency = useSelector((state) => state.currency?.currencylist?.data);
    const [sizeLimit, setSizeLimit] = useState(1);
    const [weightByIndex, setWeightByIndex] = useState([]);
    const initialValues = {
        product_id: product_id,
        sizes: [
            {
                stock: 0,
                weight: 0,
                length: 0,
                bar_code: '',
                size_name_id: 0,
                master_gram_id: 0,
                color_id: colorId,
                size_prices: [
                    {
                        price_gram: 0,
                        price_piece: 0,
                        discount: 0,
                        currency_id: 1,
                        vat_gram: 0,
                        vat_piece: 0,
                        vat_percentage: 0,
                    },
                    {
                        price_gram: 0,
                        price_piece: 0,
                        discount: 0,
                        currency_id: 2,
                        vat_gram: 0,
                        vat_piece: 0,
                        vat_percentage: 0,
                    },
                ],
            },
        ],
    };

    // useEffect(() => {
    //     services &&
    //         services.get('master/list-gram').then((res) => {
    //             setMasterGramList(res.data.rows);
    //         });
    // }, []);

    useEffect(() => {
        dispatch(fetchCurrencyList());
        dispatch(fetchVATInfo());
    }, [dispatch]);

    // const vatInfo = useSelector((state) => state?.vatSetting?.vatInfo);

    const validationSchema = Yup.object().shape({
        sizes: Yup.array().of(
            Yup.object().shape({
                stock: Yup.number().required('Stock is required'),
                weight: Yup.number().required('Weight is required'),
                length: Yup.number().required('Length is required'),
                bar_code: Yup.string().required('Barcode is required'),
                size_name_id: Yup.number().required('Size Name ID is required'),
                master_gram_id: Yup.number().required('Master Gram ID is required'),
                color_id: Yup.number().required('Color is required'),

                size_prices: Yup.array().of(
                    Yup.object().shape({
                        price_gram: Yup.number().required('Price per Gram is required'),
                        price_piece: Yup.number().required('Price per Piece is required'),
                        discount: Yup.number().required('Discount is required'),
                        currency_id: Yup.number().required('Currency ID is required'),
                        vat_gram: Yup.number().required('VAT per Gram is required'),
                        vat_piece: Yup.number().required('VAT per Piece is required'),
                        vat_percentage: Yup.number().required('VAT Percentage is required'),
                    })
                ),
            })
        ),
    });

    const fetchWeightEntry = async (weight, index) => {
        setLoadingWeight(index);
        const response = await doAPI.getData('master/gram-price?weight=' + weight);
        if (response.status === 200) {
            setLoadingWeight(null);
        }
        setWeightByIndex((old) => {
            const newArray = [...old];
            const existingIndex = newArray.findIndex((item) => item && item.index === index);

            if (existingIndex !== -1) {
                // Update existing entry at the specified index
                newArray[existingIndex] = response.data?.data;
            } else {
                // Insert new entry at the specified index
                newArray[index] = response.data?.data;
            }
            return newArray;
        });
    };

    useEffect(() => {
        services &&
            services.get('size/size-name/list?page=1&status=1&category_id=' + categoryId).then((res) => {
                setSizeList(res.data);
                setSizeLimit(res.data.length);
            });
    }, [categoryId]);

    const handleSubmit = async (values) => {
        console.log(values);
        const updatedObject = { ...values };
        updatedObject.product_id = product_id;
        try {
            const res = await doAPI.postData('size/add', updatedObject);
            setIsLoading(true);
            if (res.status) {
                notify(res.message);
                setTimeout(() => {
                    setIsLoading(false);
                    navigate('/products');
                }, 300);
            } else {
                console.error(res);
            }
        } catch (error) {
            console.error('API request failed:', error.response.data.message);
            error.response.data.message.map((i) => notify(i.message));
        }
    };

    return (
        <>
            <Notification />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, formikBag) => handleSubmit(values, formikBag)}>
                {({ values, setFieldValue }) => (
                    <Form className="sizeForm">
                        {/* {JSON.stringify(values)} */}
                        <FieldArray name="sizes">
                            {({ push, remove }) => (
                                <div>
                                    {values.sizes.map((size, index) => (
                                        <Card className="mb-3 border">
                                            <Card.Body>
                                                <Row className="gx-2 mb-3" key={index}>
                                                    {loadingWeight === index && (
                                                        <div className="customLoadingScreen">Loading...</div>
                                                    )}
                                                    <Col style={{ minWidth: 120 }} lg={2} className="mb-2 w-10">
                                                        <Label>Size</Label>
                                                        <Field
                                                            className="form-control"
                                                            as="select"
                                                            name={`sizes[${index}].size_name_id`}
                                                            placeholder="Size Name">
                                                            <option value={0}>Select Size</option>
                                                            {sizeList &&
                                                                sizeList.map((i, index) => (
                                                                    <option
                                                                        key={index}
                                                                        value={i.id}
                                                                        selected={
                                                                            i.id === values.sizes[index]?.size_name_id
                                                                        }>
                                                                        {i.name}
                                                                    </option>
                                                                ))}
                                                        </Field>
                                                    </Col>
                                                    <Col style={{ minWidth: 120 }} lg={2} className="mb-2 w-10">
                                                        <Label>Stock</Label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name={`sizes[${index}].stock`}
                                                            placeholder="Stock"
                                                        />
                                                    </Col>
                                                    <Col style={{ minWidth: 120 }} lg={2} className="mb-2 w-10">
                                                        <Label>Weight</Label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name={`sizes[${index}].weight`}
                                                            placeholder="Weight"
                                                            onChange={(e) => {
                                                                fetchWeightEntry(e.target.value, index);
                                                                setFieldValue(`sizes[${index}].weight`, e.target.value);
                                                                setFieldValue(
                                                                    `sizes[${index}].master_gram_id`,
                                                                    weightByIndex[index]?.id
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                    {/* {weightByIndex[index]?.id} */}
                                                    <Col style={{ minWidth: 120 }} lg={2} className="mb-2 w-10">
                                                        <Label>Weight Range</Label>
                                                        {/* Select tag for master_gram_id */}
                                                        <Field
                                                            disabled
                                                            className="form-control"
                                                            type="text"
                                                            value={weightByIndex[index]?.title}
                                                            placeholder="Weight Range"
                                                        />
                                                    </Col>
                                                    <Col className="mb-2 w-10">
                                                        <Label>Bar Code</Label>
                                                        <Field
                                                            className="form-control"
                                                            required
                                                            type="text"
                                                            name={`sizes[${index}].bar_code`}
                                                            placeholder="Bar Code"
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    `sizes[${index}].bar_code`,
                                                                    e.target.value
                                                                );
                                                                setFieldValue(
                                                                    `sizes[${index}].master_gram_id`,
                                                                    weightByIndex[index]?.id
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col className="mb-2 w-10">
                                                        <Label>Color</Label>
                                                        <Field
                                                            className="form-control"
                                                            as="select"
                                                            name={`sizes[${index}].color_id`}
                                                            placeholder="Size Name">
                                                            <option value={0}>Select Color</option>
                                                            {colorList &&
                                                                colorList.map((i, index) => (
                                                                    <option
                                                                        key={index}
                                                                        value={i.id}
                                                                        selected={
                                                                            i.id === values.sizes[index]?.color_id
                                                                        }>
                                                                        {i.name}
                                                                    </option>
                                                                ))}
                                                        </Field>
                                                    </Col>

                                                    {size.size_prices.map((price, priceIndex) => (
                                                        <Col lg={6} className=" mt-0">
                                                            <Row className="gx-2" key={priceIndex}>
                                                                <Col style={{ minWidth: 100 }} className="mb-2">
                                                                    <Label>
                                                                        Price / Gm (
                                                                        {
                                                                            currency.find(
                                                                                (k) =>
                                                                                    k.id ===
                                                                                    values.sizes[index]?.size_prices[
                                                                                        priceIndex
                                                                                    ]?.currency_id
                                                                            )?.short_code
                                                                        }
                                                                        )
                                                                    </Label>

                                                                    <Field
                                                                        disabled
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={
                                                                            weightByIndex[index]?.setting_prices &&
                                                                            weightByIndex[index]?.setting_prices.find(
                                                                                (k) =>
                                                                                    k.currency_id ===
                                                                                    values.sizes[index]?.size_prices[
                                                                                        priceIndex
                                                                                    ]?.currency_id
                                                                            ).price_gram
                                                                                ? weightByIndex[
                                                                                      index
                                                                                  ]?.setting_prices.find(
                                                                                      (k) =>
                                                                                          k.currency_id ===
                                                                                          values.sizes[index]
                                                                                              ?.size_prices[priceIndex]
                                                                                              ?.currency_id
                                                                                  ).price_gram
                                                                                : values.sizes[index].size_prices[
                                                                                      priceIndex
                                                                                  ].price_gram
                                                                        }
                                                                        name={`sizes[${index}].size_prices[${priceIndex}].price_gram`}
                                                                        placeholder="Price per Gram"
                                                                    />
                                                                </Col>
                                                                <Col style={{ minWidth: 100 }} className="mb-2">
                                                                    <Label>
                                                                        Price / Piece (
                                                                        {
                                                                            currency.find(
                                                                                (k) =>
                                                                                    k.id ===
                                                                                    values.sizes[index]?.size_prices[
                                                                                        priceIndex
                                                                                    ]?.currency_id
                                                                            )?.short_code
                                                                        }
                                                                        )
                                                                    </Label>
                                                                    <Field
                                                                        min={0}
                                                                        className="form-control"
                                                                        type="text"
                                                                        name={`sizes[${index}].size_prices[${priceIndex}].price_piece`}
                                                                        placeholder="Price per Piece"
                                                                        onBlur={(e) => {
                                                                            // Check if the value is not empty and doesn't already contain a decimal point
                                                                            const currentValue = e.target.value;
                                                                            if (
                                                                                currentValue &&
                                                                                currentValue.indexOf('.') === -1
                                                                            ) {
                                                                                // Append ".00" to the value
                                                                                const updatedValue = `${currentValue}.00`;
                                                                                setFieldValue(
                                                                                    `sizes[${index}].size_prices[${priceIndex}].price_piece`,
                                                                                    updatedValue
                                                                                );
                                                                                e.target.value = updatedValue; // Update the input field value
                                                                            }
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col style={{ minWidth: 100 }} className="mb-2">
                                                                    <Label>
                                                                        Discount (
                                                                        {
                                                                            currency.find(
                                                                                (k) =>
                                                                                    k.id ===
                                                                                    values.sizes[index]?.size_prices[
                                                                                        priceIndex
                                                                                    ]?.currency_id
                                                                            )?.short_code
                                                                        }
                                                                        )
                                                                    </Label>
                                                                    <Field
                                                                        min={0}
                                                                        className="form-control"
                                                                        type="text"
                                                                        name={`sizes[${index}].size_prices[${priceIndex}].discount`}
                                                                        placeholder="Discount"
                                                                        onBlur={(e) => {
                                                                            const currentValue = e.target.value;
                                                                            if (
                                                                                currentValue &&
                                                                                currentValue.indexOf('.') === -1
                                                                            ) {
                                                                                // Append ".00" to the value
                                                                                const updatedValue = `${currentValue}.00`;
                                                                                setFieldValue(
                                                                                    `sizes[${index}].size_prices[${priceIndex}].discount`,
                                                                                    updatedValue
                                                                                );
                                                                                e.target.value = updatedValue; // Update the input field value
                                                                            }
                                                                        }}
                                                                    />
                                                                </Col>

                                                                {/* {vatInfo && vatInfo.find(j => j.currency_id == values.sizes[index]?.size_prices[priceIndex]?.currency_id)?.is_vat > 0 && <>
                                                <Col lg={2} sm={3} xs={6} className='mb-2'>
                                                   <Label>VAT Per Gram</Label>
                                                   <Field className="form-control" type="text" name={`sizes[${index}].size_prices[${priceIndex}].vat_gram`} placeholder="VAT per Gram"
                                                      onBlur={(e) => {
                                                         // Check if the value is not empty and doesn't already contain a decimal point
                                                         const currentValue = e.target.value;
                                                         if (currentValue && currentValue.indexOf('.') === -1) {
                                                            // Append ".00" to the value
                                                            const updatedValue = `${currentValue}.00`;
                                                            setFieldValue(`sizes[${index}].size_prices[${priceIndex}].vat_gram`, updatedValue);
                                                            e.target.value = updatedValue; // Update the input field value
                                                         }
                                                      }}
                                                   />
                                                </Col>
                                                <Col lg={2} sm={3} xs={6} className='mb-2'>
                                                   <Label>VAT Per Piece</Label>
                                                   <Field className="form-control" type="text" name={`sizes[${index}].size_prices[${priceIndex}].vat_piece`} placeholder="VAT per Piece"
                                                      onBlur={(e) => {
                                                         // Check if the value is not empty and doesn't already contain a decimal point
                                                         const currentValue = e.target.value;
                                                         if (currentValue && currentValue.indexOf('.') === -1) {
                                                            // Append ".00" to the value
                                                            const updatedValue = `${currentValue}.00`;
                                                            setFieldValue(`sizes[${index}].size_prices[${priceIndex}].vat_piece`, updatedValue);
                                                            e.target.value = updatedValue; // Update the input field value
                                                         }
                                                      }}
                                                   />
                                                </Col>
                                                <Col lg={2} sm={3} xs={6} className='mb-2'>
                                                   <Label>VAT %</Label>
                                                   <Field className="form-control" type="text" name={`sizes[${index}].size_prices[${priceIndex}].vat_percentage`} placeholder="VAT %"
                                                      onBlur={(e) => {
                                                         // Check if the value is not empty and doesn't already contain a decimal point
                                                         const currentValue = e.target.value;
                                                         if (currentValue && currentValue.indexOf('.') === -1) {
                                                            // Append ".00" to the value
                                                            const updatedValue = `${currentValue}.00`;
                                                            setFieldValue(`sizes[${index}].size_prices[${priceIndex}].vat_percentage`, updatedValue);
                                                            e.target.value = updatedValue; // Update the input field value
                                                         }
                                                      }}
                                                   />
                                                </Col>
                                             </>} */}
                                                            </Row>
                                                        </Col>
                                                    ))}
                                                </Row>
                                                <Button
                                                    className="removebtn"
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => remove(index)}>
                                                    <BiTrash />
                                                </Button>
                                                <Button
                                                    className="copybtn"
                                                    variant="primary"
                                                    size="sm"
                                                    onClick={() => {
                                                        setFieldValue(`sizes[${index + 1}]`, values.sizes[index]);
                                                        setFieldValue(
                                                            `sizes[${index + 1}].master_gram_id`,
                                                            weightByIndex[index]?.id
                                                        );
                                                    }}>
                                                    <BiCopy />
                                                </Button>
                                            </Card.Body>
                                        </Card>
                                    ))}
                                    <Button
                                        disabled={values.sizes.length === sizeLimit}
                                        size="lg"
                                        type="button"
                                        onClick={() => {
                                            // Use the FieldArray's push function
                                            push({
                                                stock: 0,
                                                weight: 1,
                                                length: 0,
                                                bar_code: '',
                                                size_name_id: 0,
                                                master_gram_id: 0,
                                                color_id: colorId,
                                                size_prices: [
                                                    {
                                                        price_gram: 0,
                                                        price_piece: 0,
                                                        discount: 0,
                                                        currency_id: 1,
                                                        vat_gram: 0,
                                                        vat_piece: 0,
                                                        vat_percentage: 0,
                                                    },
                                                    {
                                                        price_gram: 0,
                                                        price_piece: 0,
                                                        discount: 0,
                                                        currency_id: 2,
                                                        vat_gram: 0,
                                                        vat_piece: 0,
                                                        vat_percentage: 0,
                                                    },
                                                ],
                                            });
                                        }}>
                                        <AiOutlinePlus /> Add Size
                                    </Button>
                                </div>
                            )}
                        </FieldArray>
                        <Col className="text-end" style={{ marginTop: -40 }}>
                            <Button size="lg" type="submit">
                                {' '}
                                {isLoading ? <BiLoader className="loader" /> : 'Save'}
                            </Button>
                        </Col>
                    </Form>
                )}
            </Formik>
        </>
    );
}

import React from 'react'
import Pagelayout from '../../../Layouts/Pagelayout'
import { Badge, Button, Card } from 'react-bootstrap'
import CustomSelect from '../../../Components/CustomSelect'
import LTable from '../../../Components/LTable'
import Currency from '../../../Components/Currency'
import { AiFillFileText, AiOutlineFileText, AiOutlinePlus } from 'react-icons/ai'
import CustomDatePicker from '../../../Components/CustomDatePicker'
import Label from '../../../Components/Label'
import { Link } from 'react-router-dom'

export default function Purchase() {
   return (
      <Pagelayout
         title="Inventory"
         breadcrumbs={
            [
               { title: 'Inventory', link: '/inventory' },
               { title: 'Purchase', link: '/inventory/purchase' },
            ]
         }>
         <Card>
            <Card.Body>
               <div className='table_filter equal_space'>
                  <div className='table_filter_item align-items-center w-100'>
                     <Label>Type - </Label>  <label className='me-3'><input type="checkbox" name="type" /> Delivery note / Purchase invoice</label>
                     <label className='me-3'><input type="checkbox" name="type" /> Purchase order</label>
                     <label className='me-3'><input type="checkbox" name="type" /> Credit invoice</label>
                  </div>
                  <div className='table_filter_item align-items-center'>
                     <Label>Start</Label>
                     <CustomDatePicker
                        name="dob"
                        className="form-control"
                        // value={formik.values.dob}
                        onChange={(value) => console.log(value)}
                     // onBlur={formik.handleBlur}
                     />
                  </div>
                  <div className='table_filter_item align-items-center'>
                     <Label>End</Label> <CustomDatePicker
                        name="dob"
                        className="form-control"
                        // value={formik.values.dob}
                        onChange={(value) => console.log(value)}
                     // onBlur={formik.handleBlur}
                     />
                  </div>

                  <div className='table_filter_item'>
                     <CustomSelect
                        placeholder="Select Option"
                        //   value={formik.values.country}
                        onChange={(value) =>
                           console.log(value)
                        }
                        options={[]}
                     />
                  </div>

                  <div className='table_filter_item'>
                     <CustomSelect
                        placeholder="Select Suppiler"
                        //   value={formik.values.country}
                        onChange={(value) =>
                           console.log(value)
                        }
                        options={[]}
                     />
                  </div>

                  <div className='table_filter_item'>
                     <Button className='w-100' size='lg'><AiOutlinePlus /> New purchase invoice</Button>
                  </div>
                  <div className='table_filter_item'>
                     <Button className='w-100' variant='dark' size='lg'><AiOutlinePlus /> New purchase order</Button>
                  </div>
               </div>
               <LTable>
                  <thead>
                     <tr>
                        <th>Type</th>
                        <th>Document Number</th>
                        <th>document Date</th>
                        <th>Status</th>
                        <th>Supplier</th>
                        <th>Store to</th>
                        <th>Total without VAT</th>
                        <th>Total with VAT</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td><AiOutlineFileText className='h5 m-0 text-primary' /></td>
                        <td><Link className="text-black" to='/inventory/purchase/65656BGHH5454878454'>65656BGHH5454878454</Link></td>
                        <td>20, Nov. 2023</td>
                        <td><Badge bg="success">Success</Badge></td>
                        <td>Supplier Name</td>
                        <td>Store name</td>
                        <td><Currency /> 6565.00</td>
                        <td><Currency /> 6865.00</td>
                     </tr>
                     <tr>
                        <td><AiOutlineFileText className='h5 m-0 text-primary' /></td>

                        <td><Link className="text-black" to='/inventory/purchase/65656BGHH5454878454'>65656BGHH5454878454</Link></td>

                        <td>20, Nov. 2023</td>
                        <td><Badge bg="success">Success</Badge></td>
                        <td>Supplier Name</td>
                        <td>Store name</td>
                        <td><Currency /> 6565.00</td>
                        <td><Currency /> 6865.00</td>
                     </tr>
                     <tr>
                        <td><AiOutlineFileText className='h5 m-0 text-danger' /></td>

                        <td><Link className="text-black" to='/inventory/purchase/65656BGHH5454878454'>65656BGHH5454878454</Link></td>

                        <td>20, Nov. 2023</td>
                        <td><Badge bg="success">Success</Badge></td>
                        <td>Supplier Name</td>
                        <td>Store name</td>
                        <td><Currency /> 6565.00</td>
                        <td><Currency /> 6865.00</td>
                     </tr>
                     <tr>
                        <td><AiOutlineFileText className='h5 m-0 text-primary' /></td>

                        <td><Link className="text-black" to='/inventory/purchase/65656BGHH5454878454'>65656BGHH5454878454</Link></td>

                        <td>20, Nov. 2023</td>
                        <td><Badge bg="success">Success</Badge></td>
                        <td>Supplier Name</td>
                        <td>Store name</td>
                        <td><Currency /> 6565.00</td>
                        <td><Currency /> 6865.00</td>
                     </tr>
                     <tr>
                        <td><AiOutlineFileText className='h5 m-0 text-danger' /></td>

                        <td><Link className="text-black" to='/inventory/purchase/65656BGHH5454878454'>65656BGHH5454878454</Link></td>

                        <td>20, Nov. 2023</td>
                        <td><Badge bg="success">Success</Badge></td>
                        <td>Supplier Name</td>
                        <td>Store name</td>
                        <td><Currency /> 6565.00</td>
                        <td><Currency /> 6865.00</td>
                     </tr>
                     <tr>
                        <td><AiOutlineFileText className='h5 m-0 text-primary' /></td>

                        <td><Link className="text-black" to='/inventory/purchase/65656BGHH5454878454'>65656BGHH5454878454</Link></td>

                        <td>20, Nov. 2023</td>
                        <td><Badge bg="success">Success</Badge></td>
                        <td>Supplier Name</td>
                        <td>Store name</td>
                        <td><Currency /> 6565.00</td>
                        <td><Currency /> 6865.00</td>
                     </tr>
                     <tr>
                        <td><AiOutlineFileText className='h5 m-0 text-primary' /></td>

                        <td><Link className="text-black" to='/inventory/purchase/65656BGHH5454878454'>65656BGHH5454878454</Link></td>

                        <td>20, Nov. 2023</td>
                        <td><Badge bg="success">Success</Badge></td>
                        <td>Supplier Name</td>
                        <td>Store name</td>
                        <td><Currency /> 6565.00</td>
                        <td><Currency /> 6865.00</td>
                     </tr>
                     <tr>
                        <td><AiOutlineFileText className='h5 m-0 text-primary' /></td>

                        <td><Link className="text-black" to='/inventory/purchase/65656BGHH5454878454'>65656BGHH5454878454</Link></td>

                        <td>20, Nov. 2023</td>
                        <td><Badge bg="success">Success</Badge></td>
                        <td>Supplier Name</td>
                        <td>Store name</td>
                        <td><Currency /> 6565.00</td>
                        <td><Currency /> 6865.00</td>
                     </tr>

                  </tbody>
               </LTable>
            </Card.Body>
         </Card>
      </Pagelayout >

   )
}

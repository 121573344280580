import React from 'react';

const NoAddressFound = ({addressType}) => {
  return (
    <>
    <div className="no-address-container">
      <div className="pin-container">
        <img
          src="https://cdn-icons-png.flaticon.com/512/9356/9356230.png"
          alt="Map Pin"
          className="pin-icon"
        />
      </div>
      <div className="message-container">
          <p>No {addressType } Address Found</p>
      </div>
      </div>
      <style jsx>
        {
          `
          .no-address-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-family: 'Arial', sans-serif;
            text-align: center;
          }
          
          .pin-container {
            position: relative;
            margin-bottom: 10px;
            height: 100px;
            width: 100px;
            border: 1px solid #ddd;
            border-radius: 100%;
            overflow:hidden
        }
          
        .pin-icon {
          height: 60px;
          animation: pinPeep .5s alternate;
          position: absolute;
          bottom: 20px;
          width: 100%;
          object-fit: contain;
          object-position: center center;
          left: 0;
        }
          
          .message-container {
            font-size: 18px;
          }
          
          @keyframes pinPeep {
            0% {
              bottom: -60px;
            }
            100% {
              bottom: 20px;
            }
          }
          
          `
      }
      </style>  
    </>
  );
};

export default NoAddressFound;

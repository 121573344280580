import React, { useState, useEffect } from 'react';
import Pagelayout from '../Layouts/Pagelayout';
import { Card, Row, Col, Button } from 'react-bootstrap';
import ProductCard from '../Components/ProductCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../redux/categorySlice';
import { BiX } from 'react-icons/bi';
import CustomInput from '../Components/CustomInput';
import { fetchProductList } from '../redux/productSlice';
import { CartItems } from '../Components/CartItems';
import Pagination from '../Components/Pagination';
import { BiLoader } from 'react-icons/bi';
import { useDebouncedCallback } from 'use-debounce';

export default function Home() {
  const dispatch = useDispatch();
  // const services = new Service();
  const [size, setSize] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('');
  // const [toggleSidebar, setToggleSidebar] = useState(false);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [skuSearchQuery, setSkuSearchQuery] = useState('');
  const [searchNameQuery, setSearchNameQuery] = useState('');
  const { productList, loading, error } = useSelector((state) => state.product);

  const fetchEntry = () => {
    dispatch(
      fetchProductList({
        page: page,
        search: searchQuery,
        category: category,
        price: price,
        size: size,
        sku: skuSearchQuery,
        name: searchNameQuery,
        isActive: 1,
      })
    );
  };

  const categories = useSelector((state) => state.category?.categories?.data);

  useEffect(() => {
    // Dispatch the fetchCategories async thunk when the component mounts
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    fetchEntry();
  }, [page, searchQuery, size, price, category, skuSearchQuery, searchNameQuery]);

  const clearFilter = () => {
    setCategory('');
    setPrice('');
    setSearchQuery('');
    setSize('');
    setSkuSearchQuery('');
    setSearchNameQuery('');
  };

  // const getcategory = (value) => {
  //   setCategory(value);
  // };

  // function getPrice(pricerange) {
  //   setPrice(pricerange);
  // }

  // const handleRangeValueChange = (newValues) => {
  //   setPrice(newValues);
  // };

  const searchName = useDebouncedCallback((event) => {
    setSearchNameQuery(event.target.value);
    setSkuSearchQuery(event.target.value);
  }, 500);

  // const searchSku = (event) => {
  //   setSkuSearchQuery(event.target.value);
  // };
  // const handleCouponChange = (event) => {
  //    setCouponCode(event.target.value);
  // };

  const handlePageChange = (newPage) => {
    console.log(newPage);
    setPage(parseInt(newPage));
  };

  const handleGoToPage = (event) => {
    setPage(parseInt(event.target.value));
  };

  return (
    <Pagelayout
    // title="Product List"
    >
      <Card>
        <Card.Body className="p-0">
          <Row>
            <Col lg={6} className="pe-md-0">
              <div className="newCardHeader">
                <Row className="gx-2">
                  <Col md={4} xs={4} style={{ minWidth: 140 }}>
                    <CustomInput
                      className="form-control input-sm"
                      onChange={(event) => searchName(event)}
                      type="search"
                      placeholder="Search Product..."
                    />
                  </Col>
                  <Col md={4} xs={4} style={{ minWidth: 140 }}>
                    <select className="input-sm form-control" onChange={(e) => setCategory(e.target.value)}>
                      <option selected={category === ''} value="">
                        All Categories
                      </option>
                      {categories &&
                        categories.length > 0 &&
                        categories.map((i, index) => (
                          <option key={i.slug} value={i.slug}>
                            {i.name}
                          </option>
                        ))}
                    </select>
                  </Col>
                  <Col className="text-end ms-auto ">
                    <Button variant="none" onClick={() => clearFilter()} className="fw-bold my-0" size="sm">
                      Clear Filter <BiX className="h5 m-0" />
                    </Button>
                  </Col>
                </Row>
              </div>

              {loading ? (
                <div className="d-flex justify-content-center mt-5">
                  <BiLoader className="loader" />
                </div>
              ) : productList?.rows.length > 0 ? (
                <div className="productSection px-3">
                  <Row className="g-xl-3 g-2">
                    {productList?.rows && productList?.rows.map((i, index) => <ProductCard data={i} key={index} />)}
                  </Row>
                  <Pagination
                    pagecount={productList?.total_pages}
                    handleGoToPage={handleGoToPage}
                    currentpage={page || 1}
                    onPageChange={handlePageChange}
                  />
                </div>
              ) : error ? (
                <div className="d-flex justify-content-center mt-5">
                  We have encountered a problem fetching data from that resource.
                </div>
              ) : (
                <div className="d-flex justify-content-center mt-5">No records found.</div>
              )}
            </Col>

            <Col lg={6} className="ps-md-0">
              <CartItems />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Pagelayout>
  );
}

import React, { useEffect, useState } from 'react'
import doAPI from '../api';

export default function CityStateCountryFetcher({ id, type }) {
   const [name, setName] = useState('');

   useEffect(() => {
     const fetchData = async () => {
       try {
         const response = await doAPI.getData(`${type}/${id}`);
         const newName = response?.data?.data?.name;
         setName(newName);
       } catch (error) {
         console.error(`Error fetching ${type}:`, error);
       }
     };
 
     fetchData();
   }, [id, type]);
 
   return <span>{name}</span>;
}

import React, { useState, useEffect } from "react";
import { fetchCountries, fetchStatesByCountry, fetchCitiesByState } from "../../redux/LocationSlice";
// import { createAddress, ADDRESS_CREATE_RESET, staffListAddresss, listAddresss } from "@/redux/actions/ShippingActions";
import { useSelector, useDispatch } from "react-redux";
import { notify } from "../../Components/Notification";
import * as Yup from "yup";
import { Col, Modal, Row, Button } from "react-bootstrap";
import CustomInput from "../../Components/CustomInput";
import CustomSelect from "../../Components/CustomSelect";
import Label from "../../Components/Label";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import doAPI from "../../api";

function AddressModal({ show, onHide, user }) {
  const dispatch = useDispatch();

  const [countryCode, setCountryCode] = useState();

  const countryData = [];
  const stateData = [];
  const cityData = [];
  const { countries } = useSelector((state) => state.location);
  const { states } = useSelector((state) => state.location);
  const { cities } = useSelector((state) => state.location);

  countries.map((country) =>
    countryData.push({
      value: country.id,
      label: country.name,
    })
  );

  states.map((state) =>
    stateData.push({
      value: state.id,
      label: state.name,
    })
  );

  cities.map((city) =>
    cityData.push({
      value: city.id,
      label: city.name,
    })
  );

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  const createAddress = async (values) => {
    // make POST request
    const res = await doAPI.postData("/user/add-address", values);
    console.error(res);

    if (res.data.status) {
      console.log(res.data);
      notify(res?.data?.message);
      onHide();
    } else {
      if (Array.isArray(res?.data?.message)) {
        notify("An error has occured, kindly contact the admin!");
        console.log(res?.data?.message[0].message);
      } else {
        notify(res?.data?.message || "Something went wrong!");
      }
    }
  };

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      first_name: "",
      last_name: "",
      address_line_1: "",
      address_line_2: "",
      phone_number: 0,
      email: "",
      country_id: null,
      state_id: null,
      city_id: null,
      user_id: user,
      zip_code: "",
      default: 0,
      address_type: "B",
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      address_line_1: Yup.string().required("Address is required"),
      address_line_2: Yup.string().required("Landmark is required"),
      phone_number: Yup.number().required("Phone is required"),
      email: Yup.string().email().required("Invalid email format"),
      country_id: Yup.number().required("Country is required"),
      state_id: Yup.number().required("Required"),
      city_id: Yup.number().required("Required"),
      default: Yup.number().optional(),
      user_id: Yup.number().optional(),
      zip_code: Yup.string().required("Zipcode is required"),
      address_type: Yup.string().required("Address type is required"),
    }),
    onSubmit: (values) => {
      console.log(values);
      const data = { ...values, default: 0, user_id: user };
      if (values.address_type === "same") {
        const billingData = { ...data, address_type: "B" };
        const shippingData = { ...data, address_type: "S" };
        createAddress(billingData);
        createAddress(shippingData);
      } else {
        createAddress(data);
      }
    },
  });

  const optionData = [
    {
      value: "",
      label: "Select Address Type",
    },
    {
      value: "B",
      label: "Billing Address",
    },
    {
      value: "S",
      label: "Shipping Address",
    },
    {
      value: "same",
      label: "Set as both Billing & Shipping Address",
    },
  ];

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <h5 className="fw-bold">Create a new Address</h5>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={6} className="mb-3">
              <Label aria-required>First Name</Label>
              <CustomInput
                placeholder="First Name"
                name="first_name"
                className="form-control"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.first_name && formik.errors.first_name ? (
                <div className="error">{formik.errors.first_name}</div>
              ) : null}
            </Col>

            <Col md={6} className="mb-3">
              <Label aria-required>Last Name</Label>
              <CustomInput
                placeholder="Last Name"
                name="last_name"
                className="form-control"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.last_name && formik.errors.last_name ? (
                <div className="error">{formik.errors.last_name}</div>
              ) : null}
            </Col>

            <Col md={6} className="mb-3">
              <Label aria-required>Phone</Label>
              <PhoneInput
                countryCodeEditable={false}
                country={(countryCode ? countryCode : "TH").toLowerCase()}
                value={formik.values.phone_number}
                onChange={(phone, country, e, formattedValue) => {
                  formik.setFieldValue("phone_number", formattedValue);
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.phone_number && formik.errors.phone_number ? (
                <div className="error">{formik.errors.phone_number}</div>
              ) : null}
            </Col>

            <Col md={6} className="mb-3">
              <Label aria-required>Email</Label>
              <CustomInput
                type="email"
                placeholder="Email"
                name="email"
                className="form-control"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? <div className="error">{formik.errors.email}</div> : null}
            </Col>

            <Col md={6} className="mb-3">
              <Label aria-required>Select Country</Label>
              <CustomSelect
                className="w-100"
                value={formik.values.country_id}
                onBlur={formik.handleBlur} // pass onBlur handler from Formik form
                form={formik} // pass the formik instance to CustomSelect
                onChange={(value) => {
                  formik.setFieldValue("country_id", value.value);
                  dispatch(fetchStatesByCountry(value.value));
                }}
                options={countryData}
              />
              {formik.touched.country_id && formik.errors.country_id ? (
                <div className="error">{formik.errors.country_id}</div>
              ) : null}
            </Col>

            <Col md={6} className="mb-3">
              <Label aria-required>Select State</Label>
              <CustomSelect
                className="w-100"
                value={formik.values.state_id}
                onBlur={formik.handleBlur} // pass onBlur handler from Formik form
                form={formik} // pass the formik instance to CustomSelect
                onChange={(value) => {
                  formik.setFieldValue("state_id", value.value);
                  dispatch(fetchCitiesByState(value.value));
                }}
                options={stateData}
              />
              {formik.touched.state_id && formik.errors.state_id ? (
                <div className="error">{formik.errors.state_id}</div>
              ) : null}
            </Col>

            <Col md={6} className="mb-3">
              <Label>Select City</Label>
              <CustomSelect
                className="w-100"
                value={formik.values.city_id}
                onBlur={formik.handleBlur} // pass onBlur handler from Formik form
                form={formik} // pass the formik instance to CustomSelect
                onChange={(value) => formik.setFieldValue("city_id", value.value)}
                options={cityData}
              />
              {formik.touched.city_id && formik.errors.city_id ? (
                <div className="error">{formik.errors.city_id}</div>
              ) : null}
            </Col>

            <Col md={6} className="mb-3">
              <Label aria-required>Address</Label>
              <textarea
                type="text"
                placeholder="Address"
                name="address_line_1"
                className="form-control"
                rows="1"
                value={formik.values.address_line_1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}></textarea>

              {formik.touched.address_line_1 && formik.errors.address_line_1 ? (
                <div className="error">{formik.errors.address_line_1}</div>
              ) : null}
            </Col>

            <Col md={6} className="mb-3">
              <Label aria-required>Landmark</Label>
              <textarea
                type="text"
                placeholder="Landmark"
                name="address_line_2"
                className="form-control"
                rows="1"
                value={formik.values.address_line_2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}></textarea>
              {formik.touched.address_line_2 && formik.errors.address_line_2 ? (
                <div className="error">{formik.errors.address_line_2}</div>
              ) : null}
            </Col>

            <Col md={6} className="mb-3">
              <Label aria-required>Zip Code</Label>
              <CustomInput
                type="text"
                placeholder="Zip Code"
                name="zip_code"
                className="form-control"
                value={formik.values.zip_code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.zip_code && formik.errors.zip_code ? (
                <div className="error">{formik.errors.zip_code}</div>
              ) : null}
            </Col>

            <Col md={12} className="mb-3">
              <Label>Set Billing Address same as Shipping</Label>
              <CustomSelect
                className="w-100"
                value={formik.values.address_type}
                onBlur={formik.handleBlur} // pass onBlur handler from Formik form
                form={formik} // pass the formik instance to CustomSelect
                onChange={(value) => formik.setFieldValue("address_type", value.value)}
                options={optionData}
              />
            </Col>
          </Row>
          <Button type="submit">Submit</Button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddressModal;

import React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import CustomSelect from '../../../Components/CustomSelect'
// import CustomDatePicker from '../../../Components/CustomDatePicker'
import Notification from '../../../Components/Notification'
import ToggleSwitch from '../../../Components/ToggleSwitch'
// import { useFormik } from "formik";
// import * as Yup from 'yup';
// import Service from '../../../http'
import Label from '../../../Components/Label'
export default function Configuration() {

   // const services = new Service();
   // const [isLoading, setIsLoading] = useState(false)
   // const formik = useFormik({
   //    validateOnBlur: true,
   //    validateOnChange: true,
   //    initialValues: {

   //    },
   //    onSubmit: (values, { resetForm }) => {
   //       setIsLoading(true);

   //       services &&
   //          services
   //             .post("", values)
   //             .then((res) => {
   //                res.status ? notify(res.message) : notify(res.message[0].message);
   //                setIsLoading(false);
   //             });

   //       resetForm();
   //    },
   //    validationSchema: Yup.object().shape({
   //       center_id: Yup.number().required('Center ID is required.'),
   //       name_en: Yup.string().required('Name is required.'),
   //       name_other: Yup.string().required('Name is required.'),
   //       center_sport_id: Yup.number().required('Center sport ID is required.'),
   //       start_date: Yup.string().required('Start Date is required.'),
   //       end_date: Yup.string().required('End Date is required.'),

   //    })
   // });
   return (
      <Form>
         <Notification />
         <Row>

            <Col md={12} className='mb-3'>
               <Label aria-required>Select Product Identifiers</Label>
               <CustomSelect
                  className={'w-100'}
                  placeholder="Select Product Identifiers"
                  //   value={formik.values.country}
                  onChange={(value) =>
                     console.log(value)
                  }
                  options={[{ label: 'Product Number', value: 1 }, { label: 'Barcode', value: 2 }]}
               />
            </Col>

            <Col md={12} className='mb-3'>


               <ToggleSwitch name="check" initialChecked={false} text={'Import new products'} />

               <ToggleSwitch name="check" initialChecked={false} text={'New products are activated'} />

               <hr />

               <ToggleSwitch name="check" initialChecked={false} text={'Upload only unfinished products'} />

               <ToggleSwitch name="check" initialChecked={false} text={'Owerwrite existing values with empty values'} />

               <ToggleSwitch name="check" initialChecked={false} text={'Update sales price for existing products'} />

               <ToggleSwitch name="check" initialChecked={false} text={'Activate disabled products'} />

               <ToggleSwitch name="check" initialChecked={false} text={'Validate that product has got sales price'} />
               <hr />
               <ToggleSwitch name="check" initialChecked={false} text={'Skip first row'} />

            </Col>

            <Col md={12} className='mb-3'>
               <Label aria-required>Add/Update suppiler information</Label>
               <CustomSelect
                  className={'w-100'}
                  placeholder="Add/Update suppiler information"
                  //   value={formik.values.country}
                  onChange={(value) =>
                     console.log(value)
                  }
                  options={[{ label: 'Product Number', value: 1 }, { label: 'Barcode', value: 2 }]}
               />
            </Col>
            <Col md={12} className='mb-3'>
               <Button size='lg'>Submit</Button>
            </Col>
         </Row>
      </Form >
   )
}

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "./Image";

const CommonSlider = (props) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    style: { height: "180px" }, // Set the height directly on the Slider component
  };

  return (
    <>
    <Slider {...settings}>
      {props.physicalImg !== "" && (
        <div>
          <img
            style={{ width: "100%", height: "180px", objectFit:'cover' }}
            src={props.physicalImg}
            alt={props.physicalImg}
          />
        </div>
      )}
      {props.data.map((i) => (
        <div key={i.id}>
          <img
            style={{ width: "100%", height: "180px", objectFit:'cover' }}
            alt={`${i.image_path}/${i.image_name}?resize=300,300`}
            src={`${i.image_path}/${i.image_name}?resize=300,300`}
          />
        </div>
      ))}

    
      </Slider>
      <style jsx>{`
        button.slick-arrow {
          top: 50%;
          z-index: 1000;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
          opacity: 1;
          border-radius: 20px;
          background: rgba(0, 0, 0, 0.2);
        }
        .slick-prev {
          left: 7px;
        }
        .slick-next {
          right: 7px;
        }
      `}</style> 
    
    </>
  );
};

export default CommonSlider;

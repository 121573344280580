import React, { useState, useEffect } from "react";
import Pagelayout from "../../Layouts/Pagelayout";
import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import Label from "../../Components/Label";
import CustomSelect from "../../Components/CustomSelect";
import CustomDatePicker from "../../Components/CustomDatePicker";
import LTable from "../../Components/LTable";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import CustomInput from "../../Components/CustomInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import Notification, { notify } from "../../Components/Notification";
import Service from "../../http";
import { redirect, useNavigate, useParams } from "react-router-dom";
import { amount, logoutApp, slugGeneration } from "../../Components/Utils";
import CommonEditor from "../../Components/CommonEditor";
import CustomMultiUpload from "../../Components/CustomMultiUpload";
import AddSize from "./AddSize";
import { BiLoader } from "react-icons/bi";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import EditSize from "./EditSize";
import NewImageUploader from "../../Components/NewImageUploader";
import TagSelector from "./../../Components/TagSelector";
import { fetchConsignment } from "../../redux/consignmentSlice";
import Currency from "../../Components/Currency";
import doAPI from "../../api";
import CustomCropper from "../../Components/CustomCropper";
export default function EditProduct() {
  const { id } = useParams();

  const services = new Service();
  const [selectedImages, setSelectedImages] = useState([]);
  const [editorData, setEditorData] = useState("");
  const [selectedCodeFile, setSelectedCodeFile] = useState(null);

  const [categoryInfo, setCategoryInfo] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [productId, setProductId] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productTags, setProductTags] = useState();
  const [generatedKeywords, setGeneratedKeywords] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [showSize, setShowSize] = useState(false);
  const [subCategoryInfo, setSubCategoryInfo] = useState([]);
  const [isImageDeleted, setIsImageDeleted] = useState(false);
  const [selectedSize, setSelectedSize] = useState();
  const selectedCurrency = useSelector(
    (state) => state.property.selectedCurrency
  )?.id;
  const currency = useSelector((state) => state.currency?.currencylist?.data);

  const dispatch = useDispatch();

  const handleCloseSize = () => setShowSize(false);
  const handleShowSize = () => setShowSize(true);

  const navigate = useNavigate();

  // const handleImageUpload = (selectedImages) => {

  //    const files = Array.from(selectedImages);
  //    console.log("New Images:", files);

  //    setSelectedImages(old => [...old, ...files]);
  // };

  const stocklist = useSelector(
    (state) => state.consignmentlist.consignments?.rows
  );
  useEffect(() => {
    dispatch(fetchConsignment());
  }, [dispatch]);

  const NewProductSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    short_description: Yup.string().required("Short Description is required"),
    full_description: Yup.string().required("Full Description is required"),
    slug: Yup.string().required("Slug is required"),
    sku: Yup.string().required("Sku is required"),
    category_id: Yup.number().required("category_id is required"),
    sub_category_id: Yup.number().required("sub_category_id is required"),
    color_id: Yup.number().required("color_id is required"),
    meta_name: Yup.string().required("meta_name is required"),
    meta_description: Yup.string().required("Meta Description is required"),
    meta_keywords: Yup.string().required("Meta Kwywords is required"),
    tag_id: Yup.mixed().required("Tags are required"),
    stock_id: Yup.mixed().required("Stock are required"),
    code_image: Yup.mixed().required("Image are required"),
  });

  const fetchDetails = () => {
    services &&
      services.get("product/detail-id/" + id).then((res) => {
        setProductDetails(res.data);
        setProductId(res.data?.id);
        setSelectedSize(res.data?.sizes[0]?.size_name_id);
      });
  };
  useEffect(() => {
    fetchDetails();
  }, [isImageDeleted]);

  useEffect(() => {
    setSelectedFile(productDetails?.product_images);
  }, [productDetails?.product_images]);

  useEffect(() => {
    setSelectedCodeFile([
      {
        image_name: productDetails?.code_image_name,
        image_path: productDetails?.code_image_path,
      },
    ]);
  }, [productDetails?.product_images]);

  // console.log({ selectedImages })

  const uploadImages = (id) => {
    console.log({ id });
    if (id !== null) {
      const token = () => {
        if (localStorage.getItem("token")) {
          let tokenVal = localStorage.getItem("token").replace(/['"]+/g, "");
          return "Bearer " + tokenVal;
        }
      };

      const baseUrl = process.env.REACT_APP_BASE_URL;
      const headers = {
        Accept: "*",
        "Content-Type": "multipart/form-data",
      };
      const formData = new FormData();
      formData.append("product_id", id);
      selectedFile.forEach((file) => {
        formData.append("product_images", file);
      });
      headers.Authorization = token();
      axios
        .post(baseUrl + "/product/images", formData, { headers })
        .then((res) => {
          console.log({ formData });
          if (res.status) {
            notify(res.message);
            // handleImageChange();
          } else {
            notify(res.message[0]?.message[0]?.message, { variant: "error" });
          }
        })
        .catch((error) => {
          console.error("Error uploading images:", error);
        });
    }
  };

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      name: productDetails?.name,
      slug: productDetails?.slug,
      product_id: productDetails?.product_id,
      sku: productDetails?.sku,
      short_description: productDetails?.short_description,
      full_description: productDetails?.full_description,
      category_id: productDetails?.category_id,
      sub_category_id: productDetails?.sub_category_id,
      color_id: productDetails?.color_id,
      meta_name: productDetails?.meta_name,
      meta_description: productDetails?.meta_description,
      meta_keywords: productDetails?.meta_keywords,
      tag_id: productDetails?.tag_id,
      stock_id: productDetails?.stock_id,
      parent_id: 0,
      code_image:
        productDetails?.code_image_path + "/" + productDetails?.code_image_name,
      cover_image: null,
    },
    onSubmit: (values, { resetForm }) => {},
    validationSchema: NewProductSchema,
  });

  const formSubmit = () => {
    uploadImages(id);
    doAPI
      .postWithImage("/product/add-update", formik.values)
      .then((res) => {
        res?.response?.data?.message.map((i) => notify(i.message));
        if (res.status) {
          setIsFormSubmitted(true);
          notify(res.message);
          setProductId(res.data?.data?.id);
          handleShowSize();
          services &&
            services.get("product/detail-id/" + id).then((res) => {
              setProductDetails(res.data);
            });
        } else {
          console.log(res);
          res.message.map((i) => console.log(i.message));
          res.message.map((i) => notify(i.message));
          console.log(res.response);
          notify(res.message, { variant: "error" });
        }
      })
      .catch((err) => {
        err?.response?.data?.message.map((i) => notify(i.message));
      });
  };
  useEffect(() => {
    formik.setValues({
      name: productDetails?.name,
      slug: productDetails?.slug,
      product_id: productDetails?.id,
      sku: productDetails?.sku,
      short_description: productDetails?.short_description,
      full_description: productDetails?.full_description,
      category_id: productDetails?.category_id,
      sub_category_id: productDetails?.sub_category_id,
      color_id: productDetails?.color_id,
      meta_name: productDetails?.meta_name,
      meta_description: productDetails?.meta_description,
      meta_keywords: productDetails?.meta_keywords || "keywords",
      parent_id: 0,
      stock_id: productDetails?.stock_id,
      code_image: productDetails?.code_image_path
        ? productDetails?.code_image_path +
          "/" +
          productDetails?.code_image_name
        : "",
      cover_image: productDetails?.code_image_path
        ? productDetails?.code_image_path +
          "/" +
          productDetails?.code_image_name
        : "",
    });
    formik.setFieldValue("tag_id", productDetails?.tag_id);
  }, [productDetails]);

  useEffect(() => {
    formik.setFieldValue("meta_keywords", generatedKeywords);
  }, [generatedKeywords]);

  useEffect(() => {
    let isMounted = true;
    services
      .get("category/sub-category/" + formik.values.category_id)
      .then((res) => {
        if (isMounted) {
          setSubCategoryInfo(res.data);
        }
      });
    return () => {
      isMounted = false;
    };
  }, [formik.values.category_id]);

  useEffect(() => {
    formik.setFieldValue(
      "slug",
      slugGeneration(formik.values.name, formik.values.sku)
    );
  }, [formik.values.name, formik.values.sku]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleImageChange = (selectedFiles) => {
    if (selectedFiles) {
      setSelectedFile(selectedFiles);
    }
  };

  const [croppedImage, setCroppedImage] = useState(null);

  const handleImageCropped = (croppedImageFile) => {
    // Do something with the cropped image file
    console.log("Cropped Image File:", croppedImageFile);
    formik.setFieldValue("code_image", croppedImageFile);
    formik.setFieldValue("cover_image", croppedImageFile);
    // Set it in the state if needed
    setCroppedImage(croppedImageFile);
  };

  useEffect(() => {
    let isMounted = true;
    services.get("category/list").then((res) => {
      if (isMounted) {
        setCategoryInfo(res.data);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    services &&
      services.get("tag/list").then((res) => {
        setProductTags([]);
        res.data.map((i) => {
          const newObj = { value: i.id, label: i.name };
          setProductTags((oldTags) => {
            const updatedTags = Array.isArray(oldTags) ? oldTags : [];
            return [...updatedTags, newObj];
          });
        });
      });
  }, []);

  useEffect(() => {
    let isMounted = true;
    services.get("color/list").then((res) => {
      if (isMounted) {
        setColorList(res.data);
      }
    });
  }, []);

  const handleTagsChange = (keywords) => {
    const updatedList = [];
    keywords && keywords.map((i) => updatedList.push(i.value));
    Array.isArray(updatedList) && formik.setFieldValue("tag_id", updatedList);
  };

  const imageDeleted = (status) => {
    setIsImageDeleted(status);
  };
  const onDeleteTag = (id) => {
    if (formik.values.tag_id.split(",").includes(id.toString())) {
      const updatedTags = formik.values.tag_id
        .split(",")
        .filter((tagId) => tagId !== id.toString())
        .join(",");
      formik.setFieldValue("tag_id", updatedTags);
    }
  };

  const AddTag = (id) => {
    formik.setFieldValue("tag_id", formik.values.tag_id + "," + id);
  };

  const handleImageCodeImage = (selectedFiles) => {
    if (selectedFiles) {
      setSelectedCodeFile(selectedFiles);
      formik.setFieldValue("code_image", selectedFiles[0]);
      formik.setFieldValue("cover_image", selectedFiles[0]);
    }
  };
  return (
    <Pagelayout
      title="Edit Product"
      breadcrumbs={[
        { title: "Dashboard", link: "/dashboard" },
        { title: "Products", link: "/products" },
        { title: "Edit Product", link: "#" },
      ]}
    >
      <Notification />
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md={8}>
            <Card className="overflow-visible mb-4">
              <Card.Body>
                <Row>
                  <Col md={12} className="mb-3">
                    <Label aria-required>Product Name</Label>
                    <CustomInput
                      type="text"
                      name="name"
                      placeholder="Product Name"
                      className="form-control"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="error">{formik.errors.name}</div>
                    ) : null}
                  </Col>
                  <Col md={12} className="mb-3">
                    <h6 className="fw-bold">Slug - {formik.values.slug}</h6>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Label aria-required>Short Description</Label>
                    <CommonEditor
                      value={formik.values.short_description}
                      onChange={(value) =>
                        formik.setFieldValue("short_description", value)
                      }
                    />

                    {formik.touched.short_description &&
                    formik.errors.short_description ? (
                      <div className="error">
                        {formik.errors.short_description}
                      </div>
                    ) : null}
                  </Col>

                  <Col md={12} className="mb-3">
                    <Label aria-required>Full Description</Label>
                    <CommonEditor
                      value={formik.values.full_description}
                      onChange={(value) =>
                        formik.setFieldValue("full_description", value)
                      }
                    />

                    {formik.touched.full_description &&
                    formik.errors.full_description ? (
                      <div className="error">
                        {formik.errors.full_description}
                      </div>
                    ) : null}
                  </Col>

                  <Col md={8} className="mb-3">
                    <Label aria-required>Images (max 5 Images)</Label>
                    <CustomMultiUpload
                      cropper={false}
                      isEdit={true}
                      imageDeleted={imageDeleted}
                      isFormSubmitted={isFormSubmitted}
                      productId={productId}
                      multiple={true}
                      placeholder="Select files"
                      onChange={handleImageChange}
                      images={selectedFile} // Pass selectedFile as the 'images' prop
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <Label aria-required>Physical Image</Label>
                    <CustomCropper
                      fetchedImg={formik.values.code_image}
                      isMulti={false}
                      onImageCropped={handleImageCropped}
                    />

                    {/* <CustomMultiUpload
                      cropper={false}
                      isEdit={true}
                      imageDeleted={imageDeleted}
                      isFormSubmitted={isFormSubmitted}
                      productId={productId}
                      multiple={true}
                      placeholder="Select files"
                      onChange={handleImageCodeImage}
                      images={selectedCodeFile} // Pass selectedFile as the 'images' prop
                    /> */}
                  </Col>

                  <Col md={12} className="mb-3">
                    <Label aria-required>Meta Name</Label>
                    <CustomInput
                      type="text"
                      name="meta_name"
                      placeholder="Meta Name"
                      className="form-control"
                      value={formik.values.meta_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.meta_name && formik.errors.meta_name ? (
                      <div className="error">{formik.errors.meta_name}</div>
                    ) : null}
                  </Col>

                  <Col md={12} className="mb-3">
                    <Label aria-required>Meta Description</Label>
                    <CommonEditor
                      value={formik.values.meta_description}
                      onChange={(value) =>
                        formik.setFieldValue("meta_description", value)
                      }
                    />

                    {formik.touched.name && formik.errors.name ? (
                      <div className="error">{formik.errors.name}</div>
                    ) : null}
                  </Col>

                  <Col md={6} className="mb-3">
                    <Label aria-required>Meta Keywords</Label>
                    <CustomInput
                      type="text"
                      name="meta_keywords"
                      placeholder="Meta Keywords"
                      className="form-control"
                      value={formik.values.meta_keywords}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.meta_keywords &&
                    formik.errors.meta_keywords ? (
                      <div className="error">{formik.errors.meta_keywords}</div>
                    ) : null}
                  </Col>

                  <Col md={6} className="mb-3">
                    <Label aria-required>Product tags</Label>
                    <TagSelector
                      tags={productTags}
                      value={formik.values.tag_id}
                      AddTag={AddTag}
                      onDeleteTag={onDeleteTag}
                    />
                    {/* <CustomSelect
                                 isMulti
                                 className={'w-100'}
                                 onChange={(value) => handleTagsChange(value)}
                                 options={productTags}
                                 value={productTags && productTags.filter(option => formik.values.tag_id && formik.values.tag_id.includes(option.value.toString()))}
                              /> */}
                    {formik.touched.tag_id && formik.errors.tag_id ? (
                      <div className="error">{formik.errors.tag_id}</div>
                    ) : null}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            {productDetails?.sizes && productDetails?.sizes.length > 0 && (
              <Card className="overflow-visible priceInfoCard mb-4">
                <Card.Body className="px-3">
                  <Row className="mb-2 align-items-end">
                    <Col md={4}>
                      {
                        // JSON.stringify(productDetails?.sizes)
                      }
                      <Label>Select Size </Label>
                      <select
                        onChange={(event) =>
                          setSelectedSize(event.target.value)
                        }
                        className="form-control"
                      >
                        {/* <opion value={null}>Select Size</opion> */}
                        {productDetails?.sizes &&
                          productDetails?.sizes.map((i, index) => (
                            <option key={index} value={i.size_name_id}>
                              {i.size_name?.name}
                            </option>
                          ))}
                      </select>
                    </Col>
                    <Col>
                      <h6 className="d-block fw-bold my-3">
                        Size -{" "}
                        {productDetails?.sizes &&
                          productDetails?.sizes.find(
                            (j) => j.size_name_id === selectedSize
                          )?.size_name?.name}
                      </h6>
                    </Col>
                  </Row>

                  {productDetails?.sizes &&
                    productDetails?.sizes
                      .filter((j) => j.size_name_id === selectedSize)
                      .map((i) => (
                        <Row className="lh-base border-top pt-2 mt-3">
                          <Col className="fw-bold text-center">
                            Weight - {i.weight.toFixed(2)} Gram
                          </Col>
                          {/* <Col  className='fw-bold border-start border-end text-center'>Length - {i.length === 0 ? 'NA' : i.length}</Col> */}
                          <Col className="fw-bold border-start text-center">
                            Bar Code - {i.bar_code || "NA"}
                          </Col>
                          <hr className="my-2" />
                          <div className="p-3 border-bottom mb-3 ">
                            <div className="priceInfo">
                              {i?.size_prices.map((j) => (
                                <Row className="lh-base gx-2">
                                  <b className="d-block mb-2">
                                    Price in{" "}
                                    {currency &&
                                      currency.find(
                                        (k) => k.id === j.currency_id
                                      )?.name}{" "}
                                    (
                                    {currency &&
                                      currency.find(
                                        (k) => k.id === j.currency_id
                                      )?.symbol}
                                    )
                                  </b>
                                  <Col md={6}>
                                    <Col md={12}>
                                      Selling Price (Per Piece) :{" "}
                                      {amount(j.price_piece)}
                                      <b>
                                        {" "}
                                        {currency &&
                                          currency.find(
                                            (k) => k.id === j.currency_id
                                          )?.symbol}
                                      </b>
                                    </Col>
                                    <Col md={12}>
                                      Selling Price (By Gms) :{" "}
                                      {amount(j.sell_price_gram)}
                                      <b>
                                        {" "}
                                        {currency &&
                                          currency.find(
                                            (k) => k.id === j.currency_id
                                          )?.symbol}
                                      </b>
                                    </Col>
                                  </Col>
                                  <Col md={6}>
                                    <Col
                                      md={12}
                                      className="fw-bold"
                                      style={{ color: "var(--color3)" }}
                                    >
                                      Rate Per Gram : {amount(j.price_gram)}
                                      <b>
                                        {" "}
                                        {
                                          currency.find(
                                            (k) => k.id === j.currency_id
                                          )?.symbol
                                        }
                                      </b>
                                    </Col>
                                    <Col md={12}>
                                      Discount : {j.discount} <b>%</b>
                                    </Col>
                                  </Col>
                                  <hr className="my-2" />
                                </Row>
                              ))}
                            </div>
                          </div>
                        </Row>
                      ))}
                </Card.Body>
              </Card>
            )}
            <Card className="overflow-visible mb-4">
              <Card.Body>
                <Row>
                  <Col md={6} className="mb-3">
                    <Label aria-required>Select Category</Label>
                    <select
                      name="category_id"
                      className="form-control"
                      onChange={(event) =>
                        formik.setFieldValue("category_id", event.target.value)
                      }
                      onBlur={formik.handleBlur}
                    >
                      <option value={null}>Select Category</option>
                      {categoryInfo &&
                        categoryInfo.map((i, index) => (
                          <option
                            key={index}
                            selected={i.id === formik.values.category_id}
                            value={i.id}
                          >
                            {i.name}
                          </option>
                        ))}
                    </select>
                    {formik.touched.category_id && formik.errors.category_id ? (
                      <div className="error">{formik.errors.category_id}</div>
                    ) : null}
                  </Col>

                  <Col md={6} className="mb-3">
                    <Label aria-required>Select Sub Category</Label>
                    <select
                      name="category_id"
                      className="form-control"
                      onChange={(event) =>
                        formik.setFieldValue(
                          "sub_category_id",
                          event.target.value
                        )
                      }
                      onBlur={formik.handleBlur}
                    >
                      <option value={null}>Select Sub Category</option>
                      {subCategoryInfo &&
                        subCategoryInfo.map((i, index) => (
                          <option
                            selected={i.id === formik.values.sub_category_id}
                            key={index}
                            value={i.id}
                          >
                            {i.name}
                          </option>
                        ))}
                    </select>
                    {formik.touched.sub_category_id &&
                    formik.errors.sub_category_id ? (
                      <div className="error">
                        {formik.errors.sub_category_id}
                      </div>
                    ) : null}
                  </Col>

                  <Col md={12} className="mb-3">
                    <Label aria-required>Product SKU</Label>
                    <CustomInput
                      type="text"
                      name="sku"
                      placeholder="Product SKU"
                      className="form-control"
                      value={formik.values.sku}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.sku && formik.errors.sku ? (
                      <div className="error">{formik.errors.sku}</div>
                    ) : null}
                  </Col>

                  <Col md={6} className="mb-3">
                    <Label aria-required>Select Consignment</Label>
                    <select
                      name="stock_id"
                      className="form-control"
                      onChange={(event) =>
                        formik.setFieldValue("stock_id", event.target.value)
                      }
                      onBlur={formik.handleBlur}
                    >
                      <option value={null}>Select Consignment</option>
                      {stocklist &&
                        stocklist.map((i, index) => (
                          <option
                            selected={i.id === formik.values.stock_id}
                            key={index}
                            value={i.id}
                          >
                            {i.stock_title}
                          </option>
                        ))}
                    </select>
                    {formik.touched.stock_id && formik.errors.stock_id ? (
                      <div className="error">{formik.errors.stock_id}</div>
                    ) : null}
                  </Col>

                  <Col md={6} className="mb-3">
                    <Label aria-required>Select Color</Label>
                    <select
                      name="color_id"
                      className="form-control"
                      onChange={(event) =>
                        formik.setFieldValue("color_id", event.target.value)
                      }
                      onBlur={formik.handleBlur}
                    >
                      <option value={null}>Select Color</option>
                      {colorList &&
                        colorList.map((i, index) => (
                          <option
                            selected={i.id === formik.values.color_id}
                            key={index}
                            value={i.id}
                          >
                            {i.name}
                          </option>
                        ))}
                    </select>
                    {formik.touched.color_id && formik.errors.color_id ? (
                      <div className="error">{formik.errors.color_id}</div>
                    ) : null}
                  </Col>

                  <Col md={12} className="mb-3">
                    <Button
                      onClick={() => formSubmit()}
                      type="button"
                      size="lg"
                      className="px-md-5 w-100"
                      variant="primary"
                    >
                      {isLoading ? (
                        <BiLoader className="loader" />
                      ) : (
                        <>Update Product</>
                      )}
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>

      <Modal
        show={showSize}
        backdrop="static"
        keyboard={false}
        size="xl"
        onHide={handleCloseSize}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="h3">
              {productDetails?.name}
              <h6 className="mt-2">{productDetails?.category?.name}</h6>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Category - Ring */}
          <EditSize
            categoryId={productDetails?.category_id}
            closepopup={handleCloseSize}
            sizeData={productDetails?.sizes}
            product_id={productId}
          />
        </Modal.Body>
      </Modal>
    </Pagelayout>
  );
}

// draftOrderSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import doAPI from "../api";

// Define an initial state
const initialState = {
  orders: [],
  loading: false,
  error: null,
};
// Define the async thunk for fetching orders
export const fetchDraftOrders = createAsyncThunk(
  "orders/fetchDraftOrders",
  async ({ page = 1, shop = "", from_date='',to_date='', status='', sku='',orderId='' }) => {
    try {
      const params = new URLSearchParams({ page, shop, from_date, to_date , status, sku, orderId}).toString();
      const data = await doAPI.getData(`order/list-draft?${params}`);
      return data;
    } catch (error) {
      throw Error("Failed to fetch orders");
    }
  }
);

// Create the slice
const draftOrderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDraftOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDraftOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload;
      })
      .addCase(fetchDraftOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
    
  },
});

// Export the async thunk for external usage
// export { fetchOrders };

// Export the reducer
export default draftOrderSlice.reducer;

import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Row } from 'react-bootstrap';
import { MdEditSquare } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import doAPI from '../api';
import { notify } from './Notification';
import CustomSelect from './CustomSelect';
import { fetchUsers } from '../redux/usersSlice';

export default function AddToDraft({ items }) {
    const dispatch = useDispatch();

    const selectedCurrency = useSelector((state) => state.property.selectedCurrency?.id);

    const cart = useSelector((state) => state.cart);
    const cartTotal = parseFloat(
        cart?.shipping +
            cart?.subTotal +
            (parseFloat(cart.totalVatCustom) > 0 ? parseFloat(cart.totalVatCustom) : parseFloat(cart.totalVat)) -
            (cart?.couponResponse?.data?.discount || 0)
    );

    const userList = useSelector((state) => state.userList?.userList?.data?.rows) || [];

    // console.log({userList})

    const userListState = [];

    userList.map((i) =>
        userListState.push({
            label: i.first_name + ' ' + i.family_name,
            value: i.id,
        })
    );

    const [isLoading, setIsLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState(20);
    const [orderDetail, setOrderDetail] = useState({
        tran_ref: null,
        payment_result: null,
        order_items: {},
        shipping_address_id: null,
        shipping_address: null, // Initialize with null or an empty object
        billing_address_id: null,
        billing_address: null, // Initialize with null or an empty object
        payment_method: 'Cash',
        payment_id: 1,
        shipping_amount: cart.shipping,
        vat_amount: parseInt(cart.totalVatCustom) > 0 ? parseInt(cart.totalVatCustom) : parseInt(cart.totalVat),
        raw_amount: cart?.subTotal,
        total_amount: cartTotal,
        coupon_amount: cart?.couponResponse?.data?.discount || 0,
        coupon_id: cart?.couponResponse?.data?.coupon_id || '',
        coupon_code: cart?.couponResponse?.data?.coupon_code || null,
        order_status_id: 5,
        order_notes: 'POS Draft',
        currency_id: selectedCurrency,
        user_id: selectedUser,
    });

    useEffect(() => {
        const newOrderItems = cart.items.map((i) => ({
            id: i.id,
            sizes: i.sizes,
            size_id: i.size_id,
            quantity: i.quantity,
            price_type: i.price_type,
        }));
        setOrderDetail((prevOrderDetail) => ({
            ...prevOrderDetail,
            order_items: newOrderItems,
            total_amount: cartTotal,
        }));
    }, [cart]);

    const selectUser = (id) => {
        if (id !== selectedUser) {
            setSelectedUser(id);
            setOrderDetail((prevOrderDetail) => ({
                ...prevOrderDetail,
                user_id: id,
            }));
        }
    };

    const saveToDraft = () => {
        if (orderDetail?.user_id !== null) {
            doAPI.postData('order/create-draft-order', orderDetail).then((res) => {
                setIsLoading(true);
                if (res?.data?.status) {
                    notify(res?.data?.message);
                    // onFormSubmitSuccess();
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    if (Array.isArray(res?.data?.message)) {
                        notify('An error has occured, kindly contact the admin!');
                        console.log(res?.data?.message[0].message);
                    } else {
                        notify(res?.data?.message || 'Something went wrong!');
                    }
                    // notify(res.message[0]?.message[0]?.message, { variant: 'error' });
                }
            });
        } else {
            notify('Please select user');
        }
    };

    useEffect(() => {
        dispatch(fetchUsers({ role_id: 3 }));
    }, [dispatch]);
    return (
        <>
            <Row>
                <Col>
                    <h5 className="fw-bold m-0 py-1 d-inline-block">Cart </h5> &nbsp;
                    <p className="mb-0 d-inline-block">({items.length} Items)</p>
                    <ButtonGroup variant="outline-success" className="py-0 ms-3">
                        <Button className="py-1 px-3" onClick={() => saveToDraft()}>
                            <MdEditSquare />
                        </Button>
                    </ButtonGroup>
                </Col>
                <Col className="ms-auto">
                    <CustomSelect
                        className={'w-100 '}
                        placeholder="Select User"
                        value={selectedUser}
                        onChange={(value) => selectUser(value.value)}
                        options={userListState}
                    />
                </Col>
            </Row>
        </>
    );
}

import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Label from "../../Components/Label";
import CustomInput from "../../Components/CustomInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BiLoader } from "react-icons/bi";
import doAPI from "../../api";
import { addCourierCompany } from "../../redux/courierCompaniesSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  assignCourierCompany,
  clearResponse,
} from "../../redux/assignCourierCompanySlice";
import { notify } from "../../Components/Notification";

export default function AssignCompany({ orderId, isFormSubmit }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const courierCompanies = useSelector((state) => state.courierCompanies);

  const assignCourierCompanyState = useSelector(
    (state) => state.assignCourierCompany
  );

  const validationSchema = Yup.object().shape({
    logistics_company_id: Yup.string().required("Company Id is required"),
    tracking_no: Yup.string().required("Tracking number is required"),
  });

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      logistics_company_id: null,
      tracking_no: "",
    },
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      dispatch(assignCourierCompany(values));
    },
    validationSchema: validationSchema,
  });

  useEffect(() => {
    orderId !== undefined && formik.setFieldValue("order_id", orderId);
  }, [orderId]);

  useEffect(() => {
    // if (assignCourierCompanyState?.response?.status) dispatch(clearResponse());
    if (assignCourierCompanyState?.response?.status !== undefined) {
      notify("Logistic has been assigned to order.");
      setTimeout(() => {
        dispatch(clearResponse());
        isFormSubmit();
      }, 1000);

    }
  }, [assignCourierCompanyState?.response]);

  // console.log(formik.values, assignCourierCompanyState);
  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col md={12} className="mb-2">
          <Label aria-required>Select Company</Label>
          <select
            name="logistics_company_id"
            className="form-control"
            value={formik.values.logistics_company_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value={null}>Select Courier</option>
            {courierCompanies?.courierCompanies?.data?.result
              .filter((j) => j.status !== 0)
              .map((i) => (
                <option value={i.id} key={i.id}>
                  {i.company_name}
                </option>
              ))}
          </select>
          {formik.touched.logistics_company_id &&
          formik.errors.logistics_company_id ? (
            <div className="error">{formik.errors.logistics_company_id}</div>
          ) : null}
        </Col>

        <Col md={12} className="mb-2">
          <Label aria-required>Tracking No.</Label>
          <CustomInput
            type="text"
            className="form-control"
            placeholder="Tracking No."
            name="tracking_no"
            value={formik.values.tracking_no}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.tracking_no && formik.errors.tracking_no ? (
            <div className="error">{formik.errors.tracking_no}</div>
          ) : null}
        </Col>

        <Col md={12} className="mb-2">
          <Button
            type="submit"
            // onClick={()=>submitForm()}
            size="lg"
            className="px-md-5"
            variant="primary"
          >
            {isLoading ? <BiLoader className="loader" /> : "Submit"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

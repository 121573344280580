// categorySlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import doAPI from '../api';


// Async thunk for fetching categories and subcategories
export const fetchCategories = createAsyncThunk('categories/fetchCategories', async () => {
  try {
    const response = await doAPI.getData('category/list');
    return response.data;
  } catch (error) {
    throw error;
  }
});

// Initial state
const initialState = {
  categories: [],
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

// Create a slice
const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default categorySlice.reducer;

// LocationSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import doAPI from "../api";


// Define an initial state
const initialState = {
  countries: [],
  states: [],
  cities: [],
  loading: false,
  error: null,
};

// Create async thunks for fetching country, state, and city data
export const fetchCountries = createAsyncThunk("countries/fetchCountries", async () => {
  try {
    const data = await doAPI.getData("country/list");
    return data?.data?.data || [];
  } catch (error) {
    throw Error("Failed to fetch countries");
  }
});

export const fetchStatesByCountry = createAsyncThunk(
  "countries/fetchStatesByCountry",
  async (countryId) => {
    try {
      const data = await doAPI.getData(`state/country/${countryId}`);
      return data?.data?.data || [];
    } catch (error) {
      throw Error("Failed to fetch states");
    }
  }
);

export const fetchCitiesByState = createAsyncThunk(
  "countries/fetchCitiesByState",
  async (stateId) => {
    try {
      const data = await doAPI.getData(`city/state/${stateId}`);
      return data?.data?.data || [];
    } catch (error) {
      throw Error("Failed to fetch cities");
    }
  }
);

// Create the slice
const countrySlice = createSlice({
  name: "countries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCountries.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.countries = payload;
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchStatesByCountry.fulfilled, (state, { payload }) => {
        state.states = payload;
      })
      .addCase(fetchCitiesByState.fulfilled, (state, { payload }) => {
        state.cities = payload;
      });
  },
});


export default countrySlice.reducer;

import React from 'react'
import { Card } from 'react-bootstrap'

export default function DashboardCard({ children, variant }) {
   return (
      <Card className={`dashboardCard variant-${variant}`}>
         <Card.Body> {children} </Card.Body>
      </Card>
   )
}

import React, { useEffect, useState } from "react";
import ProductObj from "./ProductObj";

export default function ItemDetails({ data, list }) {
  const [allProduct, setAllProducts] = useState(list);

  useEffect(() => {
    setAllProducts(list);
  }, [list]);

  return (
    <>
      {allProduct
        .filter((i) => i.id === data.id)
        .map((j) => {
          return <ProductObj key={j.id} data={j} />;
        })}

      <style jsx>{`
        .description,
        .description * {
          margin: 0px;
        }
        .modalNavigation {
          position: absolute;
          top: -48px;
          right: calc(50% - 50px);
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .modalNavigation > button {
          border-radius: 100%;
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-width: 0px;
          background: var(--color1);
          color: #fff;
          box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.3);
        }
      `}</style>
    </>
  );
}

// propertySlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currency_id: 0,
    selectedCurrency: { id: 1, name: "Thai Baht", short_code: "THB", symbol: "฿", rate: null },
    // Add other properties as needed
};

const propertySlice = createSlice({
    name: 'property',
    initialState,
    reducers: {
        setSelectedCurrency: (state, action) => {
            state.currency_id = action?.payload?.id;
            state.selectedCurrency = action.payload;
        },
        // Add other reducer functions as needed
    },
});

export const { setSelectedCurrency } = propertySlice.actions;
export default propertySlice.reducer;

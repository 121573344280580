import React from 'react'
import Image from './Image'

export default function logo(props) {
  return (
    <Image src={require('../public/images/CieloZilver-logo.png')} height={50} />
  )
}



// countrySlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import doAPI from '../api';

// Define the initial state for the country slice
const initialState = {
  countries: [],
  status: 'idle',
  error: null,
};

// Define an async thunk for fetching the country list
export const fetchCountries = createAsyncThunk('country/fetchList', async (_, { rejectWithValue }) => {
  try {
    const response = await doAPI.getData(`country/list`);
    const data = await response.json();
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// Create the country slice
const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.countries = action.payload;
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default countrySlice.reducer;

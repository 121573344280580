import React from 'react';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
function CustomDatePicker({ name, className, value, onChange, onBlur }) {
   const convertToLocaleDate = (utcDateTimeString) => {
      const dateObj = new Date(utcDateTimeString);
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
   };

   const handleChange = (date) => {
      const localDate = convertToLocaleDate(date.toISOString());
      onChange(localDate);
   };

   return (
      <DatePicker
         name={name}
         className={className}
         format="dd-MM-yyyy"
         value={value ? new Date(value) : null}
         onChange={handleChange}
         onBlur={onBlur}
         yearPlaceholder="YYYY"
         monthPlaceholder="MM"
         dayPlaceholder="DD"
      />
   );
}

export default CustomDatePicker;

import React, { useEffect, useState } from 'react';
import { Button, Card, Col } from 'react-bootstrap';
import Currency from './Currency';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../redux/AddToCart';
import { amount } from './Utils';
import Swal from 'sweetalert2';
import CommonSlider from './CommonSlider';

export default function ProductCard({ data, listView }) {
    // console.log(data)
    const dispatch = useDispatch();

    const [selectedSize, setSelectedSize] = useState(null);

    const [selectedWeight, setSelectedWeight] = useState(null);

    const [selectPiecePrice, setSelectPiecePrice] = useState();

    const [selectGramsPrice, setSelectGramsPrice] = useState();

    const selectedCurrency = useSelector((state) => state.property.selectedCurrency)?.id;

    const checkIfOutofStock = (size) => data?.sizes.find((i) => i.id === size)?.stock !== 0;

    const handleAddToCart = () => {
        if (selectedSize !== undefined || selectedSize !== null || selectedWeight !== null) {
            if (checkIfOutofStock(selectedSize)) {
                const dataWithSizeAndWeight = {
                    ...data,
                    price_type: 'gram',
                    currency_id: selectedCurrency,
                    size_id: parseInt(selectedSize),
                    selectedSize: selectedSize,
                    selectedWeight: selectedWeight,
                };
                dispatch(addToCart(dataWithSizeAndWeight));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Out of Stock',
                    text: 'Please select another size, as the current size is out of stock.',
                });
            }
        }
    };
    useEffect(() => {
        setSelectPiecePrice(
            data?.sizes
                .find((j) => `${selectedSize}` === `${j.id}`)
                ?.size_prices.find((i) => i.currency_id === selectedCurrency)?.price_piece
        );
    }, [selectedSize, selectedCurrency]);

    useEffect(() => {
        setSelectGramsPrice(
            data?.sizes
                .find((j) => `${selectedSize}` === `${j.id}`)
                ?.size_prices.find((i) => i.currency_id === selectedCurrency)?.price_gram
        );
    }, [selectedSize, selectedCurrency]);

    useEffect(() => {
        setSelectedSize(data?.sizes[0]?.id);
    }, [dispatch, data?.sizes]);

    useEffect(() => {
        setSelectedWeight(data?.sizes && data?.sizes.find((j) => j.id === selectedSize)?.weight + ' Gms');
    }, [selectedSize]);

    const getSize = (event) => {
        const newSizeId = parseInt(event.target.value);
        setSelectedSize(newSizeId);

        // Find the selected size directly from the prop
        const selectedSizeData = data?.sizes.find((j) => j.id === newSizeId);
        console.log(selectedSizeData);
        // Update selectedWeight and prices only if selectedSizeData is available
        if (selectedSizeData) {
            setSelectedWeight(selectedSizeData.weight + ' Gms');
            setSelectPiecePrice(
                selectedSizeData.size_prices.find((i) => i.currency_id === selectedCurrency)?.price_piece
            );
            setSelectGramsPrice(
                selectedSizeData.size_prices.find((i) => i.currency_id === selectedCurrency)?.price_gram
            );
        }
    };

    return (
        <Col xl={listView ? 12 : 4} lg={listView ? 12 : 6} md={listView ? 12 : 4} sm={listView ? 12 : 6} xs={12}>
            <Card
                className={
                    listView ? 'listView card_product position-relative' : 'gridView card_product position-relative'
                }>
                <div className="img position-relative">
                    <CommonSlider
                        physicalImg={
                            data?.code_image_path
                                ? `${data?.code_image_path}/${data?.code_image_name}?resize=300,300`
                                : ''
                        }
                        data={data?.product_images}
                    />

                    {/* <Image
            className="w-100"
            src={`${data?.product_images[0]?.image_path}/${data?.product_images[0]?.image_name}`}
          /> */}
                </div>
                <div className="px-md-3 pb-md-3 p-2 flex-grow-1">
                    <h6 className="mb-1 overflow-ellipsis-two-lines">{data.name}</h6>
                    {data?.sizes && data?.sizes.map((color) => color.color?.name)}
                    <small className="d-block mb-1">
                        SKU - {data.sku} | Stock - {data?.sizes.find((size) => size?.id === selectedSize)?.stock}
                    </small>
                    <div className="m0 px-0 row gx-2">
                        <div className="col">
                            <select
                                onChange={(event) => getSize(event)}
                                value={selectedSize || ''}
                                className="form-control w-100 select-sm">
                                <option disabled value={null}>
                                    Size
                                </option>
                                {data?.sizes &&
                                    data?.sizes.map((i) => (
                                        <option key={i.id} value={i.id}>
                                            {i.size_name?.name}
                                        </option>
                                    ))}
                            </select>

                            <div className="fw-bold small flex-grow-1 text-center mt-2">
                                Piece - <Currency />
                                {amount(selectPiecePrice)}
                            </div>
                        </div>
                        <div className="col">
                            <input disabled className="form-control w-100 input-sm" defaultValue={selectedWeight} />
                            <div className="fw-bold small flex-grow-1 text-center mt-2">
                                Gms - <Currency />
                                {amount(selectGramsPrice)}
                            </div>
                        </div>
                        <div className="cartBtn">
                            <Button onClick={handleAddToCart}>Add to Cart</Button>
                        </div>
                    </div>
                </div>
            </Card>
        </Col>
    );
}

import React from 'react'

export default function RightSidebar({ children, onCloseSidebar, className }) {
   return (
      <div className={'right-sidebar-wrapper '+className}>
         <div className='right-sidebar'>{children}</div>
         <div className="right-sidebar-backdrop" onClick={onCloseSidebar}></div>
      </div>
   )
}

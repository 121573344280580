import React from 'react'
import PageHeading from '../Components/PageHeading'
export default function Pagelayout({ children, title, breadcrumbs, actions }) {
   return (
      <div className='inner_page'>
         <PageHeading title={title} breadcrumbs={breadcrumbs} actions={actions} />
         {children}
      </div>
   )
}

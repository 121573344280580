// cartSlice.js

import { createSlice } from '@reduxjs/toolkit';

const currencyObj = JSON.parse(localStorage.getItem('currency')) || {
    id: 1,
    name: 'Thai Baht',
    short_code: 'THB',
    symbol: '฿',
    rate: null,
};

const initialState = {
    items: [],
    quantity: 0,
    subTotal: 0,
    totalVat: 0,
    totalVatCustom: 0,
    shippingCharge: 0,
    discount: 0,
    shipping: 0,
    couponResponse: null,
    coupon: '',
    currency_id: currencyObj?.id,
    total_amount: 0,
    defaultPriceType: 'piece',
};

// Helper function to get the price based on the selected type
const getPriceBasedOnType = (item, priceType) => {
    const currencyId = item.currency_id;
    const sizeId = item.size_id;
    // console.log(item?.sizes&&item?.sizes.find(size=>size.id===sizeId)?.size_prices)
    const price =
        priceType === 'piece'
            ? item?.sizes &&
              (item?.sizes.find((size) => size?.id === sizeId)?.size_prices.find((i) => i?.currency_id === currencyId)
                  ?.sell_price_piece ||
                  0)
            : item?.sizes &&
              (item?.sizes.find((size) => size?.id === sizeId)?.size_prices.find((i) => i?.currency_id === currencyId)
                  ?.sell_price_gram ||
                  0);

    return parseFloat(price || 0);
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state, { payload }) => {
            const cartItems = state.items;

            const itemIndex = cartItems.findIndex((item) => item.size_id === payload?.size_id);
            // if (itemIndex === -1) {
            //   payload.price_type = 'gram'; // Replace with your default price type
            // }

            if (itemIndex >= 0) {
                const { currency_id, sizes, price_type } = cartItems[itemIndex];
                let objPrice = sizes
                    .find((size) => size.id === payload?.size_id)
                    ?.size_prices.find((x) => x.currency_id === currency_id);
                let price = price_type === 'piece' ? objPrice?.sell_price_piece : objPrice?.sell_price_gram;
                let vat = price_type === 'piece' ? objPrice?.vat_piece : objPrice?.vat_gram;
                cartItems[itemIndex].quantity += 1;
                cartItems[itemIndex].total_amount += price + vat;
            } else {
                const { currency_id, sizes, price_type } = payload;
                let objPrice =
                    sizes &&
                    sizes
                        .find((size) => size.id === payload?.size_id)
                        ?.size_prices.find((x) => x.currency_id === currency_id);
                let price = price_type === 'piece' ? objPrice?.sell_price_piece : objPrice?.sell_price_gram;
                let vat = price_type === 'piece' ? objPrice?.vat_piece : objPrice?.vat_gram;
                let total_amount = price + vat;
                cartItems.push({ ...payload, quantity: 1, total_amount });
            }
            Object.assign(state, { items: cartItems });
        },

        getCartTotal(state, { payload }) {
            const cartItems = state.items;
            const currencyId = payload;
            // Calculate cart total

            let { quantity, subTotal, totalVat, total_amount } = cartItems.reduce(
                (cartCalculation, cartItem) => {
                    const { quantity, sizes, price_type, total_amount, size_id } = cartItem;
                    cartCalculation.quantity += quantity;
                    let objPrice = sizes
                        .find((size) => size.id === size_id)
                        ?.size_prices.find((x) => x.currency_id === currencyId);
                    let price = price_type === 'piece' ? objPrice?.sell_price_piece : objPrice?.sell_price_gram;
                    let vat = price_type === 'piece' ? objPrice?.vat_piece : objPrice?.vat_gram;
                    cartCalculation.subTotal += price * quantity;
                    cartCalculation.totalVat += vat;
                    cartCalculation.total_amount += total_amount;
                    // Return output
                    return cartCalculation;
                },
                { quantity: 0, subTotal: 0, totalVat: 0, total_amount: 0 }
            );
            Object.assign(state, { quantity, subTotal, totalVat, total_amount });
        },

        clearCart: (state) => {
            state.items = [];
            state.total = 0;
            state.quantity = 0;
            state.subTotal = 0;
            state.totalVat = 0;
            state.totalVatCustom = 0;
            state.shipping = 0;
            state.discount = 0;
            state.shippingCharge = 0;
            state.couponResponse = null;
            state.coupon = '';
            state.total_amount = 0;
        },

        updateQuantity: (state, action) => {
            const { sizeId, newQuantity } = action.payload;

            const updatedCartItems = state.items.map((item) => {
                if (item.size_id === sizeId) {
                    const currencyId = item.currency_id;
                    const objPrice = item.sizes
                        .find((size) => size.id === sizeId)
                        ?.size_prices.find((x) => x.currency_id === currencyId);
                    const price = item.price_type === 'piece' ? objPrice?.sell_price_piece : objPrice?.sell_price_gram;
                    const vat = item.price_type === 'piece' ? objPrice?.vat_piece : objPrice?.vat_gram;
                    const totalAmount = (price + vat) * newQuantity;

                    return {
                        ...item,
                        quantity: newQuantity,
                        total_amount: totalAmount,
                    };
                } else {
                    return item;
                }
            });

            const updatedState = {
                ...state,
                items: updatedCartItems,
                quantity: updatedCartItems.reduce((total, item) => total + item.quantity, 0),
                subTotal: updatedCartItems.reduce((total, item) => total + item.total_amount, 0),
                // Recalculate other values based on your logic
                // ...
            };

            Object.assign(state, updatedState);
        },

        updateItemOptions: (state, action) => {
            const { sizeId, selectedSize } = action.payload;

            // Find the item by itemId in the items array
            const itemToUpdate = state.items.find((item) => item.size_id === sizeId);

            if (itemToUpdate) {
                // Update the size_id and selectedSize for the specific item
                itemToUpdate.size_id = sizeId;
                itemToUpdate.selectedSize = selectedSize;
            }
        },
        removeSingleItem: (state, { payload }) => {
            const cartItems = state.items;
            const itemIndex = cartItems.findIndex((item) => item.size_id === payload.size_id);

            if (itemIndex >= 0) {
                const item = cartItems[itemIndex];

                // Remove the item entirely from the cart
                cartItems.splice(itemIndex, 1);

                // Adjust other state properties as needed
                state.quantity -= item.quantity;
                state.subTotal -= item.total_amount;
                state.totalVat -=
                    item.sizes.product_prices &&
                    (item.sizes.product_prices.find((i) => i.currency_id === state.currency_id)?.vat_piece || 0);

                // Update other state properties based on your logic

                Object.assign(state, { items: cartItems });
            }
        },
        addShipping: (state, action) => {
            state.shipping = action.payload;
            state.shipping_amount = action.payload;
        },

        addVat: (state, action) => {
            state.totalVatCustom = action.payload;
        },

        addCoupon: (state, action) => {
            state.coupon = action.payload;
            state.couponResponse = null;
        },
        addCouponSuccess: (state, action) => {
            state.couponResponse = action.payload.data;
        },
        addCouponFailure: (state, action) => {
            console.error('Coupon application failed:', action.payload);
            state.couponResponse = null;
        },

        removeCoupon: (state, action) => {
            state.couponResponse = null;
            state.coupon = '';
        },

        addSelectedSize: (state, action) => {
            const existingItem = state.items.find((item) => item.size_id === action.payload.size_id);
            if (existingItem) {
                existingItem.selectedSize = action.payload;
            } else {
                state.items.push({ ...action.payload, selectedSize: 1 });
            }
        },

        updateTotalAmount: (state, action) => {
            state.total_amount = action.payload;
        },

        removeFromCart: (state, action) => {
            // console.log(state.items.find(j=>j.size_id===action.payload.size_id),action.payload.size_id)
            const itemIndex = state.items.findIndex((item) => item.size_id === action.payload.size_id);
            if (itemIndex !== -1) {
                const item = state.items[itemIndex];

                if (item.quantity > 1) {
                    item.quantity -= 1;
                } else {
                    state.items.splice(itemIndex, 1);
                }

                const checkCurrency = action.payload.sizes
                    .find((s) => s.id === item?.size_id)
                    ?.size_prices.find((i) => i.currency_id === currencyObj?.id);

                if (action.payload.selectedSize !== null) {
                    state.subTotal -= parseFloat(checkCurrency?.sell_price_piece);
                    state.discount -= parseFloat(checkCurrency?.discount);
                    state.totalVat -= parseFloat(checkCurrency?.vat_piece);
                }
                if (action.payload.selectedWeight !== null) {
                    state.subTotal -= parseFloat(checkCurrency?.sell_price_gram);
                    state.discount -= parseFloat(checkCurrency?.discount);
                    state.totalVat -= parseFloat(checkCurrency?.vat_gram);
                }
            }
        },

        addCurrencyId: (state, action) => {
            state.currency_id = action.payload;
        },

        addPriceType: (state, { payload }) => {
            const cartItems = state.items;
            const itemIndex = cartItems.findIndex((item) => item.size_id === payload.size_id);
            // console.log(cartItems.findIndex(item => item.size_id),payload.size_id)
            if (itemIndex >= 0) {
                cartItems[itemIndex].price_type = payload.price_type;
                const newPriceSubTotal = getPriceBasedOnType(cartItems[itemIndex], payload.price_type);
                state.subTotal += parseFloat(newPriceSubTotal);

                const newPrice = getPriceBasedOnType(cartItems[itemIndex], payload.price_type);
                cartItems[itemIndex].total_amount = parseFloat(newPrice);
                state.subTotal += parseFloat(newPrice);
                state.discount += parseFloat(
                    cartItems[itemIndex].sizes
                        .find((size) => size.id === cartItems.size_id)
                        ?.product_prices.find((i) => i.currency_id === state.currency_id)?.discount
                );

                state.totalVat += parseFloat(
                    cartItems[itemIndex].sizes
                        .find((size) => size.id === cartItems.size_id)
                        ?.product_prices.find((i) => i.currency_id === state.currency_id)?.vat_piece
                );
            }
        },
    },
});

export const {
    addToCart,
    removeFromCart,
    updateQuantity,
    clearCart,
    addShipping,
    addCoupon,
    addCouponSuccess,
    addCouponFailure,
    removeCoupon,
    addVat,
    updateTotalAmount,
    addCurrencyId,
    addPriceType,
    addDefaultPriceType,
    getCartTotal,
    updateItemOptions,
    removeSingleItem,
} = cartSlice.actions;

export default cartSlice.reducer;

import React, { useEffect, useState } from "react";
import Notification, { notify } from "../../../Components/Notification";
import { Button, Card, Dropdown, Modal } from "react-bootstrap";
import Service from "../../../http";
import { useDispatch, useSelector } from "react-redux";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Pagelayout from "../../../Layouts/Pagelayout";
import LTable from "../../../Components/LTable";
import { MdDelete } from "react-icons/md";
import AddCarousel from "./AddBanner";
import Swal from "sweetalert2";
import { tableSerialNumber } from "../../../Components/Utils";
import { fetchBannerList } from "../../../redux/bannerSlice";
import Image from "../../../Components/Image";
export default function Banner() {
  const services = new Service();
  // const [carouselData, setCarouselData] = useState()
  const handleFormSubmitSuccess = () => {
    // setShowCarouselEditForm(false)
    setShowCarouselAddForm(false);
  };

  const [CarouselAddForm, setShowCarouselAddForm] = useState(false);
  const handleCloseCarouselAddForm = () => setShowCarouselAddForm(false);
  const handleShowBannerAddForm = () => setShowCarouselAddForm(true);

  const dispatch = useDispatch();
  const banner = useSelector((state) => state.banner);
  useEffect(() => {
    dispatch(fetchBannerList());
  }, [dispatch]);

  console.log(banner?.bannerList.data);

  const handleDeleteCarousel = (bannnerId) => {
    const options = {
      title: "Delete Banner",
      text: "Are you sure you want to delete this banner?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    };

    // Use Swal.fire to show the SweetAlert dialog
    Swal.fire(options).then((result) => {
      if (result.isConfirmed) {
        // User confirmed, proceed with deletion
        services
          .put("slider/banner-delete/" + bannnerId)
          .then((res) => {
            if (res.status) {
              notify(res.message);
              dispatch(fetchBannerList());
            } else {
              notify(res.message[0]?.message);
            }
          })
          .catch((error) => {
            console.error("Error - ", error);
          });
      }
    });
  };
  return (
    <Pagelayout
      title="Banners"
      breadcrumbs={[
        { title: "Dashboard", link: "/dashboard" },
        { title: "Banner list", link: "#" },
      ]}
      actions={
        <Button onClick={() => handleShowBannerAddForm()}>Add Banner</Button>
      }
    >
      <Notification />
      <Card>
        <Card.Body>
          <LTable isLoading={banner.status === "pending"}>
            <thead>
              <tr>
                <th width={70}>#</th>
                <th>Desktop</th>
                <th>Mobile</th>
                <th>Title</th>
                <th>Description</th>
                <th>Link</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {banner?.bannerList.data &&
                banner?.bannerList.data.map((i, index) => (
                  <tr key={index}>
                    <td width={50}>
                      {tableSerialNumber(
                        index,
                        banner?.bannerList.cur_page,
                        banner?.bannerList.data.length
                      )}
                    </td>
                    <td width={80}>
                    <Image
                        className="shadow-sm enlarge_img"
                        alt="img"
                        style={{ objectFit: "cover", borderRadius: 5 }}
                        src={
                          i?.image_path +
                          "/" +
                          (i?.image_name === null
                            ? ""
                            : i?.desktop_image + "?resize=200"
                          ).replace(/\.(jpg|png|jpeg)$/, ".webp")
                        }
                        width={80}
                      />
                     
                    </td>
                    <td width={80}>
                    <Image
                        className="shadow-sm enlarge_img"
                        alt="img"
                        style={{ objectFit: "cover", borderRadius: 5 }}
                        src={
                          i?.image_path +
                          "/" +
                          (i?.image_name === null
                            ? ""
                            : i?.mobile_image + "?resize=200"
                          ).replace(/\.(jpg|png|jpeg)$/, ".webp")
                        }
                        width={50}
                      />
                     
                    </td>
                    <td>{i.title}</td>
                    <td>{i.description}</td>
                    <td>{i.link}</td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <BiDotsVerticalRounded />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {/* <Dropdown.Item onClick={() => handleShowCarouselEditForm(i)}><MdEdit /> Edit</Dropdown.Item> */}
                          <Dropdown.Item
                            onClick={() => handleDeleteCarousel(i.id)}
                          >
                            <MdDelete /> Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
            </tbody>
          </LTable>
        </Card.Body>
      </Card>

      {/* 
         <Modal show={CarouselEditForm} onHide={handleCloseCarouselEditForm}>
            <Modal.Header closeButton>
               <Modal.Title>Edit Carousel</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <EditCarousel onFormSubmitSuccess={handleFormSubmitSuccess} data={carouselData} />
            </Modal.Body>
         </Modal> */}

      <Modal show={CarouselAddForm} onHide={handleCloseCarouselAddForm}>
        <Modal.Header closeButton>
          <Modal.Title>Add Carousel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddCarousel onFormSubmitSuccess={handleFormSubmitSuccess} />
        </Modal.Body>
      </Modal>
    </Pagelayout>
  );
}

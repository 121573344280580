import React from 'react';
import { Table } from 'react-bootstrap';
import { FaSadTear } from 'react-icons/fa';
import Image from './Image';
import LoadingSkeleton from '../Components/LoadingSkeleton';

export default function LTable({ children, isLoading }) {
   let tbodyTrCount = 0;
   let theadTrThCount = 0;
   let updatedChildren = React.Children.map(children, (child) => {
      // Check if the child is a tbody element
      if (React.isValidElement(child) && child.type === 'tbody') {
         // Count the number of tr elements inside the tbody
         React.Children.forEach(child.props.children, (tbodyChild) => {
            if (React.isValidElement(tbodyChild) && tbodyChild.type === 'tr') {
               tbodyTrCount += 1;
            }
         });

         // If tbodyTrCount is 0, replace the children of tbody with a No Records row

         if (isLoading) {
            return React.cloneElement(child, null, // Preserve other props of tbody

               <>
                  <tr><td colSpan={theadTrThCount + 1} className="text-center  py-3 border-0 bg-white"><LoadingSkeleton /></td></tr>
                  <tr><td colSpan={theadTrThCount + 1} className="text-center  py-3 border-0 bg-white"><LoadingSkeleton /></td></tr>
                  <tr><td colSpan={theadTrThCount + 1} className="text-center  py-3 border-0 bg-white"><LoadingSkeleton /></td></tr>
                  <tr><td colSpan={theadTrThCount + 1} className="text-center  py-3 border-0 bg-white"><LoadingSkeleton /></td></tr>
                  <tr><td colSpan={theadTrThCount + 1} className="text-center  py-3 border-0 bg-white"><LoadingSkeleton /></td></tr>
                  <tr><td colSpan={theadTrThCount + 1} className="text-center  py-3 border-0 bg-white"><LoadingSkeleton /></td></tr>
                  <tr><td colSpan={theadTrThCount + 1} className="text-center  py-3 border-0 bg-white"><LoadingSkeleton /></td></tr>
                  <tr><td colSpan={theadTrThCount + 1} className="text-center  py-3 border-0 bg-white"><LoadingSkeleton /></td></tr>
                  <tr><td colSpan={theadTrThCount + 1} className="text-center  py-3 border-0 bg-white"><LoadingSkeleton /></td></tr>
                  <tr><td colSpan={theadTrThCount + 1} className="text-center  py-3 border-0 bg-white"><LoadingSkeleton /></td></tr>
               </>
            );
         }
         if (tbodyTrCount === 0) {
            return React.cloneElement(child, null, // Preserve other props of tbody
               <tr className='no-data'>
                  <td colSpan={theadTrThCount + 1} className="text-center  py-5 border-0 bg-white">
                     <div style={{ opacity: .5 }}>
                        <Image height={100} src={require("../public/images/empty-box.png")} />
                        {/* <FaSadTear size={64} color=""  /> */}
                        <h6 style={{ letterSpacing: 5 }}>No Records</h6>
                     </div>
                  </td>
               </tr>
            );
         }
      } else if (React.isValidElement(child) && child.type === 'thead') {
         // Count the number of tr elements inside the thead
         React.Children.forEach(child.props.children, (theadChild) => {
            if (React.isValidElement(theadChild) && theadChild.type === 'tr') {
               // Count the number of th elements inside the tr of thead
               React.Children.forEach(theadChild.props.children, (trChild) => {
                  if (React.isValidElement(trChild) && trChild.type === 'th') {
                     theadTrThCount += 1;
                  }
               });
            }
         });
      }
      return child;
   });

   return <div className='ltTable'><Table hover responsive className="no-border">{updatedChildren}</Table></div>

}

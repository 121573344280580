import React, { useEffect, useState } from 'react'
import Pagelayout from '../../Layouts/Pagelayout'
import { Button, Card, Col, Dropdown, Form, Modal } from 'react-bootstrap'
import Service from '../../http';
import LTable from '../../Components/LTable';
import { BiDotsVerticalRounded, BiLoader } from 'react-icons/bi';
import { formatDate, tableSerialNumber } from '../../Components/Utils';
import CustomInput from '../../Components/CustomInput';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Notification, { notify } from '../../Components/Notification';
import { Link } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import Label from '../../Components/Label';

export default function ProductColors() {
  const services = new Service();
  const [isLoading, setIsLoading] = useState(false)
  const [colorList, setColorList] = useState([])
  const [showItemList, setShowItemList] = useState(false)
  const handleCloseItemList = () => setShowItemList(false);
  const handleShowItemList = () => {
    setShowItemList(true)
  };

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      name: "",
      hex: ""
    },
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);

      services &&
        services
          .post("color/add", values)
          .then((res) => {
            if (res.status) {
              notify(res.message)
              fetchEntry()
              handleCloseItemList()
            } else {
              notify(res.message[0].message)
            };
            setIsLoading(false);
          });

      resetForm();
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required.'),
      hex: Yup.string().required('Hex Code is required.'),

    })
  });

  const fetchEntry = () => {
    services &&
      services.get(`color/list`).then((res) => {
        setColorList(res?.data)
      });
  };

  useEffect(() => { fetchEntry() }, [])

  const deleteEntry = (id) => {
    services &&
      services.put("color/delete/" + id)
        .then((res) => {
          if (res.status) {
            notify(res.message);
            fetchEntry();
          } else {
            notify(res.message[0]?.message);
          }
        })
        .catch((error) => {
          console.error('Error - ', error);
        });
  }

  return (
    <Pagelayout
      title="Color List"
      breadcrumbs={
        [
          { title: 'Dashboard', link: '/dashboard' },
          { title: 'Products', link: '/products' },
          { title: 'Color', link: '/color-list' },
        ]
      }
      actions={<Button onClick={handleShowItemList}>Add Color</Button>}>
      <Notification />
      <Card className='overflow-visible'>
        <Card.Body>

          <LTable>
            <thead>
              <tr>
                <th width={50}>#</th>
                <th>Name</th>
                <th>Hex Code</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>


              {colorList && colorList.map((i, index) => <tr key={index}>
                <td>{
                  tableSerialNumber(index, colorList?.cur_page, colorList.length, 20)
                }</td>
                <td>{i.name}</td>
                <td><div className='d-flex align-items-center'><div style={{ height: 15, width: 15, border: '1px solid #ddd', borderRadius: 5, background: i.hex, display: 'inline-block', marginRight: 10 }} />  {i.hex}</div></td>
                <td>{formatDate(i.updated)}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <BiDotsVerticalRounded />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} onClick={() => deleteEntry(i.id)} to="#"><MdDelete /> Delete</Dropdown.Item>
                      {/* <Dropdown.Item href="#/action-2"><MdEdit /> Edit</Dropdown.Item> */}

                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>)}

            </tbody>
          </LTable>

        </Card.Body>
      </Card>

      <Modal show={showItemList} onHide={handleCloseItemList}>
        <Modal.Header closeButton>
          <Modal.Title>Add Color Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit} >
            <Notification />
            <Col md={12} className="mb-3">
              <Label>Color Name</Label>
              <CustomInput
                className="form-control"
                type="text"
                placeholder="Color Name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              {formik.touched.name && formik.errors.name ? (
                <div className="error">{formik.errors.name}</div>
              ) : null}
            </Col>
            <Col md={12} className="mb-3">
              <Label>Hex Code</Label>
              <CustomInput
                className="form-control"
                type="color"
                placeholder="Hex"
                name="hex"
                value={formik.values.hex}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              {formik.touched.hex && formik.errors.hex ? (
                <div className="error">{formik.errors.hex}</div>
              ) : null}
            </Col>
            <Button size='lg' className='w-100' type="submit">{isLoading ? <BiLoader className='loader' /> : <>Add</>}</Button>
          </Form>
        </Modal.Body>

      </Modal>

    </Pagelayout>
  )
}
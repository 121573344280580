import React, { useEffect } from "react";
import Pagelayout from "../../Layouts/Pagelayout";
import { Card, Col, Row } from "react-bootstrap";
import DashboardCard from "./DashboardCard";
import Image from "../../Components/Image";
import { useDispatch, useSelector } from "react-redux";
import MonthlyOrdersCharts from "./MonthlyOrdersCharts";
import MonthlySalesCharts from "./MonthlySalesCharts";
import MonthlyStoresCharts from "./MonthlyStoresCharts";
import { fetchOrders } from "./../../redux/orderSlice";
import { fetchProductList } from "./../../redux/productSlice";
import { amount, formatNumberWithSymbols } from "../../Components/Utils";

export default function Dashboard() {
  const dispatch = useDispatch();

  const userinfo = useSelector((state) => state.user.user.first_name);

  const productListCount =
    useSelector((state) => state.product)?.productList.count || 0;

  const orderListCount =
    useSelector((state) => state?.order?.orders?.data?.data?.count) || 0;

  const orderList =
    useSelector((state) => state?.order?.orders?.data?.data?.result) || [];

  useEffect(() => {
    dispatch(
      fetchProductList({
        page: 1,
        search: "",
        category: "",
        price: "",
        size: "",
        color: "",
      })
    );
    dispatch(fetchOrders({ page: 1, shop: "" }));
  }, [dispatch]);

  function calculateTotalAmountSum(data) {
    return data.reduce((sum, item) => sum + (item?.total_amount || 0), 0);
  }
  const totalSum = calculateTotalAmountSum(orderList);

  return (
    <Pagelayout
      title={`Hi, Welcome back, ${userinfo}`}
      // breadcrumbs={
      //    [
      //       { title: 'Inventory', link: '/inventory' },
      //       { title: 'Store Balance', link: '/inventory/store-balance' },
      //    ]
      // }
    >
      <Row className=" gx-2 gx-md-3">
        <Col md={3} xs={6} className="mb-md-3 mb-2">
          <DashboardCard variant={"primary"}>
            <div className="dashboardCard_chart"></div>
            <Image
              src={require("../../public/images/dashboard_icons/store.png")}
            />
            <h3>{productListCount}</h3>
            <p>Total Products</p>
          </DashboardCard>
        </Col>
        <Col md={3} xs={6} className="mb-3">
          <DashboardCard variant={"success"}>
            <Image
              src={require("../../public/images/dashboard_icons/sales.png")}
            />
            <h3>{formatNumberWithSymbols(totalSum)}</h3>
            <p>Total Sales</p>
          </DashboardCard>
        </Col>
        <Col md={3} xs={6} className="mb-3">
          <DashboardCard variant={"warning"}>
            <Image
              src={require("../../public/images/dashboard_icons/orders.png")}
            />
            <h3>{orderListCount}</h3>
            <p>Item Orders</p>
          </DashboardCard>
        </Col>
        <Col md={3} xs={6} className="mb-3">
          <DashboardCard variant={"danger"}>
            <Image
              src={require("../../public/images/dashboard_icons/report.png")}
            />
            <h3>714k</h3>
            <p>Bug Reports</p>
          </DashboardCard>
        </Col>
        <Col md={5}>
          <Card>
            <Card.Body>
              <h5 className="fw-bold mb-4">Monthly Sales</h5>
              <MonthlySalesCharts />
              {/* Use the Charts component */}
            </Card.Body>
          </Card>
        </Col>
        <Col md={5}>
          <Card>
            <Card.Body>
              <h5 className="fw-bold mb-4">Monthly New Store</h5>
              <MonthlyStoresCharts />
              {/* Use the Charts component */}
            </Card.Body>
          </Card>
        </Col>
        <Col md={2}>
          <Card>
            <Card.Body>
              <h5 className="fw-bold mb-43">Monthly Orders</h5>
              <MonthlyOrdersCharts />
              {/* Use the Charts component */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Pagelayout>
  );
}

import React, { useState } from 'react'
import CustomInput from './CustomInput'
import { Link } from 'react-router-dom';
import Label from './Label';
import { Badge } from 'react-bootstrap';
import { MdDelete, MdRemove } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';

export default function TagSelector({ tags, value = [], onDeleteTag, AddTag }) {
   const [showTagList, setShowTagList] = useState(false)
   return (<>
      <div className='tagSelector' onMouseEnter={() => setShowTagList(true)} onMouseLeave={() => setShowTagList(false)}>
         {value.length > 0 && <div className='form-control' >
            {tags && tags.filter(tag => value.split(',').includes(tag.value.toString())).map(k => <Badge className='m-1' style={{ fontSize: 13 }}>{k.label} <AiOutlineClose className='m-0' onClick={() => onDeleteTag(k.value)} /></Badge>)}
         </div>}

         {value.length === 0 && <div className='form-control' >
            {tags && tags.filter(tag => value.includes(tag.value.toString())).map(k => <Badge className='m-1' style={{ fontSize: 13 }}>{k.label} <AiOutlineClose className='m-0' onClick={() => onDeleteTag(k.value)} /></Badge>)}
         </div>}

         {showTagList && <div className='resultList'>
            <ul>
               {tags && tags.map((i, index) => <li
                  key={index}
                  className={value.length > 0 && value.split(',').includes(i.value.toString()) ? 'selected' : ''}
                  onClick={() => AddTag(i.value)}>{i.label}</li>)}
            </ul>
         </div>}
      </div>
   </>
   )
}
